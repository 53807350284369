/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import { Row, Col, BackTop } from "antd";
// import Content0 from "./Content0";
// import Content7 from "./Content7";
// import Banner from "../Banner/index";
// import CSCButton from "../widget/CSCButton";
import CSCTitle from "../../widget/CSCTitle";
import CSCBackTop from "../../widget/CSCBackTop";

// import Content5 from "./Content5";

// import {
//     Content00DataSource,
//     Content70DataSource,
//     Banner10DataSource,
//     Content50DataSource,
// } from "./data.source.js";

import "./less/antMotionStyle.less";
import "./less/product.less";

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const location = window.location;

const textSty = {
    fontSize: isMobile ? "13px" : "18px",
    textIndent: isMobile ? "20px" : "40px",
    maxWidth: "980px",
    padding: isMobile ? "15px" : "20px",
    margin: "0 auto",
};

export default class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
            activeTab: 0,
        };
    }

    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
        /* 如果不是 dva 2.0 请删除 end */
    }

    // 保修期限
    getServiceNetwork() {
        return (
            // <div style={textSty}>
            <div
                style={{
                    maxWidth: "1200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "0 auto",
                    marginTop: "4reme",
                    // marginBottom: "4rem",

                    paddingLeft: "5px",
                    paddingRight: "5px",
                    paddingBottom: "7rem",
                }}
                dangerouslySetInnerHTML={{
                    __html: `
            <table class="MsoNormalTable ke-zeroborder" border="0" cellspacing="0" cellpadding="0" width="760" >
                <tbody style="background:#fff">
                    <tr>
                        <td width="66">
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span style="font-weight:bold;font-size:12pt; color:#00AA71">区域</span>
                            </p>
                        </td>
                        <td width="76">
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span style="font-weight:bold;font-size:12pt; color:#00AA71">省份（直辖市）</span>
                            </p>
                        </td>
                        <td width="85">
                            <p class="MsoNormal" align="center">
                                <span style="font-weight:bold;font-size:12pt; color:#00AA71">城市</span>
                            </p>
                        </td>
                        <td width="470">
                            <p class="MsoNormal" align="center">
                                <span style="font-weight:bold;font-size:12pt;color:#00AA71">地址</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td rowspan="3">
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>华北</span>
                            </p>
                        </td>
                        <td rowspan="2">
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>北京</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>北京</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>北京亦庄经济技术开发区科创十四街6号院2号楼3层</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>北京</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>北京市昌平区鲁疃路5号中国电子信息安全技术研发基地A座4层</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>河北</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>石家庄</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>河北石家庄市新华区北小街太和大厦2130室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td rowspan="18">
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>东北</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>黑龙江</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>哈尔滨</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>哈尔滨市南岗区一曼街盟科观邸A2栋三单元2104室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>吉林</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>长春</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>吉林省长春市工农大路1313号百脑汇1317室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td rowspan="2">
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>辽宁</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>沈阳</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>沈阳市和平区三好街东软电脑城F座301</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>大连</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>辽宁省大连市沙河口区西安路长兴电子城E区110号</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td rowspan="14">
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>内蒙古</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>呼和浩特</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>呼和浩特市赛罕区科尔沁南路69号留学人员创业园创新创业大厦D座4层</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>包头</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>内蒙古包头市昆区林荫路天天公寓9楼940室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>鄂尔多斯</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>鄂尔多斯市东胜区天佑大厦604</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>巴彦淖尔</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>临河区胜利路金泰大厦综合办公楼11层1111室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>乌海</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>乌海市海勃湾区千里山东街新锐尚都C座603室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>阿拉善</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>内蒙古阿拉善盟阿盟国税局3楼306</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>乌兰察布</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>集宁区乌兰大街文化广场4层</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>锡林郭勒</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>锡林浩特市团结大街西段红茜园小区南门西航天信息</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>兴安盟</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>乌兰浩特市和平街红蒲社区欧洲风情5号楼426室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>呼伦贝尔</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>海拉尔区国贸花园小商业综合楼5028室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>满洲里</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>满洲里市六道街天宏大厦1404室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>二连浩特</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>二连桃源小区11号楼3-6</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>赤峰</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>赤峰市新城区林西路金帝大厦B座3区623</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>通辽</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>通辽市华申时代广场2号写字楼11层1116室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td rowspan="4">
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>西北</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>山西</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>太原</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>山西省太原市小店区南内环街青龙电脑城停车场同舟电脑2楼201室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>陕西</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>西安</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>西安市雁塔区测绘路4号大地数码港602室</span>
                            </p>
                        </td>
                    </tr>        
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>新疆</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>乌鲁木齐</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>乌鲁木齐市中山路260号建银大厦21楼2115室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>甘肃</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>兰州</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>甘肃省兰州市渭源路102号三楼</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td rowspan="13">
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>华东</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>上海</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>上海</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>上海市松江区沪淞公路1620弄九里亭工业园区嘉蓬路149号</span>
                            </p>
                        </td>            
                    </tr>
                    <tr>
                        <td rowspan="3">
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>山东</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>济南</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>济南市历下区花园庄东路16号数码港6号楼8楼6C-8室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>青岛</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>山东省青岛市市北区辽宁路228号科信大厦2007室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>烟台</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>山东省烟台市兴业路10号4楼408室</span>
                            </p>
                        </td>
                    </tr>               
                    <tr>
                        <td rowspan="5">
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>江苏</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>南京</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>江苏省南京市江宁经济技术开发区秣陵街道燕湖路201号</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>徐州</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>江苏省徐州市中山南路113号海云大厦11B01室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>无锡</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>江苏省无锡市人民西路45号金惠大厦梦之岛4A04室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>常州</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>江苏省常州市劳动中路银河湾电脑城C353室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>苏州</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>江苏省苏州市乌鹊桥路143号二楼201室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td rowspan="4">
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>浙江</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>杭州</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>浙江省杭州市西湖区文三路453号中茵大厦B座一楼东</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>金华</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>浙江省金华市工业园区大黄山工业区金帆街736号内3号楼2层西边层</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>宁波</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>浙江省宁波市保稅西区创业2路9号</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>温州</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>浙江省温州市鹿城区黎明西路东方大厦2903室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td rowspan="6">
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>华中</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>河南</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>郑州</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>郑州市文化路68号科技市场数码港811室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td  rowspan="2">
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>湖北</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>武汉</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>武汉市洪山区珞珈山路一号珞珈山大厦A座808号</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>荆州</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>湖北省荆州市红门路资讯广场津谷店九楼912室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>湖南</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>长沙</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>湖南省长沙市人民路国际IT城3楼306室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>江西</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>南昌</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>江西省南昌市西湖区洪都中大道158号鸿雁电子市场I区5号</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>安徽</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>合肥</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>安徽省合肥市高新区柏堰科技园香樟大道188号国信大厦15楼西侧</span>
                            </p>
                        </td>
                    </tr>
                    
                    <tr>
                        <td rowspan="17">
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>华南</span>
                            </p>
                        </td>
                        <td rowspan="8">
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>广东</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>广州</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>广州天河区石牌西路8号展望数码广场20楼2009</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>深圳</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>深圳市福田区华强北路华强电子世界3号楼4楼4C036</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>东莞</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>广东省东莞市常平镇金美村常朗路66号金美科技园1号楼4楼401-405室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>佛山</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>广东省佛山市汾江中路太平洋电脑城2楼2005档</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>中山</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>中山市西区西苑电脑城三楼3049室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>珠海</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>珠海市湾仔沙电脑城3楼3-15A</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>汕头</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>汕头市龙湖区黄河路25号银信大厦2楼</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>湛江</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>湛江市国贸大厦A座B5档</span>
                            </p>
                        </td>
                    </tr>        
                    <tr>
                        <td rowspan="3">
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>广西</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>南宁</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>广西省南宁市星湖路南二里3号星湖综合楼503号</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>桂林</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>广西桂林市解放东路9号正阳门商场3楼16号</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>柳州</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>柳州市三中路39号凯凌新空间A座509号</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>云南</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>昆明</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>云南省昆明市盘龙区环城北路205号集丰北站庭院写字楼1-202室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                    <td rowspan="4">
                        <p class="MsoNormal" align="center" style="text-align:center;">
                            <span>福建</span>
                        </p>
                    </td>
                    <td>
                        <p class="MsoNormal" align="center">
                            <span>厦门</span>
                        </p>
                    </td>
                    <td>
                        <p class="MsoNormal" align="center">
                            <span>厦门市湖滨南路69号电子城205B室</span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p class="MsoNormal" align="center">
                            <span>福州</span>
                        </p>
                    </td>
                    <td>
                        <p class="MsoNormal" align="center">
                            <span>福州市台江区八一七中路与群众路交叉处茶亭国际7层14单元</span>
                        </p>
                    </td>
                </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>龙岩</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>福建省龙岩市新罗区闽西交易城电脑数码广场财富旺角阳光楼A1-3栋909室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>泉州</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>泉州市鲤城区九一路龙宫住宅B栋305室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>海南</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>海口</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>海南省海口市美兰区海秀东路6号望海楼职工宿舍小区101室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td rowspan="3">
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>西南</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>四川</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>成都</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>成都市一环路南一段22号嘉谊大厦1006室</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>重庆</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>重庆</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>重庆市九龙坡区石桥铺科园一路3号渝高大厦6-5</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p class="MsoNormal" align="center" style="text-align:center;">
                                <span>贵州</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>贵阳</span>
                            </p>
                        </td>
                        <td>
                            <p class="MsoNormal" align="center">
                                <span>贵阳市中山西路78号腾达广场A栋8楼6号</span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>`,
                }}
            ></div>
        );
    }

    // 保修范围
    getWarrantyScope() {
        return (
            <div style={textSty}>
                1. 本服务政策仅限于超云在保修期内正常使用时出现的硬件故障；
                2.
                超云不承担由于产品故障给用户造成的损失（如设备停机、数据丢失等）；
                3.
                对于超出服务范围的服务，或保修期外的服务，超云提供有偿服务，具体事宜可向超云服务热线咨询；
                4. 下列情况不属于保修服务范围：
                1）无超云标识或标识残缺的产品；
                2）产品内部的电子元器件对潮湿、酸碱浓度高的环境敏感，湿气、酸碱类物质同污物积聚会形成腐蚀性物质，损害电子元器件，导致产品故障，请用户避免在此类环境中储存和使用产品，由此造成的产品故障不在产品保修范围内；
                3）未按说明书要求，非产品所规定的工作环境或错误安装、保管及使用等造成的故障或损坏（例如温度过高、过低，过于潮湿或干燥，海拔过高，非正常的物理压力，电磁干扰，供电不稳，静电干扰，零地电压过大，输入不合适的电压等）；
                4）由非超云授权机构人员安装、修理、更改或拆卸而造成的故障或损坏；
                5）因使用非超云提供的部件而导致的故障或损坏；
                6）因使用盗版或其他非合法授权软件、非标准或未公开发行的软件造成的故障或损坏；
                7）因意外因素或人为原因（包括但不限于计算机病毒、操作失误、进液、划伤、搬运、
                磕碰、不正确插拔、异物掉入、鼠害、虫害等）导致的故障或损坏；
                8）因遗忘口令而导致的故障；
                9）因客户误操作而破坏随机配置软件系统导致的故障；
                10）因自然灾害等不可抗力（如雷击、火灾、地震等）原因造成的故障或损坏；
            </div>
        );
    }

    // 保修流程
    getWarrantyProcess() {
        return (
            <div style={textSty}>
                说明： 1. 超云服务热线 超云面向全国客户提供 7x24
                小时的服务热线支持，客户可随时拨打超云服务热线 4006-330-360
                寻求支持和帮助。 2. 保修确认手续 用户报修时需要提供如下信息： 1)
                产品型号； 2) 整机序列号（S/N）； 3)
                系统的软硬件配置、详细的故障描述和系统错误信息； 4)
                用户单位名称、联系人、联系方式及详细地址。
            </div>
        );
    }

    // 保修方式
    getWarrantyMode() {
        return (
            <div style={textSty}>
                超云将根据具体情况选择以下所列的一种或多种合适的方式提供保修服务：
                1. 远程问题诊断和技术支持
                客户拨打超云售后服务热线提出服务请求后，超云将与客户合作共同解决问题。在提供任何必要的现场服务之前，超云将首先使用电话或远程支持服务工具（可行的情况下）对超云提供支持的产品进行远程问题诊断，或通过其它方式为远程解决问题提供帮助。
                2. 发货更换
                对于保修期内能简单安装的部件（包括但不限于热拔插硬盘、热拔插电源、显示器、键盘、鼠标），或者客户单独购买的服务器零部件，超云可通过发货更换的方式进行维修，并提供相应的电话安装指导。超云负责将备件寄往客户处的单程运费。客户负责将维修时替换下来的部件寄回至超云并负担单程运费。
                3. 现场硬件更换服务
                如果硬件问题不能通过远程方式或发货更换方式解决，超云将派遣现场服务工程师到客户现场进行硬件的维修，客户有责任提供合理的维修操作条件，包括但不限于超云现场服务工程师进入客户机房的权限、维修时间的申请、适合的电源等。
                说明： 1. 备件和材料
                超云将提供维修所需的备件和材料，确保超云提供支持的硬件设备恢复正常运行。维修更换下来的产品部件归超云所有。
                2. 不间断工作
                超云的现场服务工程师到达客户现场后，立即开始进行不间断服务，直至设备能够重新运行。如需额外的备件或资源，服务工作可暂停，直至这些备件或资源备齐。
            </div>
        );
    }

    // 响应时间
    getWarrantResTime() {
        return (
            <div style={textSty}>
                接到报修后，超云的技术服务人员将于2小时内进行电话响应，及时联系客户解决问题。如果确认需要现场服务，超云的技术服务人员将会根据客户所处的位置，与客户电话预约登门时间，并将在第二个工作日抵达现场（或在途）。
            </div>
        );
    }

    // 特别说明
    getWarrantySpecialTip() {
        return (
            <div style={textSty}>
                1. 优先适用
                本说明如果出现与国家相关法律法规相违背的情况，以相关法律法规为准。
                2. 超云否认的承诺
                除非本说明中明确表示，超云不做任何其它明示或暗示的承诺和保证，包括对产品的可销性和对某一特定用途的适用性的暗示保证。除非超云另外做出明确承诺，否则：
                1）本保修说明仅适用于超云产品出厂时配置的主机和部件（详见客户订单）。任何机构或人员（如销售商）给客户安装的一切非超云部件，超云不承担保修责任。
                2）任何机构和人员（如销售商）在本保修说明之外就客户购买的产品及其附属软硬件设备向客户做出的任何额外承诺，超云将不承担责任；客户应向做出该承诺的机构或人员索要书面证明，以保证这些额外承诺能够兑现。
                3. 数据备份
                请您在接受服务前务必及时将您认为有价值的信息程序进行备份或取回，超云及超云认证服务机构仅负责产品的维修与检测，本文件规定的服务范围内恕不提供任何数据恢复与备份。超云及其认证服务机构不对数据、程序或存储介质的损坏或丢失承担责任。
                4. 替换整机或部件的所有权
                超云为客户更换整机或故障部件后，被更换的原机器或故障部件将由超云收回并享有所有权。
                5. 本说明的解释权归超云数字技术集团有限公司
            </div>
        );
    }

    onClickTabItem(activeTab, id) {
        this.setState({ activeTab: activeTab });
        // document.geti
        // console.log(TAG, "BottomView next:", myRef);
        let anchorElement = document.getElementById(id);

        const yHeight = anchorElement.getBoundingClientRect().y;

        if (activeTab == 0) {
            window.scrollTo(0, 0);
        }

        window.scrollTo({
            left: 0, // x坐标
            top: yHeight - 100, // y 坐标
            behavior: "smooth", // 可选值：smooth、instant、auto
        });
        // window.scrollTo(0, yHeight, {behavior: ‘smooth’});
    }

    onClickSearchBtn() {
        window.open("/product/search");
    }

    adaptHeight(Content50DataSource) {
        // return "auto";
        const result = this.state.isMobile
            ? Content50DataSource.block.children.length * 340 + "px"
            : Math.ceil(Content50DataSource.block.children.length / 4) * 340 +
              "px";
        return result;
    }

    render() {
        const children = [
            // <Banner
            //     id="Banner1_0"
            //     key="Banner1_0"
            //     dataSource={Banner10DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <div
            //     className="p_tab_box_51_wrapper"
            //     key={"p_tab_box_51_wrapper"}
            //     style={{
            //         paddingTop: this.state.isMobile ? "30px" : "60px",
            //         paddingBottom: this.state.isMobile ? "10px" : "60px",
            //     }}
            // >
            //     <div className="p_tab_box_51">
            //         {[
            //             { title: "保修期限", href: "warranty_period" },
            //             { title: "服务范围", href: "services_scope" },
            //             { title: "服务流程", href: "services_process" },
            //             { title: "响应时间", href: "response_time" },
            //             { title: "保修方式", href: "warranty_mode" },
            //             { title: "特别说明", href: "special_tip" },
            //         ].map((item, index) => {
            //             console.log("item", item);
            //             return (
            //                 <a
            //                     // onClick = {()=>{}}
            //                     // href={`#${item.href}`}
            //                     key={"cb" + index}
            //                     className={
            //                         this.state.activeTab == index
            //                             ? "p_tab_item_51_active p_tab_item_51"
            //                             : "p_tab_item_51"
            //                     }
            //                     onClick={() => {
            //                         this.onClickTabItem(index, item.href);
            //                     }}
            //                 >
            //                     {item.title}
            //                 </a>
            //             );
            //         })}
            //     </div>
            // </div>,
            // <img
            //     src="https://img2.baidu.com/it/u=3639955691,11916907&fm=253&fmt=auto&app=138&f=JPEG?w=658&h=171"
            //     alt=""
            //     style={{ width: "100%" }}
            // />,
            <div style={{ position: "relative" }}>
                <img
                    src={require("../../assets/techsuupport_bg_3.png")}
                    alt=""
                    style={{ width: "100%" }}
                />

                {/* <div
                    style={{
                        position: "absolute",
                        // top: this.state.isMobile ? "3rem" : "16rem",
                        // left: this.state.isMobile ? "2rem" : "16rem",
                        top: this.state.isMobile ? "3rem" : "40%",
                        left: this.state.isMobile ? "1rem" : "15%",
                        color: "#fff",
                        fontSize: this.state.isMobile ? "1.4rem" : "2.4vw",
                        zIndex: 100,
                        fontWeight: 700,
                    }}
                >
                    技术支持
                </div> */}
            </div>,
            <div
                id="warranty_period"
                key="warranty_period"
                style={{
                    height: "auto",
                    width: "100%",
                    // background: "#fafafa",
                    paddingBottom: "60px",
                    marginTop: "80px",
                    // marginBottom: "60px",
                }}
            >
                <CSCTitle
                    title="服务网点"
                    key="warranty_period"
                    isMobile={this.state.isMobile}
                    style={{
                        margin: 0,
                        height: "auto",
                        marginTop: "70px",
                        marginBottom: "80px",
                    }}
                    textSty={{
                        fontSize: this.state.isMobile ? "20px" : "28px",
                        fontWeight: "700",
                    }}
                />
                {this.getServiceNetwork()}
            </div>,
            // <div
            //     id="services_scope"
            //     key="services_scope"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="服务范围"
            //         key="services_scope"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getWarrantyScope()},
            // </div>,
            // <div
            //     id="services_process"
            //     key="services_process"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="服务流程"
            //         key="services_scope"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getWarrantyProcess()},
            // </div>,

            // <div
            //     id="response_time"
            //     key="response_time"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="响应时间"
            //         key="response_time"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getWarrantResTime()}
            // </div>,
            // <div
            //     id="warranty_mode"
            //     key="warranty_mode"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="保修方式"
            //         key="response_time"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getWarrantyMode()}
            //     {/* <Content5
            //         // id="warranty_mode"
            //         // key="warranty_mode"
            //         dataSource={Content50DataSource}
            //         isMobile={this.state.isMobile}
            //         content5title={"保修方式"}
            //         cname={"warranty_mode"}
            //     /> */}
            // </div>,
            // <div
            //     id="special_tip"
            //     key="special_tip"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="特别说明"
            //         key="special_tip"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getWarrantySpecialTip()}
            //     {/* <Content5
            //         // id="special_tip"
            //         // key="special_tip"
            //         dataSource={Content50DataSource}
            //         isMobile={this.state.isMobile}
            //         content5title={"特别说明"}
            //         cname={"special_tip"}
            //     /> */}
            // </div>,
        ];
        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
                style={{  background:"rgba(248, 247, 247)"  }}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {this.state.show && children}

                <CSCBackTop
                    isMobile={this.state.isMobile}
                    key="service_network"
                />
                {/* 如果不是 dva 2.0 替换成 {children} end */}
            </div>
        );
    }
}

const style = React.cree;
