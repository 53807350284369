/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import { Row, Col } from "antd";
import { FileImageTwoTone, DownloadOutlined } from "@ant-design/icons";
import Content0 from "./Content0";
import CSCTitle from "../widget/CSCTitle";
import owRequest from "../libs/request";
import config from "../libs/config";
import { Content00DataSource } from "./data.source";

import "./less/antMotionStyle.less";
// import "./less/product.less";

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const location = window.location;

export default class Solution extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
            activeTab: 0,
            solutionList: [],
        };
    }

    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
        /* 如果不是 dva 2.0 请删除 end */
        let url = window.location.href;
        let sCode = url.split("?s=")[1];
        // console.log("sCode", sCode);
        this.sCode = sCode;
        this.getSolutionList();
    }

    onClickTabItem(activeTab) {
        this.setState({ activeTab: activeTab });
    }

    getSolutionList() {
        //
        owRequest
            .get(`/office-website/solution?solution_category=${this.sCode}`)
            .then((res) => {
                // console.log("res", res);
                if (res && res.code == 2000) {
                    let ret = {};
                    if (res.data.rows && res.data.rows.length) {
                        ret = res.data.rows;
                    }
                    for(let item of ret){
                        if(item.project_bg){
                            item['project_bg'] = item.project_bg.replace(/&nbsp;/g, "")
                        }
                    }
                    console.log("rettt", ret);
                    this.setState({ solutionList: ret || {} });
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    }

    render() {
        const children = [
            // <img
            //     src="https://img2.baidu.com/it/u=3639955691,11916907&fm=253&fmt=auto&app=138&f=JPEG?w=658&h=171"
            //     alt=""
            //     style={{ width: "100%" }}
            // />,
            <div style={{ position: "relative" }}>
                {/* <img
                    src={require("../assets/solution_bg.png")}
                    alt=""
                    style={{
                        width: "100%",
                    }}
                /> */}
                {this.sCode == 1 && (
                    <img
                        src={require("../assets/solution_bg_1.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.sCode == 2 && (
                    <img
                        src={require("../assets/solution_bg_2.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.sCode == 3 && (
                    <img
                        src={require("../assets/solution_bg_3.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.sCode == 4 && (
                    <img
                        src={require("../assets/solution_bg_4.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.sCode == 5 && (
                    <img
                        src={require("../assets/solution_bg_5.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.sCode == 6 && (
                    <img
                        src={require("../assets/solution_bg_6.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.sCode == 7 && (
                    <img
                        src={require("../assets/solution_bg_7.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.sCode == 8 && (
                    <img
                        src={require("../assets/solution_bg_8.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.sCode == 9 && (
                    <img
                        src={require("../assets/solution_bg_9.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.sCode == 10 && (
                    <img
                        src={require("../assets/solution_bg_10.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.sCode == 11 && (
                    <img
                        src={require("../assets/solution_bg_11.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.sCode == 12 && (
                    <img
                        src={require("../assets/solution_bg_11.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.sCode == 13 && (
                    <img
                        src={require("../assets/solution_bg_12.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.sCode == 14 && (
                    <img
                        src={require("../assets/solution_bg_13.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.sCode == 15 && (
                    <img
                        src={require("../assets/solution_bg_14.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.sCode == 16 && (
                    <img
                        src={require("../assets/solution_bg_15.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {/* <div
                    style={{
                        position: "absolute",
                        // top: this.state.isMobile ? "3rem" : "16rem",
                        // left: this.state.isMobile ? "2rem" : "16rem",
                        // color: "#fff",
                        // fontSize: this.state.isMobile ? "20px" : "42px",
                        top: this.state.isMobile ? "3rem" : "40%",
                        left: this.state.isMobile ? "1rem" : "15%",
                        color: "#fff",
                        fontSize: this.state.isMobile ? "1.4rem" : "2.4vw",
                        zIndex: 100,
                        fontWeight: 700,
                    }}
                >
                    解决方案
                </div> */}
            </div>,

            <Content0
                id="Content0_0"
                key="Content0_0"
                dataSource={Content00DataSource}
                isMobile={this.state.isMobile}
                solutionList={this.state.solutionList}
                sCode={this.sCode}
            />,
            // </div>,
        ];
        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
                style={{background:"rgb(249, 248, 248)"}}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {this.state.show && children}

                {/* 如果不是 dva 2.0 替换成 {children} end */}
            </div>
        );
    }
}
