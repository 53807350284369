/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import {
    Row,
    Col,
    AutoComplete,
    Cascader,
    DatePicker,
    Input,
    InputNumber,
    Select,
    Button,
    Form,
    Tooltip,
    Checkbox,
    Empty,
} from "antd";
import { enquireScreen } from "enquire-js";
import CSCTitle from "../../widget/CSCTitle";
import owRequest from "../../libs/request";

import "./less/antMotionStyle.less";
import "./less/product.less";

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const location = window.location;

const textSty = {
    fontSize: isMobile ? "13px" : "18px",
    // textIndent: isMobile ? "20px" : "40px",
    maxWidth: "980px",
    padding: isMobile ? "15px" : "20px",
    margin: "0 auto",
};

export default class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
            activeTab: 0,
            jobList: [],
            searchTxt: "",
        };
    }

    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
        /* 如果不是 dva 2.0 请删除 end */
        let url = window.location.href;
        let rCode = url.indexOf("society") != -1;
        // console.log("rCode", rCode);
        if (url.indexOf("society") != -1) {
            this.rCode = 2;
        }
        if (url.indexOf("school") != -1) {
            this.rCode = 1;
        }
        this.getRecruitList(rCode);
    }

    getRecruitList(rCode) {
        let url = "";
        if (this.state.searchTxt !== "") {
            url = `/office-website/recruit?recruit_type=${this.rCode}&job_title=${this.state.searchTxt}`;
        } else {
            url = `/office-website/recruit?recruit_type=${this.rCode}`;
        }
        owRequest
            .get(url)
            .then((res) => {
                if (res && res.code == 2000) {
                    let ret = [];
                    ret = res.data.rows;
                    this.setState({ jobList: ret || [] });
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    }

    // 服务范围
    getServiceScope() {
        return (
            <div style={textSty}>
                本服务标准针对以下几种情况提出：
                <br />
                1、
                产品超出保修期，客户未购买延保服务的产品，需要超云提供现场服务的情况；
                <br />
                2、
                产品在保修期内，但服务内容超出《超云产品标准保修政策》所规定的服务范围的情况。
            </div>
        );
    }

    // 服务种类
    getServiceTypes() {
        return (
            <div style={textSty}>
                1、 硬件安装
                <br />
                2、 硬件维修 <br />
                3、 硬件配置扩容 <br />
                4、 操作系统的安装与调试 <br />
                5、 磁盘阵列的重建、修改、创建等服务 <br /> 6、 Call Center服务
                <br />
                7、 项目服务：包括驻场、技术服务日、布线、除尘、巡检等
                本服务标准针对以下几种情况提出：
                <br />
            </div>
        );
    }

    // 特别说明
    getOtherTips() {
        return (
            <div style={textSty}>
                1、
                经超云有偿服务更换或增加的产品部件自有偿服务完成之日起享有12个月的保修期。
                <br />
                2、
                增值服务收费标准请拨打超云服务中心热线服务支持电话：4006330360。
            </div>
        );
    }

    getTitleDes() {
        return (
            <div style={{ ...textSty, textIndent: isMobile ? "20px" : "40px" }}>
                超云数字技术集团有限公司（下简称“超云”）通过统一的管理、统一的培训、统一的服务标准、统一的规范和统一的考核，使得客户可以享受到周到、完美、技术专业、形象亲和的超云售后服务。
            </div>
        );
    }

    getServicesStander() {
        return <div style={textSty}>服务标准</div>;
    }

    onClickTabItem(activeTab, id) {
        this.setState({ activeTab: activeTab });
        // document.geti
        // console.log(TAG, "BottomView next:", myRef);
        let anchorElement = document.getElementById(id);
        console.log(
            "anchorElement",
            anchorElement,
            anchorElement.getBoundingClientRect()
        );
        const yHeight = anchorElement.getBoundingClientRect().y;

        if (activeTab == 0) {
            window.scrollTo(0, 0);
        }

        // console.log("yHeight", yHeight);
        window.scrollTo({
            left: 0, // x坐标
            top: yHeight - 100, // y 坐标
            behavior: "smooth", // 可选值：smooth、instant、auto
        });

        // window.scrollTo(0, yHeight, {behavior: ‘smooth’});
    }

    onClickSearch() {
        this.getRecruitList();
    }

    adaptHeight(Content50DataSource) {
        // return "auto";
        const result = this.state.isMobile
            ? Content50DataSource.block.children.length * 340 + "px"
            : Math.ceil(Content50DataSource.block.children.length / 4) * 340 +
              "px";
        return result;
    }

    onClickTitleItem(item) {
        window.open(
            `/about/recruit/detail?jobid=${item.id}&rcode=${this.rCode}`
        );
    }

    getPersonnelItem(item) {
        return (
            <div
                style={{
                    borderBottom: "1px solid #d9d9d9",
                    paddingBottom: "10px",
                    fontSize: "1rem",
                    marginBottom: "20px",
                    display:"flex",
                    flexDirection:"row",
                    background:"#fff",
                    backgroundColor:"#fff",
                    borderRadius: "1rem",
                    justifyContent:"space-between",
                    // cursor:"pointer"
                }}
            >
                <div  
                    style={{
                        display:"flex",
                        flexDirection:"row",
                        justifyContent:"space-between",
                    }}
                >
                    <img 
                        src={require("../../assets/avatar_icon.png")} 
                        alt=""   
                        style={{ width: "3rem", }} 
                    />
                    <div
                        style={{ marginBottom: "5px",  marginLeft:"1.5rem" }}
                        
                    >
                        <div style={{color:"#333", fontWeight:700, fontSize:"18px"}}>
                            {item.job_title || ""}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop:"0.4rem"
                            }}
                        >
                            {/* job_academic_requirement */}
                            {/* job_work_place */}
                            {/* job_work_experience */}
                            {/* createdAt */}
                            <div>
                                {`${item.job_work_place} ` || ""} {`   |    `}
                                {` ${item.job_academic_requirement} ` || ""} {`    |   `}
                                {`${item.job_work_experience} ` || ""}
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>
                <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
                    <div style={{fontSize:"0.8rem"}}>
                        {` ${item.createdAt && item.createdAt.slice(0, 10)}`}
                    </div>
                    <div className="recurite_detail" onClick={() => {
                            this.onClickTitleItem(item);
                        }}>
                        了解详情
                    </div>
                </div>
            </div>
        );
    }

    onChangeTSearch = (e) => {
        // console.log("onChangeTSearch", e.target.value);
        this.setState({
            searchTxt: e.target.value,
        });
    };

    render() {
        const children = [
            <div>
                {/* {!this.state.isMobile ? (
                    <img
                        src={require("../../assets/head_recurite.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                ) : (
                    <img
                        src={require("../../assets/head_recurite.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )} */}
                {this.rCode == 1 ? (
                    <img
                        src={require("../../assets/head_recurite_2.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                ) : (
                    <img
                        src={require("../../assets/head_recurite.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}

                {/* rCode */}
            </div>,
            <div
                key={"cscsearch_1"}
                style={{
                    background: "#fff",
                    padding: !this.state.isMobile ? "30px" : "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    paddingTop: "3rem",
                }}
            >
                <Input.Group compact>
                    {this.state.isMobile ? (
                        <Input
                            // width={"1000px"}
                            style={{ width: "1000px !important" }}
                            // defaultValue="请输入产品型号"
                            placeholder="请输入职位描述"
                            onChange={(e) => {
                                this.onChangeTSearch(e);
                            }}
                        />
                    ) : (
                        <Input
                            style={{ width: "40% !important" }}
                            // defaultValue="请输入产品型号"
                            placeholder="请输入职位描述"
                            onChange={(e) => {
                                this.onChangeTSearch(e);
                            }}
                        />
                    )}

                    {this.state.isMobile ? (
                        <Button
                            type="primary"
                            size="large"
                            onClick={() => {
                                this.onClickSearch();
                            }}
                        >
                            搜索
                        </Button>
                    ) : (
                        <Button
                            type="primary"
                            icon={"search"}
                            size="large"
                            onClick={() => {
                                this.onClickSearch();
                            }}
                        >
                            搜索
                        </Button>
                    )}
                </Input.Group>
            </div>,
            <div
                id="services_scope"
                key="services_scope"
                style={{
                    ...textSty,
                    height: "auto",
                    width: "100%",
                    background: "#fff",
                    paddingBottom: "60px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: "20px",
                        fontSize: "1.03rem",
                    }}
                >
                    {/* <div style={{ fontWeight: 600, fontSize: "1.1rem" }}>
                        {"最新发布"}
                    </div> */}
                    {/* {!this.state.isMobile ? (
                        // <div>显示1-10，共24职位</div>
                        <div>共{this.state.jobList.length}职位</div>
                    ) : null} */}
                </div>
                <div>
                    {this.state.jobList.length == 0 ? (
                        <div style={{ marginTop: "200px" }}>
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                imageStyle={{}}
                                description={""}
                            />
                        </div>
                    ) : null}
                    {this.state.jobList.map((item) => {
                        return this.getPersonnelItem(item);
                    })}
                </div>
                {/* {this.getTitleDes()}, */}
            </div>,
            // <Banner
            //     id="Banner1_0"
            //     key="Banner1_0"
            //     dataSource={Banner10DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <div
            //     className="p_tab_box_51_wrapper"
            //     key={"p_tab_box_51_wrapper"}
            //     style={{
            //         paddingTop: this.state.isMobile ? "30px" : "60px",
            //         paddingBottom: this.state.isMobile ? "10px" : "60px",
            //     }}
            // >
            //     <div className="p_tab_box_51">
            //         {[
            //             { title: "服务范围", href: "services_scope" },
            //             { title: "服务种类", href: "services_type" },
            //             { title: "服务标准", href: "services_stander" },
            //             { title: "其它说明", href: "other_tips" },
            //         ].map((item, index) => {
            //             console.log("item", item);
            //             return (
            //                 <a
            //                     // onClick = {()=>{}}
            //                     // href={`#${item.href}`}
            //                     key={"cb" + index}
            //                     className={
            //                         this.state.activeTab == index
            //                             ? "p_tab_item_51_active p_tab_item_51"
            //                             : "p_tab_item_51"
            //                     }
            //                     onClick={() => {
            //                         this.onClickTabItem(index, item.href);
            //                     }}
            //                 >
            //                     {item.title}
            //                 </a>
            //             );
            //         })}
            //     </div>
            // </div>,

            // <div
            //     id="services_scope"
            //     key="services_scope"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="服务范围"
            //         key="services_scope"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getServiceScope()},
            // </div>,
            // <div
            //     id="services_type"
            //     key="services_type"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="服务种类"
            //         key="services_type"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getServiceTypes()},
            // </div>,
            // <div
            //     id="services_stander"
            //     key="services_stander"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="服务标准"
            //         key="services_stander"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getServicesStander()},
            // </div>,

            // <div
            //     id="other_tips"
            //     key="other_tips"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="其它说明"
            //         key="other_tips"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getOtherTips()}
            // </div>,
        ];
        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {this.state.show && children}
                {/* 如果不是 dva 2.0 替换成 {children} end */}
            </div>
        );
    }
}

const style = React.cree;
