/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import { Row, Col, BackTop } from "antd";
import { FileImageTwoTone, DownloadOutlined } from "@ant-design/icons";
// import Content0 from "./Content0";
// import Content7 from "./Content7";
import Banner from "../Banner/index";
// import CSCButton from "../widget/CSCButton";
import CSCTitle from "../widget/CSCTitle";
import CSCBackTop from "../widget/CSCBackTop";
import Feature2 from "./Feature2";
import Specifications from "./Specifications";
import owRequest from "../libs/request";
import config from "../libs/config";

import {
    Banner10DataSource,
    Feature20DataSource,
    Content50DataSource,
} from "./data.source";

import "./less/antMotionStyle.less";
// import "./less/product.less";

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const location = window.location;

export default class ProductDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
            activeTab: 0,
            productDetailObj: {},
            htmlStr: `
            `,
        };
    }

    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
        /* 如果不是 dva 2.0 请删除 end */

        let url = window.location.href;
        let pCode = url.split("?productid=")[1];
        // console.log("pCode", pCode);
        this.pCode = pCode;
        this.getProductDetailData();
    }

    onClickTabItem(activeTab) {
        this.setState({ activeTab: activeTab });
    }

    getProductDetailData() {
        owRequest
            .post(`/office-website/search/product`, { id: this.pCode })
            .then((res) => {
                // console.log("res", res);
                if (res && res.code == 2000) {
                    let ret = {};
                    if (res.data.rows && res.data.rows.length) {
                        ret = res.data.rows[0];
                        let arr = [];
                        try {
                            if (ret.product_paper) {
                                arr = JSON.parse(ret.product_paper);
                            }
                        } catch (e) {
                            arr = [];
                        }
                        ret["fileList"] = arr;
                    }
                    // console.log("rettt", ret);
                    this.setState({ productDetailObj: ret || {} });
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    }

    jumpToPaper(url) {
        window.open(url);
        // window.location.href = url;
    }

    render() {
        const children = [
            // <Banner
            //     id="Banner1_0"
            //     key="Banner1_0"
            //     dataSource={Banner10DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            <div style={{ position: "relative" }}>
                {!this.state.isMobile ? (
                    <img
                        src={require("../assets/product_detail.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                ) : (
                    <img
                        src={require("../assets/product_detail.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                <div
                    style={{
                        position: "absolute",
                        top: this.state.isMobile ? "3rem" : "40%",
                        left: this.state.isMobile ? "1rem" : "15%",
                        color: "#fff",
                        fontSize: this.state.isMobile ? "1.4rem" : "2.4vw",
                        zIndex: 100,
                        fontWeight: 700,
                    }}
                >
                    产品详情
                </div>
            </div>,
            <div style={{ marginTop: "80px" }}>
                <CSCTitle title="产品概述" key="p_d_title_1" />
            </div>,
            // </div>,
            <Feature2
                id="Feature2_0"
                key="Feature2_0"
                dataSource={Feature20DataSource}
                isMobile={this.state.isMobile}
                productDetailObj={this.state.productDetailObj}
            />,
            <div>
                {this.state.productDetailObj.product_features ? (
                    <div style={{ marginTop: "170px" }}>
                        <CSCTitle title="产品特性" key="p_d_title_2" />
                    </div>
                ) : null}
            </div>,
            <div>
                {this.state.productDetailObj.product_features ? (
                    <div
                        key="p_d_table_1"
                        style={{
                            maxWidth: "1200px",
                            width: "100%",
                            display: "flex",
                            // alignItems: "center",
                            // justifyContent: "center",
                            margin: "0 auto",
                            marginTop: "4rem",
                            marginBottom: "4rem",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            paddingBottom: "6rem",
                            flexDirection: "column",
                            fontSize: "18px",
                            textAlign: "start",
                            lineHeight: "30px",
                        }}
                        dangerouslySetInnerHTML={{
                            __html: this.state.productDetailObj
                                .product_features,
                        }}
                    ></div>
                ) : null}
            </div>,
            <div>
                {this.state.productDetailObj.product_core_competence ? (
                    <div style={{ marginTop: "120px" }}>
                        <CSCTitle title="核心能力" key="p_d_title_2" />
                    </div>
                ) : null}
            </div>,
            <div>
                {this.state.productDetailObj.product_core_competence ? (
                    <div
                        key="p_d_table_1"
                        style={{
                            maxWidth: "1200px",
                            width: "100%",
                            display: "flex",
                            // alignItems: "center",
                            // justifyContent: "center",
                            margin: "0 auto",
                            marginTop: "4rem",
                            marginBottom: "4rem",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            paddingBottom: "6rem",
                            flexDirection: "column",
                            fontSize: "18px",
                            textAlign: "start",
                            lineHeight: "30px",
                        }}
                        dangerouslySetInnerHTML={{
                            __html: this.state.productDetailObj
                                .product_core_competence,
                        }}
                    ></div>
                ) : null}
            </div>,
            <div>
                {this.state.productDetailObj.product_specifications ? (
                    <div style={{ marginTop: "120px" }}>
                        <CSCTitle title="技术规格" key="p_d_title_2" />
                    </div>
                ) : null}
            </div>,
            <div>
                {this.state.productDetailObj.product_specifications ? (
                    <div
                        key="p_d_table_1"
                        style={{
                            maxWidth: "1200px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "0 auto",
                            marginTop: "6rem",
                            marginBottom: "4rem",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            paddingBottom: "6rem",
                        }}
                        dangerouslySetInnerHTML={{
                            __html: this.state.productDetailObj
                                .product_specifications,
                        }}
                    ></div>
                ) : null}
            </div>,

            <div style={{ marginBottom: "140px", marginTop: "6rem" }}>
                {this.state.productDetailObj.fileList &&
                Array.isArray(this.state.productDetailObj.fileList) &&
                this.state.productDetailObj.fileList.length ? (
                    <CSCTitle title="资源下载" key="p_d_title_3" />
                ) : null}

                {this.state.productDetailObj.fileList &&
                Array.isArray(this.state.productDetailObj.fileList) &&
                this.state.productDetailObj.fileList.length ? (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            // justifyContent: "space-around",
                            // maxWidth: "900px",
                            alignItems: "center",
                            // width: "100%",
                        }}
                    >
                        {this.state.productDetailObj.fileList.map((item) => {
                            return (
                                <div>
                                    <div
                                        key={"p_d_download_1"}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginBottom: "2rem",
                                        }}
                                    >
                                        <div
                                            onClick={() => {
                                                this.jumpToPaper(
                                                    config.pic_url + item.url
                                                );
                                            }}
                                            style={{
                                                // width: "15rem",
                                                // height: "8rem",

                                                border: "2px solid #ddd",
                                                borderRadius: "5px",
                                                display: "flex",
                                                position: "relative",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                paddingLeft: "1.5rem",
                                                paddingRight: "1.5rem",
                                                paddingTop: "1rem",
                                                paddingBottom: "1rem",
                                                cursor: "pointer",
                                            }}
                                        >
                                            {/* <FileImageTwoTone
                                                style={{
                                                    fontSize: "40px",
                                                    margin: "15px",
                                                }}
                                            /> */}
                                            <img
                                                src={require("../assets/pdf_icon.png")}
                                                alt=""
                                                style={{
                                                    width: "50px",
                                                    height: "50px",
                                                    marginRight: "1rem",
                                                }}
                                            />
                                            <div style={{ fontSize: "15px" }}>
                                                <div>{item.name || ""}</div>
                                                {/* <div>产品白皮书</div> */}
                                            </div>
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    bottom: "0px",
                                                    right: "2px",
                                                    zIndex: 100,
                                                }}
                                            >
                                                <DownloadOutlined
                                                    style={{ fontSize: "22px" }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : null}
            </div>,
        ];
        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {this.state.show && children}

                <CSCBackTop
                    isMobile={this.state.isMobile}
                    key="product_detail"
                />
                {/* 如果不是 dva 2.0 替换成 {children} end */}
            </div>
        );
    }
}
