import React from "react";
import { Row, Col, Empty } from "antd";
import { TweenOneGroup } from "rc-tween-one";
import TweenOne from "rc-tween-one";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import { FileImageTwoTone, DownloadOutlined } from "@ant-design/icons";
import { getChildrenToRender } from "./utils";
import config from "../../libs/config";

class Content5 extends React.PureComponent {

 constructor(props) {
        super(props);
        this.state = {
            isHoverDetail:"",
            isHoverDetail_outer:""
        };
    }

    jumpToDetail(url) {
        window.open(config.pic_url + url);
    }

    handleMouseEnter_outer(data, id){
        console.log('data',data,id)
        if(data === 1){
            this.setState({isHoverDetail_outer: id})
        }
        if(data === 2){
            this.setState({isHoverDetail_outer: ""})
        }
    }

    getChildrenToRender = (data) =>
        data.map((item) => {
            const { ...props } = this.props;
            return (
                <Col key={item.name} {...item}>
                    <a
                        {...item.children.wrapper}
                        style={{
                            height: "9rem",
                            display: "flex",
                            flexDirection: "row",
                            // justifyContent: "center",
                            alignItems: "center",
                        }}
                        onClick={() => {
                            this.jumpToDetail(item.children.manual_url);
                        }}
                    >
                        {/* <span {...item.children.img}>
                            <img
                                src={require("../../assets/4.jpeg")}
                                height="100%"
                                alt="img"
                            />
                        </span> */}
                        {/* {item.children.content ? (
                            <p
                                style={{
                                    textAlign: "center",
                                    fontSize: "22px",
                                    fontWeight: 600,
                                }}
                                {...item.children.content}
                            >
                                {item.children.content.children}
                            </p>
                        ) : null} */}

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                // alignItems: "center",
                            }}
                        >
                            <img
                                src={require("../../assets/pdf_icon.png")}
                                alt=""
                                style={{ width: "70px", height: "70px", marginLeft:"10px" }}
                            />
                            <div
                                style={{
                                    width: "100%",
                                    // marginTop: "0px",
                                    fontSize: "18px",
                                    wordWrap: "break-wrap",
                                    marginLeft: "10px",
                                    color:"#00AA71",
                                    textAlign:"right",
                                    fontWeight:600,
                                    marginTop:"-10px",
                                    marginRight:"15px"
                                }}
                            >
                                {item.children.content}
                            </div>
                        </div>
                        <div
                             onMouseEnter={() => {
                                this.handleMouseEnter_outer(1, item.name);
                            }}
                            onMouseLeave={() => {
                                this.handleMouseEnter_outer(2);
                            }}
                            style={{
                                position: "absolute",
                                bottom: "15px",
                                right: "20px",
                                fontWeight:600,
                                zIndex: 100,
                                border: "3px solid #00AA71",
                                color: this.state.isHoverDetail_outer !== item.name ? "#00AA71" : "#fff",
                                background: this.state.isHoverDetail_outer !== item.name ? '#fff' :"#00AA71",
                                width:"6rem",
                                height:"1.6rem",
                                borderRadius:"0.8rem",
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center"
                            }}
                        >
                            下载
                            {/* <DownloadOuztlined style={{ fontSize: "22px" }} /> */}
                        </div>
                    </a>
                </Col>
            );
        });

    render() {
        const { userMannualList, ...props } = this.props;
        const { dataSource } = props;
        delete props.dataSource;
        delete props.isMobile;
        // children: [
        //     {
        //         name: "block4",
        //         className: "block",
        //         md: 6,
        //         xs: 24,
        //         children: {
        //             wrapper: { className: "content5-block-content" },
        //             img: {
        //                 children:
        //                     "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
        //             },
        //             content: { children: "R8428 G11" },
        //             desc: {
        //                 children: "超云R5210 G12 111111",
        //             },
        //         },
        //     },
        // ],
        let datas = [];
        // console.log("userMannualList", userMannualList);

        for (let item of userMannualList) {
            datas.push({
                name: item.id,
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    manual_url: item.manual_url,
                    content: item.manual_name,
                    desc: {
                        children: "超云R5210 G12 111111",
                    },
                },
            });
        }

        const childrenToRender = this.getChildrenToRender(
            // dataSource.block.children
            datas
        );
        return (
            <div
                {...props}
                {...dataSource.wrapper}
                style={{ paddingTop: "0px", height: "auto" }}
            >
                <div
                    {...dataSource.page}
                    style={{
                        paddingTop: "70px",
                        paddingBottom: "70px",
                        height: "auto",
                    }}
                >
                    <div
                        id={props.id}
                        key="title"
                        {...dataSource.titleWrapper}
                        style={{
                            fontSize: "30px",
                            color: "rgba(0, 0, 0, 0.8)",
                            fontWeight: 500,
                        }}
                    >
                        {/* {dataSource.titleWrapper.children.map(
                            getChildrenToRender
                        )} */}
                        <span style={{
                            fontSize: "30px",
                            color: "rgba(0, 0, 0, 0.8)",
                            borderBottom: "4px solid #2FB467",
                            paddingBottom:"0.6rem",
                            fontWeight: 500,
                            display:"inline-block"
                        }}>
                            {props.content5title || ""}
                        </span>
                    </div>
                    <OverPack
                        className={`content-template ${props.className}`}
                        {...dataSource.OverPack}
                    >
                        <TweenOne
                            forcedReplay={false}
                            component={Row}
                            key="ul"
                            enter={{
                                y: "+=30",
                                opacity: 0,
                                type: "from",
                                ease: "easeInOutQuad",
                            }}
                            leave={{
                                y: "+=30",
                                opacity: 0,
                                ease: "easeInOutQuad",
                            }}
                            {...dataSource.block}
                        >
                            {datas && datas.length ? (
                                childrenToRender
                            ) : (
                                // <div>123</div>
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={""}
                                />
                            )}
                            {/* {} */}
                        </TweenOne>
                    </OverPack>
                </div>
            </div>
        );
    }
}

export default Content5;
