import React, {Component} from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {enquireScreen} from 'enquire-js'
// import Header from "./Home/Nav0";
// import Footer from "./Home/Footer0";
import Home from './Home'
import Product from './Product'
import ProductDetail from './ProductDetail'
import ProductSearch from './ProductSearch'
import Solution from './Solution'
import SolutionDetail from './SolutionDetail'
// import TechSupport from "./TechSupport";
import WarrantyPolicy from './TechSupport/WarrantyPolicy'
import ValueAddService from './TechSupport/ValueAddService'
import ServiceNetwork from './TechSupport/ServiceNetwork'
import UserManual from './TechSupport/UserManual'
import DriverDownload from './TechSupport/DriverDownload'
import SelfQuery from './TechSupport/SelfQuery'

import InformatioSafety from './TechSupport/InformatioSafety'
import CompanyIntro from './About/CompanyIntro'
import Recruit from './About/Recruit'
import RecruitDetail from './About/RecruitDetail'
import RecruitExplain from './About/RecruitExplain'

import NewsList from './About/NewsList'

import Header from './Nav/Nav3'
import Footer from './Footer'
import {Footer10DataSource} from './Footer/data.source'
import {Nav30DataSource} from './Nav/data.source'
import CSCFloatBox from './widget/CSCFloatBox'
import CSCConsultingBtn from './widget/CSCConsultingBtn'
// import { Nav00DataSource, Footer00DataSource } from "./Home/data.source.js";

let isMobile
enquireScreen((b) => {
    isMobile = b
})

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMobile: false,
        }
    }
    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({isMobile: !!b})
        })
    }
    render() {
        return (
            <Router>
                <div>
                    <div
                        class="CSCHeader"
                        style={{
                            position: 'fixed',
                            width: '100%',
                            top: 0,
                            background: '#fff',
                            zIndex: 1000,
                        }}
                    >
                        <Header
                            id="Nav3_0"
                            key="Nav3_0"
                            dataSource={Nav30DataSource}
                            isMobile={this.state.isMobile}
                        />
                    </div>
                    <CSCFloatBox isMobile={this.state.isMobile} />
                    <CSCConsultingBtn isMobile={this.state.isMobile} />

                    <div
                        style={{
                            marginBottom: '100px',
                            position: 'relative',
                            marginTop: '60px',
                        }}
                    >
                        <div
                            style={{
                                minHeight: 'calc(100vh - 63px)',
                                width: '100%',
                            }}
                        >
                            <Switch>
                                <Route exact path="/" component={Home} />
                                <Route
                                    exact
                                    path="/product"
                                    component={Product}
                                />
                                <Route
                                    exact
                                    path="/solution"
                                    component={Solution}
                                />
                                {/* 服务器产品 */}
                                <Route
                                    exact
                                    path="/product/server"
                                    component={Product}
                                />
                                {/* 存储产品 */}
                                <Route
                                    exact
                                    path="/product/memory"
                                    component={Product}
                                />
                                {/* 信创产品 */}
                                <Route
                                    exact
                                    path="/product/innovate"
                                    component={Product}
                                />
                                {/* <Route
                                    exact
                                    path="/techsupport"
                                    component={TechSupport}
                                /> */}
                                {/* 保修政策 */}
                                <Route
                                    exact
                                    path="/techsupport/warranty/policy"
                                    component={WarrantyPolicy}
                                />
                                {/* 增值服务 */}
                                <Route
                                    exact
                                    path="/techsupport/value/add/service"
                                    component={ValueAddService}
                                />
                                {/* 服务网点 */}
                                <Route
                                    exact
                                    path="/techsupport/service/network"
                                    component={ServiceNetwork}
                                />
                                {/* 用户手册 */}
                                <Route
                                    exact
                                    path="/techsupport/user/manual"
                                    component={UserManual}
                                />
                                {/* 驱动下载 */}
                                <Route
                                    exact
                                    path="/techsupport/drive/download"
                                    component={DriverDownload}
                                />
                                {/* 自助查询 */}
                                <Route
                                    exact
                                    path="/techsupport/self/query"
                                    component={SelfQuery}
                                />
                                {/* 信息安全 */}
                                <Route
                                    exact
                                    path="/techsupport/infomation/safety"
                                    component={InformatioSafety}
                                />
                                {/* 公司介绍 */}
                                <Route
                                    exact
                                    path="/about/company/intro"
                                    component={CompanyIntro}
                                />
                                {/* 社招校招 */}
                                {/* <Route
                                    exact
                                    path="/about/recruit/?recruit_type=2"
                                    component={Recruit}
                                /> */}
                                <Route
                                    exact
                                    path="/about/recruit/society"
                                    component={Recruit}
                                />
                                {/* 校招 */}
                                {/* <Route
                                    exact
                                    path="/about/recruit?recruit_type=1"
                                    component={Recruit}
                                /> */}
                                <Route
                                    exact
                                    path="/about/recruit/school"
                                    component={Recruit}
                                />
                                {/* 招聘详情 */}
                                <Route
                                    exact
                                    path="/about/recruit/detail"
                                    component={RecruitDetail}
                                />
                                <Route
                                    exact
                                    path="/about/recruit/explain"
                                    component={RecruitExplain}
                                />

                                {/* 新闻列表 */}
                                <Route
                                    exact
                                    path="/about/news/list"
                                    component={NewsList}
                                />
                                <Route
                                    exact
                                    path="/solution/detail"
                                    component={SolutionDetail}
                                />
                                {/* <Route
                                    exact
                                    path="/about"
                                    component={TechSupport}
                                /> */}
                                <Route
                                    exact
                                    path="/product/detail"
                                    component={ProductDetail}
                                />
                                <Route
                                    exact
                                    path="/product/search"
                                    component={ProductSearch}
                                />
                                <Route path="/b" component={Product} />
                                <Route path="/c" component={Product} />
                            </Switch>
                        </div>

                        <div
                            style={{
                                // position: "absolute",
                                // bottom: 0,
                                width: '100%',
                                // minWidth: "1600px !important",
                            }}
                        >
                            <Footer
                                id="Footer1_0"
                                key="Footer1_0"
                                dataSource={Footer10DataSource}
                                isMobile={this.state.isMobile}
                            />
                        </div>
                    </div>
                </div>
            </Router>
        )
    }
}

export default App
