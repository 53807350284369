import React from "react";
import { Row, Col } from "antd";
import { TweenOneGroup } from "rc-tween-one";
import TweenOne from "rc-tween-one";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import { getChildrenToRender } from "./utils";
import config from "../libs/config";

class Content5 extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isHoverDetail_outer:""
        };
    }

    jumpToDetail(id) {
        window.open(`/product/detail?productid=${id}`);
    }
    handleMouseEnter_outer(data, name){
        console.log('data',data,name)
        if(data === 1){
            this.setState({isHoverDetail_outer: name})
        }
        if(data === 2){
            this.setState({isHoverDetail_outer: ""})
        }
    }
    getChildrenToRender = (data) =>
        data.map((item) => {
            const { productChildData, ...props } = this.props;
            // console.log("item___", item);
            return (
                <Col key={item.name} {...item}>
                    <a
                        {...item.children.wrapper}
                        // style={{ height: "7800px" }}
                        onClick={() => {
                            this.jumpToDetail(item.name);
                        }}
                        style={{padding:"16px"}}
                    >
                        <span {...item.children.img}>
                            <img
                                src={item.children.img.children}
                                height="100%"
                                alt="img"
                            />
                        </span>
                        {item.children.content ? (
                            <p
                                style={{
                                    // textAlign: "center",
                                    fontSize: "22px",
                                    fontWeight: 600,
                                    marginBottom: "5px",
                                    color: "#00AA71",
                                    textAlign:"start"
                                }}
                                {...item.children.content}
                            >
                                {item.children.content.children}
                            </p>
                        ) : null}
                        {item.children.desc ? (
                            <p
                                className="desc_text"
                                style={{
                                    textAlign: "start",
                                    fontSize: "14px",
                                    margimnTop: "5px",
                                    paddingBottom: "10px",
                                    // textIndent: "20px",
                                    color: "#999",
                                }}
                                {...item.children.desc}
                            >
                                {item.children.desc.children}
                                {/* {props.isMobile
                                    ? item.children.desc.children
                                    : item.children.desc.children &&
                                      item.children.desc.children.length > 40
                                    ? item.children.desc.children.slice(0, 40) +
                                      "..."
                                    : item.children.desc.children} */}
                            </p>
                        ) : null}
                        <div 
                           onMouseEnter={() => {
                                this.handleMouseEnter_outer(1, item.name);
                            }}
                            onMouseLeave={() => {
                                this.handleMouseEnter_outer(2);
                            }}
                            style={{
                                color: this.state.isHoverDetail_outer !== item.name ?"#00AA71":"#fff",
                                background: this.state.isHoverDetail_outer !== item.name ?"#fff" :"#00AA71",
                                marginTop:"1.2rem",
                                width: "6rem",
                                height: "1.7rem",
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                                borderRadius:"20px",
                                marginBottom:"0.6rem",
                                border: '2px solid #00AA71',
                                cursor: "pointer",
                                fontWeight:"600"
                        }}>了解详情</div>
                    </a>
                </Col>
            );
        });

    render() {
        const { productChildData = [], ...props } = this.props;
        const { dataSource } = props;
        delete props.dataSource;
        delete props.isMobile;

        // {
        //     name: "block2",
        //     className: "block",
        //     md: 6,
        //     xs: 24,
        //     children: {
        //         wrapper: { className: "content5-block-content" },
        //         img: {
        //             children:
        //                 "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
        //         },
        //         content: { children: "R8428 G11" },
        //         desc: {
        //             children:
        //                 "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
        //         },
        //     },
        // }
        let ret = [];
        for (let item of productChildData) {
            // console.log("item", item);
            ret.push({
                name: item.id,
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children: config.pic_url + item.product_image_url,
                    },
                    content: { children: item.product_name },
                    desc: {
                        children: item.product_desc,
                    },
                },
            });
        }
        const childrenToRender = this.getChildrenToRender(ret);
        return (
            <div
                {...props}
                {...dataSource.wrapper}
                style={{
                    paddingTop: "0px",
                    height: "auto",
                    overflow: "hidden",
                    // height: Math.ceil(productChildData.length / 4) * 320 + "px",
                    // minHeight: "380px",
                    // boredr: "1px solid red",
                }}
            >
                <div
                    {...dataSource.page}
                    style={{ paddingTop: "70px", paddingBottom: "70px" }}
                >
                    <div
                        id={props.id}
                        key="title"
                        {...dataSource.titleWrapper}
                        style={{
                            color: "rgba(0, 0, 0, 0.8)",
                            fontWeight: 500,
                            textAlign:'start',
                            fontSize:"1.6rem",
                            position:"relative",
                        }}
                    >
                        <div>
                            {props.content5title || ""}
                        </div>
                        <div style={{background:'#EEEEEE',display:'flex',width:'100%', height:'0.3rem',marginTop:'0.7rem'}}>
                            <div style={{flex:1, background:"#2FB467"}}></div>
                            <div style={{flex:4, }}></div>
                        </div>
                        <div style={{display:"flex", alignSelf:"flex-end", position:"absolute", right:"0rem",top:'40%' }}>
                            <img src={require('../assets/arrow_down.png')} style={{width:"1.2rem"}} alt="" />
                        </div>
                    </div>
                    <OverPack
                        className={`content-template ${props.className}`}
                        {...dataSource.OverPack}
                    >
                        <TweenOne
                            forcedReplay={false}
                            component={Row}
                            key="ul"
                            enter={{
                                y: "+=30",
                                opacity: 0,
                                type: "from",
                                ease: "easeInOutQuad",
                            }}
                            leave={{
                                y: "+=30",
                                opacity: 0,
                                ease: "easeInOutQuad",
                            }}
                            {...dataSource.block}
                        >
                            {childrenToRender}
                        </TweenOne>
                    </OverPack>
                </div>
            </div>
        );
    }
}

export default Content5;
