import React from "react";
import {
    RightOutlined,
    LeftOutlined,
    PhoneFilled,
    PhoneTwoTone,
    PhoneOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Row, Col, Icon, Select, message, notification,} from 'antd';
import industryOptions from "../../src/libs/industryOptions"
import ChinaArea from "../../src/libs/china-area"
import owRequest from "../../src/libs/request";

import "./widget.less";

const { TextArea } = Input;
const { Search } = Input;


// class Content5 extends React.PureComponent {
 class CSCConsultingBtn extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
            loading: false,
            cityValue: "",
            countDownValue: "验证",
            countText: '验证',
            cityOptions: []
        };
    }

    onSubmit(){
        console.log('onSubmit')
    }

    onFinishFailed (){
        console.log('onFinishFailed')
    }

    // To generate mock Form.Item
    getFields() {
        const count = this.state.expand ? 10 : 6;
        const { getFieldDecorator } = this.props.form;
        const children = [];
        for (let i = 0; i < 10; i++) {
        children.push(
            <Col span={8} key={i} style={{ display: i < count ? 'block' : 'none' }}>
            <Form.Item label={`Field ${i}`}>
                {getFieldDecorator(`field-${i}`, {
                rules: [
                    {
                        required: true,
                        message: 'Input something!',
                    },
                ],
                })(<Input placeholder="placeholder" />)}
            </Form.Item>
            </Col>,
        );
        }
        return children;
    }


    handleSearch = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log('Received values of form: ', values);
        });
    };

    handleReset = () => {
        this.props.form.resetFields();
    };

    calCountDown(){
        let i = 60
        this.t = setInterval(()=>{
            console.log(i);
            // this.countDownValue = i;
            this.setState({countDownValue: i})
            // 60 秒倒计时结束
            if (i === 0){
                clearInterval(this.t);
                this.setState({countDownValue: this.state.countText})
            }
            i--;
        }, 1000);
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if(!err) {
                console.log('________', values);
                 if(values.name && values.name.length > 20){
                    message.error("姓名输入过长，请重新输入")
                    return
                }

                if(values.company_name && values.company_name.length > 20){
                    message.error("公司名称输入过长，请重新输入")
                    return
                }

                if(values.requirements_desc && values.requirements_desc.length > 200){
                    message.error("需求描述输入过长，请重新输入")
                    return
                }

                if(values.phone && (values.phone.length < 6 || values.phone.length > 13)){
                    message.error("电话内容有误，请重新输入")
                    return
                }

                if(!values.email){
                    message.error("请输入邮箱")
                    return
                }

                let reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
                const isok= reg.test(values.email );
                if(!isok){
                    message.error("邮箱内容有误，请重新输入")
                    return
                }

                console.log('valuesssvaluesvalues',values)

                const prefix_city = values.city.slice(0,2);
                const prefix_province = values.province.slice(0,2);
                if(prefix_city !== prefix_province){
                    message.error("省份和城市选择有误，请重新选择")
                    return
                }
               
                this.addConsulting(values);
            }
        });
    };

    handleChangeProvince = (e) => {
        let cityOptions = []
        for(let item of ChinaArea){
            if(e === item.code){
                cityOptions = item.children;
                break
            }
        }
        this.setState({cityOptions: cityOptions, cityValue: ""})
    }

    handleChangeCity = (e) => {
        this.setState({ cityValue: e})
    }

    handleChange(e){
        console.log(e)
    }

    toggle = () => {
        const { expand } = this.state;
        this.setState({ expand: !expand });
    };

    onSearch(data){
        console.log('onSearchonSearchonSearch', data);
        console.log('onSearchonSearchonSearch', typeof this.state.countDownValue =='number')
        if(!data){
            message.error("请输入邮箱内容")
            return 
        }

        let reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
        const isok= reg.test(data);
        if(!isok){
            message.error("邮箱内容有误，请重新输入")
            return
        }
        
        if((typeof this.state.countDownValue =='number')){
            return
        }
    
        // 请求邮箱验证

        this.setState({loading: true})
        owRequest
            .post(`/ow-api/check-auth-code`,{ check_content:data, "from":"ow" })
            .then((res) => {
                console.log("consultingconsulting", res);
                this.setState({loading: false})
                if (res && res.code == 2000) {
                    message.success("验证码已发送，请查收")
                    this.calCountDown();
                } else {
                    message.error(res.msg)
                }
            })
            .catch((err) => {
                this.setState({loading: false})
                console.log("err", err);
            });
    }

    addConsulting(reqBody) {
        this.setState({loading: true})
        owRequest
            .post(`/office-website/customer-consulting`,{ ...reqBody })
            .then((res) => {
                console.log("consultingconsulting", res);
                  this.setState({loading: false})
                if (res && res.code == 2000) {
                    notification.open({
                        message: '通知：提交成功',
                        description:
                        '提交成功，我们的销售代表会尽快与您联系',
                        icon: <Icon type="smile" style={{ color: '#108ee9' }} />,
                    });
                    this.props.form.resetFields();
                    this.setState({showDialog:false})
                } else {
                    message.error(res.msg)
                }
            })
            .catch((err) => {
                  this.setState({loading: false})
                console.log("err", err);
            });
    }

    render() {
        const { isMobile } = this.props;
        const { getFieldDecorator } = this.props.form;
        return (
            <div style={{
                    zIndex: this.state.showDialog ? 1000000: 0, 
                    // display: this.state.showDialog ? 'none' : 'block'
                }}>
                {!isMobile ? (
                    <div
                        style={{
                            position: "fixed",
                            // bottom:  this.state.showDialog  ? "4rem" : '-100rem',
                            bottom:  "4rem",
                            right: this.state.showDialog ? "80px" : "-100rem",
                            opacity: this.state.showDialog ? "1" : "0",
                            background: "#fff",
                            border:"3px solid #46a670",
                            width: "62rem",
                            height: "40rem",
                            lineHeight: "40px",
                            borderRadius: "20px",
                            fontSize: "20px",
                            fontWeight: "600",
                            textAlign: "start",
                            display: "flex",
                            alignItem: "flex-start",
                            // paddingLeft: "20px",
                            // justifyContent: "center",
                            transition: "all 0.3s",
                            zIndex: 999,
                        }}
                    >
                        <div
                            style={{
                                position: "absolute",
                                top: "20px",
                                right: "30px",
                                cursor:"pointer"
                            }}
                            onClick={()=>{
                            this.setState({ showDialog: false });
                        }}>
                            {/* 取消 */}
                            <Icon type="close-circle" style={{fontSize:30}} />
                        </div>
                        <div >
                            <div>
                                <div style={{fontSize:26, fontWeight:600,textAlign:"center", marginTop:20, color:"#46a670",marginBottom:10}}>
                                    我要咨询
                                </div>
                                <div style={{paddingLeft:"40px", fontSize:18,marginBottom:15 }}> 
                                    您可以在线填写您的需求与问题，我们的销售代表会尽快与您联系
                                </div>
                            </div>
                            <Form 
                                className="ant-advanced-search-form" 
                                onSubmit={this.handleSubmit} 
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 18 }}
                                style={{padding:10}}
                            >
                                <Row gutter={24}>
                                     <Col span={12} style={{ display: 'block'  }} className="sisussjje">
                                        <Form.Item label="姓名" name="name">
                                        {getFieldDecorator('name', {
                                            rules: [{ required: true, message: '请输入姓名' }],
                                        })(<Input style={{width:"200px !important"}} size="large" maxLength={20} />)}
                                        </Form.Item>
                                    </Col>
                                   
                                     <Col span={12} style={{ display: 'block'  }} className="sisussjje">
                                        <Form.Item label="公司名称" name="company_name">
                                        {getFieldDecorator('company_name', {
                                            rules: [{ required: true, message: '请输入公司名称' }],
                                        })(<Input size="large" maxLength={20}  />)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                 <Row gutter={24}>
                                    <Col span={12} style={{ display: 'block'  }} className="sisussjje_right_btn">
                                        <Form.Item label="邮箱" name="email">
                                        {getFieldDecorator('email', {
                                            rules: [{ required: true, message: '请输入邮箱' }],
                                        })(
                                            // <Input size="large"   />
                                            <Search 
                                                style={{width:100}}  
                                                size="large" loading={this.state.loading}  
                                                onSearch={value => this.onSearch(value)} 
                                                disabled={this.state.countDownValue !== this.state.countText}
                                                enterButton={this.state.countDownValue} />
                                            //  <Search style={{width:100}}  size="large"  onSearch={value => console.log(value)} enterButton="倒计时90秒" />
                                        )}
                                        </Form.Item>
                                    </Col>
                                     <Col span={12} style={{ display: 'block'}} className="sisussjje">
                                        <Form.Item label="邮箱验证码" name="check_code">
                                        {getFieldDecorator('check_code', {
                                            rules: [{ required: true, message: '请输入邮箱验证码' }],
                                        })(
                                            <Input size="large" maxLength={20} autocomplete="off"  />
                                        )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                     <Col span={12} style={{ display: 'block'  }} className="sisussjje">
                                        <Form.Item label="行业" name="industry">
                                        {getFieldDecorator('industry', {
                                            rules: [{ required: true, message: '请输入行业' }],
                                        })(
                                        
                                        // <Input style={{width:"200px !important"}} size="large"  />
                                         <Select
                                            size={"large"}
                                            onChange={this.handleChange}
                                            style={{ width: 300, height: 45 }}
                                            options={industryOptions}
                                            >

                                                {industryOptions.map(item => (
                                                    <Select.Option key={item.value} value={item.value}>
                                                        {item.label}
                                                    </Select.Option>
                                                    ))}
                                            </Select>
                                        )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} style={{ display: 'block'  }} className="sisussjje">
                                        <Form.Item label="电话" name="phone">
                                        {getFieldDecorator('phone', {
                                            rules: [{ required: true, message: '请输入电话' }],
                                        })(<Input size="large"  maxLength={13} />)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                     <Col span={12} style={{ display: 'block'  }} className="sisussjje">
                                        <Form.Item label="省份" name="province">
                                        {getFieldDecorator('province', {
                                            rules: [{ required: true, message: '请输入省份' }],
                                        })(
                                            <Select
                                                size={"large"}
                                                onChange={this.handleChangeProvince}
                                                style={{ width: 300, height: 45 }}
                                                options={ChinaArea}
                                            >

                                                {ChinaArea.map(item => (
                                                    <Select.Option key={item.code} value={item.code}>
                                                        {item.name}
                                                    </Select.Option>
                                                    ))}
                                            </Select>
                                            
                                        )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} style={{ display: 'block'  }} className="sisussjje">
                                        <Form.Item label="城市" name="city">
                                        {getFieldDecorator('city', {
                                            rules: [{ required: true, message: '请输入城市' }],
                                        })(
                                            <Select
                                                size={"large"}
                                                style={{ width: 300, height: 45 }}
                                                value={this.state.cityValue}
                                                onChange={this.handleChangeCity}
                                            >
                                                {this.state.cityOptions.map(item => (
                                                    <Select.Option 
                                                        key={item.code}
                                                        value={item.code}
                                                    >
                                                        {item.name}
                                                        
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                            // cityOptions
                                        )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                              
                                <Row gutter={24}>
                                    {/* <Col span={12} style={{ display: 'block'  }} className="sisussjje">
                                        <Form.Item label="公司名称" name="company_name">
                                        {getFieldDecorator('company_name', {
                                            rules: [{ required: true, message: '请输入公司名称' }],
                                        })(<Input size="large" maxLength={20}  />)}
                                        </Form.Item>
                                    </Col> */}
                                     <Col span={12} style={{ display: 'block'  }} className="sisussjje">
                                        <Form.Item label="需求描述" name="requirements_desc"  
                                        >
                                        {getFieldDecorator('requirements_desc', {
                                            rules: [{ required: true, message: '请输入需求描述' }],
                                        })(<TextArea  size="large"  autoSize={{ minRows: 5, maxRows: 5 }} maxLength={200} />)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div style={{textAlign:"center"}}>
                                    <Button 
                                        loading={this.state.loading}
                                        type="primary" 
                                        htmlType="submit" 
                                        style={{width:170,height:35,  marginTop:0}}
                                    >
                                        提交
                                    </Button>
                                </div>
                            </Form>
                            <div style={{marginLeft:"0px", }}> 
                                <Form
                                    name="basic"
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 20 }}
                                    style={{ width:"1000px" }}
                                    // style={{ width:"100%" }}
                                    // initialValues={{ remember: true }}
                                    onFinish={this.onFinish}
                                    onFinishFailed={this.onFinishFailed}
                                    autoComplete="off"
                                >
                                </Form>
                            </div>
                        </div>
                    </div>
                ) : null}
                <a
                    onClick={()=>{
                          this.setState({ showDialog: true });
                    }}
                    href={isMobile ? "tel:4006-330-360" : "javascript:void(0);"}
                    style={{
                        position: "fixed",
                        // bottom: "100px",
                        bottom: isMobile ? "250px" : "120px",
                        right: isMobile ? "10px" : "20px",
                        zIndex: 1000,
                        width: "40px",
                        height: "40px",
                        background: "red",
                        borderRadius: "50%",
                        background: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <img
                        src={require("../assets/chat_icon.png")}
                        style={{ width: "2.1rem" }}
                    />
                </a>
            </div>
        );
    }
}



CSCConsultingBtn = Form.create({})(CSCConsultingBtn);

export default CSCConsultingBtn