export const Content00DataSource = {
    wrapper: {className: 'home-page-wrapper content0-wrapper'},
    page: {className: 'home-page content0'},
    OverPack: {playScale: 0.3, className: ''},
    titleWrapper: {
        className: 'title-wrapper',
        children: [{name: 'title', children: '解决方案'}],
    },
    childWrapper: {
        className: 'content0-block-wrapper',
        children: [
            {
                name: 'block0',
                className: 'content0-block',
                md: 8,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [
                        {
                            name: 'image',
                            className: 'content0-block-icon',
                            children:
                                'https://t12.baidu.com/it/u=565705571,1599975429&fm=173&app=49&f=PNG?w=500&h=333&s=A6A393AA5C3A0D8E9F8FD1D303009097',
                        },
                        {
                            name: 'title',
                            className: 'content0-block-title',
                            children: '融智筑元 互联网上的“数字宇宙”',
                        },
                        {
                            name: 'content',
                            children: '百家号 | 2022-06-24 11:06',
                        },
                    ],
                },
            },
            {
                name: 'block1',
                className: 'content0-block',
                md: 8,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [
                        {
                            name: 'image',
                            className: 'content0-block-icon',
                            children:
                                'https://nimg.ws.126.net/?url=https%3A%2F%2Fcrawl.ws.126.net%2Fnbot2%2Fimg%2F1acf65e0c91033a2ddf281304d6c0223.png&thumbnail=660x2147483647&quality=80&type=jpg',
                        },
                        {
                            name: 'title',
                            className: 'content0-block-title',
                            children:
                                '共赢数据中心未来，摩尔线程与超云达成战略合作',
                        },
                        {
                            name: 'content',
                            children: '百家号 | 2022-06-24 11:06',
                        },
                    ],
                },
            },
            {
                name: 'block2',
                className: 'content0-block',
                md: 8,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [
                        {
                            name: 'image',
                            className: 'content0-block-icon',
                            children:
                                'https://p7.itc.cn/q_70/images01/20210720/5125f6056b0d4619bd991f8696560ed3.jpeg',
                        },
                        {
                            name: 'title',
                            className: 'content0-block-title',
                            children:
                                '蚂蚁集群亮相Web3.0中国峰会 | CEO杜鹏解读赛道商机 ',
                        },
                        {
                            name: 'content',
                            children: '搜狐新闻 | 2021-07-20 17:56',
                        },
                    ],
                },
            },
            {
                name: 'block2',
                className: 'content0-block',
                md: 8,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [
                        {
                            name: 'image',
                            className: 'content0-block-icon',
                            children:
                                'https://p7.itc.cn/q_70/images01/20210720/5125f6056b0d4619bd991f8696560ed3.jpeg',
                        },
                        {
                            name: 'title',
                            className: 'content0-block-title',
                            children:
                                '蚂蚁集群亮相Web3.0中国峰会 | CEO杜鹏解读赛道商机 ',
                        },
                        {
                            name: 'content',
                            children: '搜狐新闻 | 2021-07-20 17:56',
                        },
                    ],
                },
            },
        ],
    },
}
