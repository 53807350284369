import React from "react";
import { Carousel, BackTop } from "antd";
import "./widget.less";

export default function CSCBackTop(props) {
    return (
        <BackTop
            key="index_page"
            style={{
                right: props.isMobile ? "10px" : "20px",
                bottom: props.isMobile ? "150px" : "20px",
            }}
        >
            <div
                style={{
                    width: "2.5rem",
                    height: "2.5rem",
                    borderRadius: "50%",
                    // background: "red",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <img
                    src={require("../assets/arrow_up.png")}
                    style={{ width: "2.5rem" }}
                />
            </div>
        </BackTop>
    );
}
