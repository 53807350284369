import React from "react";
import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import { Row, Col } from "antd";
// import TweenOne from "rc-tween-one";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import CSCDecorateLine from "../widget/CSCDecorateLine";
import { getChildrenToRender } from "./utils";
import config from "../libs/config";

class Content extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: null,
        };
    }

    // getProductItem(label, obj, code) {
    //     if (label === "left") {
    //         return (
    //             <div className={"product_item_left"}>
    //                 <div
    //                     className="product_title"
    //                     style={{
    //                         color: "#2FB467",
    //                         fontSize: "26px",
    //                         marginTop: "15px",
    //                     }}
    //                 >
    //                     {obj.product_name || ""}
    //                 </div>
    //                 <div
    //                     className={"product_desc_left"}
    //                     style={{
    //                         marginTop: "30px",
    //                         textIndent: "30px",
    //                         fontSize: "18px",
    //                         color: "#333",
    //                     }}
    //                 >
    //                     {obj.product_desc && obj.product_desc.length > 200
    //                         ? obj.product_desc.slice(0, 197) + "..."
    //                         : obj.product_desc}
    //                 </div>
    //                 <div className="prduct_foot">
    //                     <div
    //                         className="learn_more"
    //                         style={{ marginTop: "80px" }}
    //                     >
    //                         <span
    //                             onClick={() => {
    //                                 window.open(
    //                                     `/product/detail?productid=${obj.id}`
    //                                 );
    //                             }}
    //                             style={
    //                                 this.state.activeIndex === code
    //                                     ? {
    //                                           color: "#fff",
    //                                           background: "#2FB467",
    //                                           transition: "all 0.4s",
    //                                           fontWeight: "400",
    //                                       }
    //                                     : {
    //                                           color: "#2FB467",
    //                                           background: "#fff",
    //                                           transition: "all 0.4s",
    //                                           fontWeight: "400",
    //                                       }
    //                             }
    //                         >
    //                             了解更多
    //                         </span>
    //                     </div>
    //                     <div className={"product_img_left"}>
    //                         {obj.product_image_url ? (
    //                             <img
    //                                 style={{ height: "200px" }}
    //                                 src={config.pic_url + obj.product_image_url}
    //                                 alt={config.pic_url + obj.product_image_url}
    //                             />
    //                         ) : null}
    //                     </div>
    //                 </div>
    //             </div>
    //         );
    //     }

    //     return (
    //         <div
    //             className={
    //                 label === "left" ? "product_item_left" : "product_item"
    //             }
    //         >
    //             <div
    //                 className="product_title"
    //                 style={{
    //                     color: "#2FB467",
    //                     fontSize: "23px",
    //                     marginTop: "15px",
    //                 }}
    //             >
    //                 {obj.product_name || ""}
    //             </div>
    //             {/* <div className='product_desc'> */}
    //             <div
    //                 className={"product_desc"}
    //                 style={{
    //                     marginTop: "30px",
    //                     textIndent: "30px",
    //                     fontSize: "17px",
    //                     color: "#333",
    //                 }}
    //             >
    //                 {/* {obj.product_desc || ""} */}
    //                 {obj.product_desc && obj.product_desc.length > 300
    //                     ? obj.product_desc.slice(0, 297) + "..."
    //                     : obj.product_desc}
    //             </div>
    //             <div className="prduct_foot">
    //                 <div className="learn_more">
    //                     <span
    //                         onClick={() => {
    //                             window.open(
    //                                 `/product/detail?productid=${obj.id}`
    //                             );
    //                         }}
    //                         style={
    //                             this.state.activeIndex === code
    //                                 ? {
    //                                       color: "#fff",
    //                                       background: "#2FB467",
    //                                       transition: "all 0.4s",
    //                                       fontWeight: "600",
    //                                   }
    //                                 : {
    //                                       color: "#2FB467",
    //                                       background: "#fff",
    //                                       transition: "all 0.4s",
    //                                       fontWeight: "600",
    //                                   }
    //                         }
    //                     >
    //                         了解更多
    //                     </span>
    //                 </div>
    //                 <div className={"product_img"}>
    //                     {obj.product_image_url ? (
    //                         <img
    //                             style={{
    //                                 minWidth: "180px",
    //                                 marginRight: "15px",
    //                             }}
    //                             src={config.pic_url + obj.product_image_url}
    //                             alt={config.pic_url + obj.product_image_url}
    //                         />
    //                     ) : null}
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // }

    getProductItem(label, obj, code) {
        return (
            <div className={"product_item_left"} key={code}>
                <div className={"product_img_left"}>
                    {obj.product_image_url ? (
                        <img
                            style={{ height: "300px", maxWidth: "400px" }}
                            src={config.pic_url + obj.product_image_url}
                            alt={config.pic_url + obj.product_image_url}
                        />
                    ) : null}
                </div>
                <div
                    className="product_title"
                    style={{
                        fontSize: "36px",
                        textAlign: "center",
                        color: "#333",
                        fontWeight: 700,
                    }}
                >
                    {obj.product_name || ""}
                </div>
                <div
                    className={"product_desc_left"}
                    style={{
                        marginTop: "30px",
                        fontSize: "18px",
                        color: "#808080",
                    }}
                >
                    {obj.product_desc && obj.product_desc.length > 200
                        ? obj.product_desc.slice(0, 197) + "..."
                        : obj.product_desc}
                </div>
                <div className="prduct_foot">
                    <div className="learn_more">
                        <div
                            // onMouseEnter={() => {
                            //     this.setState({
                            //         activeIndex: 1,
                            //     });
                            // }}
                            // onMouseLeave={() => {
                            //     this.setState({
                            //         activeIndex: null,
                            //     });
                            // }}
                            onClick={() => {
                                window.open(
                                    `/product/detail?productid=${obj.id}`
                                );
                            }}
                            style={
                                this.state.activeIndex === code
                                    ? {
                                          color: "#fff",
                                          background: "#2FB467",
                                          transition: "all 0.4s",
                                          fontWeight: "600",
                                      }
                                    : {
                                          color: "#2FB467",
                                          background: "#fff",
                                          transition: "all 0.4s",
                                          fontWeight: "600",
                                      }
                            }
                        >
                            了解详情
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getMobileProductItem(label, obj) {
        return (
            <div
                className={
                    label === "left" ? "product_item_left" : "product_item"
                }
                style={{ margin: 0 }}
            >
                <div
                    className="product_title"
                    style={{
                        color: "#2FB467",
                        fontSize: "23px",
                        marginTop: "15px",
                    }}
                >
                    {obj.product_name || ""}
                </div>
                {/* <div className='product_desc'> */}
                <div
                    className={"product_desc"}
                    style={{
                        marginTop: "30px",
                        textIndent: "30px",
                        fontSize: "17px",
                    }}
                >
                    {obj.product_desc || ""}
                </div>
                <div
                    className="prduct_foot"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        margin: 0,
                    }}
                >
                    <div
                        className={"product_img"}
                        style={{ margin: 0, textAlign: "center", padding: 0 }}
                    >
                        {obj.product_image_url ? (
                            <img
                                style={{
                                    minWidth: "180px",
                                    margin: 0,
                                    padding: 0,
                                    // marginRight: "15px",
                                }}
                                src={config.pic_url + obj.product_image_url}
                                alt={config.pic_url + obj.product_image_url}
                            />
                        ) : null}
                    </div>
                    <div
                        className="learn_more"
                        style={{ margin: 0, marginBottom: "30px", padding: 0 }}
                    >
                        <div
                            onClick={() => {
                                window.open(
                                    `/product/detail?productid=${obj.id}`
                                );
                            }}
                            style={{
                                width: "100%",
                                fontSize: "14px",
                                color: "#2FB467",
                                fontWeight: "600",
                                margin: 0,
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                paddingTop: "4px",
                                paddingBottom: "4px",
                            }}
                        >
                            了解详情
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // getMobileProductItem(label, obj) {
    //     return (
    //         <div className={"product_item_mobile"}>
    //             <div className="product_title">{obj.product_name || ""}</div>
    //             {/* <div className='product_desc'> */}
    //             <div className={"product_desc"}>{obj.product_desc || ""}</div>
    //             <div className="prduct_foot">
    //                 <div className="learn_more">
    //                     <span
    //                         onClick={() => {
    //                             window.open(
    //                                 `/product/detail?productid=${obj.id}`
    //                             );
    //                         }}
    //                     >
    //                         了解更多
    //                     </span>
    //                 </div>
    //                 <div className={"product_img"}>
    //                     <img
    //                         src={config.pic_url + obj.product_image_url}
    //                         alt=""
    //                         // onError={() => onError({ url: errImg })}
    //                     />
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // }

    render() {
        const {
            dataSource,
            isMobile,
            newProductRet = [],
            ...props
        } = this.props;
        const {
            wrapper,
            titleWrapper,
            page,
            OverPack: overPackData,
            childWrapper,
        } = dataSource;
        return (
            <div {...props} {...wrapper}>
                <div {...page}>
                    <div
                        {...titleWrapper}
                        style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {/* <div
                            style={{
                                width: "40%",
                                height: "1px",
                                background: "#cbcccb",
                                // marginRight: "3rem",
                                marginBottom: "16px",
                            }}
                        ></div>
                        <div
                            style={{
                                width: "0.9rem",
                                height: "0.9rem",
                                borderRadius: "50%",
                                background: "#cbcccb",
                                marginRight: "3rem",
                                marginBottom: "16px",
                            }}
                        ></div>

                        {titleWrapper.children.map(getChildrenToRender)}
                        <div
                            style={{
                                width: "0.9rem",
                                height: "0.9rem",
                                borderRadius: "50%",
                                background: "#cbcccb",
                                marginLeft: "3rem",
                                marginBottom: "16px",
                            }}
                        ></div>
                        <div
                            style={{
                                width: "40%",
                                height: "1px",
                                background: "#cbcccb",
                                // marginLeft: "3rem",
                                marginBottom: "16px",
                            }}
                        ></div> */}
                        <CSCDecorateLine
                            children={titleWrapper.children.map(
                                getChildrenToRender
                            )}
                            isMobile={isMobile}
                        />
                    </div>
                    <OverPack {...overPackData}>
                        <QueueAnim
                            forcedReplay={false}
                            type="bottom"
                            key="block"
                            leaveReverse
                            componentProps={childWrapper}
                         
                        >
                            {/* getProductItem */}
                            {/* {newProductRet && newProductRet.length ? (
                                !isMobile ? (
                                    <div className="product_box" key={"p1"}>
                                        <div
                                            className="product_left_box"
                                            style={{
                                                // cursor: "pointer",
                                                border:
                                                    this.state.activeIndex === 1
                                                        ? "1px solid #2FB467"
                                                        : "1px solid #ddd",
                                                transition: "all 0.4s",
                                            }}
                                            onMouseEnter={() => {
                                                this.setState({
                                                    activeIndex: 1,
                                                });
                                            }}
                                            onMouseLeave={() => {
                                                this.setState({
                                                    activeIndex: null,
                                                });
                                            }}
                                        >
                                            {this.getProductItem(
                                                "left",
                                                newProductRet[0] || {},
                                                1
                                            )}
                                        </div>
                                        <div
                                            className="product_right_box"
                                            key={"p"}
                                        >
                                            <div
                                                className="product_right_up_box"
                                                style={{
                                                    // cursor: "pointer",
                                                    border:
                                                        this.state
                                                            .activeIndex === 2
                                                            ? "1px solid #2FB467"
                                                            : "1px solid #ddd",
                                                    transition: "all 0.4s",
                                                }}
                                                onMouseEnter={() => {
                                                    this.setState({
                                                        activeIndex: 2,
                                                    });
                                                }}
                                                onMouseLeave={() => {
                                                    this.setState({
                                                        activeIndex: null,
                                                    });
                                                }}
                                            >
                                                {this.getProductItem(
                                                    "right",
                                                    newProductRet[1] || {},
                                                    2
                                                )}
                                            </div>
                                            <div
                                                className="product_right_down_box"
                                                style={{
                                                    // cursor: "pointer",
                                                    border:
                                                        this.state
                                                            .activeIndex === 3
                                                            ? "1px solid #2FB467"
                                                            : "1px solid #ddd",
                                                    transition: "all 0.4s",
                                                }}
                                                onMouseEnter={() => {
                                                    this.setState({
                                                        activeIndex: 3,
                                                    });
                                                }}
                                                onMouseLeave={() => {
                                                    this.setState({
                                                        activeIndex: null,
                                                    });
                                                }}
                                            >
                                                {this.getProductItem(
                                                    "right",
                                                    newProductRet[2] || {},
                                                    3
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="product_box" key={"p1"}>
                                        <div
                                            className="product_right_box"
                                            key={"p"}
                                            style={{ margin: 0 }}
                                        >
                                            <div
                                                className="product_right_up_box"
                                                style={{
                                                    display: "flex",
                                                    border: "1px solid #ddd",
                                                }}
                                            >
                                                {this.getMobileProductItem(
                                                    "right",
                                                    newProductRet[0] || {}
                                                )}
                                            </div>
                                            <div
                                                className="product_right_down_box"
                                                style={{
                                                    display: "flex",
                                                    border: "1px solid #ddd",
                                                }}
                                            >
                                                {this.getMobileProductItem(
                                                    "right",
                                                    newProductRet[1] || {}
                                                )}
                                            </div>
                                            <div
                                                className="product_right_down_box"
                                                style={{
                                                    display: "flex",
                                                    border: "1px solid #ddd",
                                                }}
                                            >
                                                {this.getMobileProductItem(
                                                    "right",
                                                    newProductRet[2] || {}
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                            ) : null} */}
                            {newProductRet && newProductRet.length ? (
                                !isMobile ? (
                                    <div className="product_box" key={"p1"}>
                                        <div
                                            className="product_left_box"
                                            style={{
                                                // cursor: "pointer",
                                                // border:
                                                //     this.state.activeIndex === 1
                                                //         ? "1px solid #2FB467"
                                                //         : "1px solid #ddd",
                                                transition: "all 0.4s",
                                            }}
                                        >
                                            {this.getProductItem(
                                                "left",
                                                newProductRet[0] || {},
                                                0
                                            )}
                                        </div>
                                        <div
                                            className="product_left_box"
                                            style={{
                                                // cursor: "pointer",
                                                // border:
                                                //     this.state.activeIndex === 1
                                                //         ? "1px solid #2FB467"
                                                //         : "1px solid #ddd",
                                                transition: "all 0.4s",
                                            }}
                                        >
                                            {this.getProductItem(
                                                "left",
                                                newProductRet[1] || {},
                                                1
                                            )}
                                        </div>
                                        <div
                                            className="product_left_box"
                                            style={{
                                                // cursor: "pointer",
                                                // border:
                                                //     this.state.activeIndex === 1
                                                //         ? "1px solid #2FB467"
                                                //         : "1px solid #ddd",
                                                transition: "all 0.4s",
                                            }}
                                        >
                                            {this.getProductItem(
                                                "left",
                                                newProductRet[2] || {},
                                                2
                                            )}
                                        </div>
                                        {/* <div
                                            className="product_right_box"
                                            key={"p"}
                                        >
                                            <div
                                                className="product_right_up_box"
                                                style={{
                                                    // cursor: "pointer",
                                                    border:
                                                        this.state
                                                            .activeIndex === 2
                                                            ? "1px solid #2FB467"
                                                            : "1px solid #ddd",
                                                    transition: "all 0.4s",
                                                }}
                                                onMouseEnter={() => {
                                                    this.setState({
                                                        activeIndex: 2,
                                                    });
                                                }}
                                                onMouseLeave={() => {
                                                    this.setState({
                                                        activeIndex: null,
                                                    });
                                                }}
                                            >
                                                {this.getProductItem(
                                                    "right",
                                                    newProductRet[1] || {},
                                                    2
                                                )}
                                            </div>
                                            <div
                                                className="product_right_down_box"
                                                style={{
                                                    // cursor: "pointer",
                                                    border:
                                                        this.state
                                                            .activeIndex === 3
                                                            ? "1px solid #2FB467"
                                                            : "1px solid #ddd",
                                                    transition: "all 0.4s",
                                                }}
                                                onMouseEnter={() => {
                                                    this.setState({
                                                        activeIndex: 3,
                                                    });
                                                }}
                                                onMouseLeave={() => {
                                                    this.setState({
                                                        activeIndex: null,
                                                    });
                                                }}
                                            >
                                                {this.getProductItem(
                                                    "right",
                                                    newProductRet[2] || {},
                                                    3
                                                )}
                                            </div>
                                        </div> */}
                                    </div>
                                ) : (
                                    <div className="product_box" key={"p1"}>
                                        <div
                                            className="product_right_box"
                                            key={"p"}
                                            style={{ margin: 0 }}
                                        >
                                            <div
                                                className="product_right_up_box"
                                                style={{
                                                    display: "flex",
                                                    border: "1px solid #ddd",
                                                }}
                                            >
                                                {this.getMobileProductItem(
                                                    "right",
                                                    newProductRet[0] || {}
                                                )}
                                            </div>
                                            <div
                                                className="product_right_down_box"
                                                style={{
                                                    display: "flex",
                                                    border: "1px solid #ddd",
                                                }}
                                            >
                                                {this.getMobileProductItem(
                                                    "right",
                                                    newProductRet[1] || {}
                                                )}
                                            </div>
                                            <div
                                                className="product_right_down_box"
                                                style={{
                                                    display: "flex",
                                                    border: "1px solid #ddd",
                                                }}
                                            >
                                                {this.getMobileProductItem(
                                                    "right",
                                                    newProductRet[2] || {}
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                            ) : null}
                        </QueueAnim>
                    </OverPack>
                </div>
            </div>
        );
    }
}

export default Content;
