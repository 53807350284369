import React from "react";
import { Button } from "antd";
import "./widget.less";

export default function CSCButton(props) {
    const { title = "", onClick = () => {} } = props;
    return (
        <div
            className="csc_button_wrapper"
            onClick={() => {
                onClick();
            }}
        >
            <Button type="primary">{title}</Button>
        </div>
    );
}

CSCButton.defaultProps = {
    title: "",
    onClick: () => {},
};
