/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import CSCTitle from "../../widget/CSCTitle";
import Content5 from "./Content5";
import owRequest from "../../libs/request";

import {
    Content00DataSource,
    Content70DataSource,
    Banner10DataSource,
    Content50DataSource,
} from "./data.source.js";

import "./less/antMotionStyle.less";
import "./less/product.less";

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const location = window.location;

const textSty = {
    fontSize: isMobile ? "13px" : "18px",
    // textIndent: isMobile ? "20px" : "40px",
    maxWidth: "1200px",
    padding: isMobile ? "15px" : "20px",
    margin: "0 auto",
};

export default class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
            activeTab: 0,
            userMannualList: [],
        };
    }

    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
        /* 如果不是 dva 2.0 请删除 end */
        this.getUserMannualList();
    }

    getUserMannualList() {
        owRequest
            .get(`/office-website/techsupport/usermanual`)
            .then((res) => {
                
                if (res && res.code == 2000) {
                    let ret = [];
                    ret = res.data.rows;
                    
                    this.setState({
                        userMannualList: ret || [],
                        totalPage: res.data.count,
                    });
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    }

    onClickTabItem(activeTab, id) {
        this.setState({ activeTab: activeTab });
        // document.geti
        // console.log(TAG, "BottomView next:", myRef);
        let anchorElement = document.getElementById(id);
        // console.log(
        //     "anchorElement",
        //     anchorElement,
        //     anchorElement.getBoundingClientRect()
        // );
        const yHeight = anchorElement.getBoundingClientRect().y;

        if (activeTab == 0) {
            window.scrollTo(0, 0);
        }

        // console.log("yHeight", yHeight);
        window.scrollTo({
            left: 0, // x坐标
            top: yHeight - 100, // y 坐标
            behavior: "smooth", // 可选值：smooth、instant、auto
        });
        // window.scrollTo(0, yHeight, {behavior: ‘smooth’});
    }

    onClickSearchBtn() {
        window.open("/product/search");
    }

    adaptHeight(Content50DataSource) {
        // return "auto";
        const result = this.state.isMobile
            ? Content50DataSource.block.children.length * 340 + "px"
            : Math.ceil(Content50DataSource.block.children.length / 4) * 340 +
              "px";
        return result;
    }

    render() {
        const children = [
            // <Banner
            //     id="Banner1_0"
            //     key="Banner1_0"
            //     dataSource={Banner10DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <img
            //     src="https://img2.baidu.com/it/u=3639955691,11916907&fm=253&fmt=auto&app=138&f=JPEG?w=658&h=171"
            //     alt=""
            //     style={{ width: "100%" }}
            // />,
            <div style={{ position: "relative" }}>
                <img
                    src={require("../../assets/techsuupport_bg_4.png")}
                    alt=""
                    style={{ width: "100%" }}
                />

                {/* <div
                    style={{
                        position: "absolute",
                        top: this.state.isMobile ? "3rem" : "40%",
                        left: this.state.isMobile ? "1rem" : "15%",
                        color: "#fff",
                        fontSize: this.state.isMobile ? "1.4rem" : "2.4vw",
                        zIndex: 100,
                        fontWeight: 700,
                    }}
                >
                    技术支持
                </div> */}
            </div>,

            <div
                id="services_scope"
                key="services_scope"
                style={{
                    height: "auto",
                    width: "100%",
                    minHeight: "60vh",
                    background: "#fafafa",
                    // marginBottom: "60px",
                    paddingTop: "30px",
                    paddingBottom: "20px",
                    // height: this.state.isMobile
                    //     ? this.state.userMannualList.length * 200 + "px"
                    //     : "auto",
                }}
            >
                {/* <CSCTitle
                    title="用户手册文件下载"
                    key="services_scope"
                    isMobile={this.state.isMobile}
                    style={{
                        margin: 0,
                        height: "auto",
                    }}
                    textSty={{
                        fontSize: this.state.isMobile ? "20px" : "28px",
                        fontWeight: "700",
                    }}
                /> */}
                <Content5
                    // id="special_tip"
                    // key="special_tip"
                    dataSource={Content50DataSource}
                    isMobile={this.state.isMobile}
                    content5title={"用户手册文件下载"}
                    userMannualList={this.state.userMannualList}
                    cname={"special_tip"}
                />
                {/* {this.getServiceScope()}, */}
            </div>,

            // <div
            //     className="p_tab_box_51_wrapper"
            //     key={"p_tab_box_51_wrapper"}
            //     style={{
            //         paddingTop: this.state.isMobile ? "30px" : "60px",
            //         paddingBottom: this.state.isMobile ? "10px" : "60px",
            //     }}
            // >
            //     <div className="p_tab_box_51">
            //         {[
            //             { title: "服务范围", href: "services_scope" },
            //             { title: "服务种类", href: "services_type" },
            //             { title: "服务标准", href: "services_stander" },
            //             { title: "其它说明", href: "other_tips" },
            //         ].map((item, index) => {
            //             console.log("item", item);
            //             return (
            //                 <a
            //                     // onClick = {()=>{}}
            //                     // href={`#${item.href}`}
            //                     key={"cb" + index}
            //                     className={
            //                         this.state.activeTab == index
            //                             ? "p_tab_item_51_active p_tab_item_51"
            //                             : "p_tab_item_51"
            //                     }
            //                     onClick={() => {
            //                         this.onClickTabItem(index, item.href);
            //                     }}
            //                 >
            //                     {item.title}
            //                 </a>
            //             );
            //         })}
            //     </div>
            // </div>,
            // <div
            //     id="services_scope"
            //     key="services_scope"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     {this.getTitleDes()},
            // </div>,

            // <div
            //     id="services_scope"
            //     key="services_scope"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="服务范围"
            //         key="services_scope"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getServiceScope()},
            // </div>,
            // <div
            //     id="services_type"
            //     key="services_type"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="服务种类"
            //         key="services_type"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getServiceTypes()},
            // </div>,
            // <div
            //     id="services_stander"
            //     key="services_stander"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="服务标准"
            //         key="services_stander"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getServicesStander()},
            // </div>,

            // <div
            //     id="other_tips"
            //     key="other_tips"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="其它说明"
            //         key="other_tips"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getOtherTips()}
            // </div>,
        ];
        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {this.state.show && children}
                {/* 如果不是 dva 2.0 替换成 {children} end */}
            </div>
        );
    }
}

const style = React.cree;
