import React from "react";
import { Row, Col } from "antd";
import { TweenOneGroup } from "rc-tween-one";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";

class Content5 extends React.PureComponent {
    render() {
        const { ...props } = this.props;
        const { dataSource, htmlStr = "" } = props;
        delete props.dataSource;
        delete props.isMobile;
        return (
            <OverPack
                className={`content-template ${props.className}`}
                {...dataSource.OverPack}
            >
                <TweenOneGroup
                    component={Row}
                    key="ul"
                    enter={{
                        y: "+=30",
                        opacity: 0,
                        type: "from",
                        ease: "easeInOutQuad",
                    }}
                    leave={{
                        y: "+=30",
                        opacity: 0,
                        ease: "easeInOutQuad",
                    }}
                    {...dataSource.block}
                >
                    <div
                        style={{
                            maxWidth: "1200px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "0 auto",
                            marginTop: "4reme",
                            marginBottom: "4reme",

                            paddingLeft: "5px",
                            paddingRight: "5px",
                            paddingBottom: "7rem",
                        }}
                        dangerouslySetInnerHTML={{ __html: htmlStr }}
                    ></div>
                    ,
                </TweenOneGroup>
            </OverPack>
        );
    }
}

export default Content5;
