import React from "react";
import "./widget.less";

export default function CSCTitle(props) {
    const { title = "", textSty = {}, ...item } = props;
    return (
        <div className="title_wrapper" {...item}>
            <h1 className="title_text" style={textSty}>
                {title}
            </h1>
        </div>
    );
}

CSCTitle.defaultProps = {
    title: "",
};
