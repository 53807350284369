import React from "react";
// import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import { Row, Col } from "antd";
import config from "../libs/config";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";

function Content2(props) {
    const { productDetailObj = {}, ...tagProps } = props;
    const { dataSource, isMobile } = tagProps;
    delete tagProps.dataSource;
    delete tagProps.isMobile;
    // console.log("productDetailObj", productDetailObj);
    const animType = {
        queue: isMobile ? "bottom" : "left",
        one: isMobile
            ? {
                  scaleY: "+=0.3",
                  opacity: 0,
                  type: "from",
                  ease: "easeOutQuad",
              }
            : {
                  x: "+=30",
                  opacity: 0,
                  type: "from",
                  ease: "easeOutQuad",
              },
    };
    const img = (
        <TweenOne
            forcedReplay={false}
            key="img"
            animation={animType.one}
            resetStyle
            {...dataSource.imgWrapper}
            component={Col}
            componentProps={{
                md: dataSource.imgWrapper.md,
                xs: dataSource.imgWrapper.xs,
            }}
        >
            {/* <span {...dataSource.img} style={{ width: "100%" }}> */}
            <img
                src={config.pic_url + productDetailObj.product_image_url}
                alt="img"
                style={{
                    marginTop: isMobile ? "0px" : "120px",
                    // width: isMobile ? "100%" : "100%",
                    marginRight: "0px",
                    maxHeight: "300px",
                    marginLeft: isMobile ? "0px" : "80px",
                    maxWidth: "30rem",
                }}
            />
            {/* <img src={require("../assets/4.jpeg")} width="100%" alt="img" /> */}
            {/* </span> */}
        </TweenOne>
    );
    return (
        <div
            {...tagProps}
            {...dataSource.wrapper}
            style={{ height: "auto", padding: 0 }}
        >
            <OverPack {...dataSource.OverPack} component={Row}>
                {isMobile && img}
                <TweenOne
                    type={animType.queue}
                    key="text"
                    leaveReverse
                    ease={["easeOutCubic", "easeInCubic"]}
                    {...dataSource.textWrapper}
                    component={Col}
                    componentProps={{
                        md: dataSource.textWrapper.md,
                        xs: dataSource.textWrapper.xs,
                    }}
                    style={{}}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            lineHeight: "30px",
                        }}
                    >
                        <h2
                            key="h1"
                            {...dataSource.title}
                            style={{
                                color: "#1da833",
                                textIndent: "30px",
                                marginRight: isMobile ? "0px" : "80px",
                            }}
                        >
                            {/* {dataSource.title.children} */}
                            {productDetailObj.product_name || ""}
                        </h2>
                        <div
                            key="p"
                            style={{
                                fontSize: "18px",
                                textIndent: "30px",
                                height: "auto",
                                marginTop: "15px",
                            }}
                            dangerouslySetInnerHTML={{
                                __html: productDetailObj.product_desc || "",
                            }}
                        />
                    </div>
                </TweenOne>
                {!isMobile && img}
            </OverPack>
        </div>
    );
}

export default Content2;
