/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import { SearchOutlined } from "@ant-design/icons";

import {
    Row,
    Col,
    AutoComplete,
    Cascader,
    DatePicker,
    Input,
    InputNumber,
    Select,
    Button,
    Form,
    Tooltip,
    Checkbox,
    BackTop,
    Empty,
    Spin,
} from "antd";

import Content0 from "./Content0";
import Content7 from "./Content7";
import Banner from "../Banner/index";
import CSCButton from "../widget/CSCButton";
import CSCBackTop from "../widget/CSCBackTop";
import Content5 from "./Content5";

import {
    Content00DataSource,
    Content70DataSource,
    Banner10DataSource,
    Content50DataSource,
} from "./data.source.js";
import owRequest from "../libs/request";

import "./less/antMotionStyle.less";
import "./less/product_search.less";

const { Option } = Select;
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const location = window.location;

export default class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            loading: false,
            show: !location.port, // 如果不是 dva 2.0 请删除
            activeTab: 0,
            cycleOptions: [
                { label: "全部", value: "all" },
                { label: "日报", value: "day" },
                { label: "周报", value: "week" },
                { label: "月报", value: "month" },
            ],
            cycleOptions1: [
                { label: "全部", value: "all" },
                { label: "日报", value: "day" },
                { label: "周报", value: "week" },
                { label: "月报", value: "month" },
                { label: "月报", value: "month" },
                { label: "月报", value: "month" },
            ],
            defaultVals_4: ["SSD"],
            defaultVals_3: ["PHI"],
            defaultVals_2: ["PFM"],
            defaultVals_1: ["NVM"],
            productList: [],
            reqBody: {},
            product_type: null,
            appearance_structure: null,
            isFinished: false,
        };
    }

    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
        /* 如果不是 dva 2.0 请删apiGetSearchProduct除 end */
        this.apiGetSearchProduct();
        window.scrollTo(0, 0);
    }

    // getProductItem() {
    //     return (
    //         <div className="product_item">
    //             <div className="product_img">
    //                 <img
    //                     src="https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg"
    //                     alt=""
    //                 />
    //             </div>
    //             <div className="product_title">233333</div>
    //             <div className="product_desc">444</div>
    //         </div>
    //     );
    // }

    // onClickTabItem(activeTab) {
    //     this.setState({ activeTab: activeTab });
    // }

    // onClickSearchBtn() {
    //     window.open("/product/detail");
    // }

    onFormLayoutChange(e) {
        console.log("onFormLayoutChange", e);
    }

    onClickSearch() {
        this.apiGetSearchProduct();
    }
    // onChange(e) {
    //     console.log("onChange", e);
    // }
    onChange_4(target) {
        if (target[target.length - 1] === "SSD") {
            this.setState(
                {
                    defaultVals_4: ["SSD"],
                    nvme_ssd_nums: null,
                },
                () => {
                    this.apiGetSearchProduct();
                }
            );
        } else {
            const len = target.indexOf("SSD");
            if (len != -1) {
                target.splice(len, 1);
            }
            this.setState(
                {
                    defaultVals_4: target,
                    nvme_ssd_nums: target.sort().join(""),
                },
                () => {
                    this.apiGetSearchProduct();
                }
            );
        }
    }
    onChange_3(target) {
        if (target[target.length - 1] === "PHI") {
            this.setState(
                {
                    defaultVals_3: ["PHI"],
                    gpu_phi_supported_nums: null,
                },
                () => {
                    this.apiGetSearchProduct();
                }
            );
        } else {
            const len = target.indexOf("PHI");
            if (len != -1) {
                target.splice(len, 1);
            }
            this.setState(
                {
                    defaultVals_3: target,
                    gpu_phi_supported_nums: target.sort().join(""),
                },
                () => {
                    this.apiGetSearchProduct();
                }
            );
        }
    }

    onChange_2(target) {
        if (target[target.length - 1] === "PFM") {
            this.setState(
                {
                    defaultVals_2: ["PFM"],
                    appearance_structure: null,
                },
                () => {
                    this.apiGetSearchProduct();
                }
            );
        } else {
            const len = target.indexOf("PFM");
            if (len != -1) {
                target.splice(len, 1);
            }
            this.setState(
                {
                    defaultVals_2: target,
                    appearance_structure: target.sort().join(""),
                },
                () => {
                    this.apiGetSearchProduct();
                }
            );
        }
    }

    onChange_1(target) {
        if (target[target.length - 1] === "NVM") {
            this.setState(
                {
                    defaultVals_1: ["NVM"],
                    cpu_nums: null,
                },
                () => {
                    this.apiGetSearchProduct();
                }
            );
        } else {
            const len = target.indexOf("NVM");
            if (len != -1) {
                target.splice(len, 1);
            }
            this.setState(
                {
                    defaultVals_1: target,
                    cpu_nums: target.sort().join(""),
                },
                () => {
                    this.apiGetSearchProduct();
                }
            );
        }
    }

    onChangeSelect(e) {
        // console.log("onChangeSelect", e);
        this.setState({ product_type: e == "all" ? "" : e }, () => {
            this.apiGetSearchProduct();
        });
    }

    onPressEnterKey(e) {
        // console.log("onPressEnterKey", e);
        this.apiGetSearchProduct();
    }

    onChangeInputTxt = (e) => {
        this.setState({ product_name: e.target.value || "" });
    };

    apiGetSearchProduct() {
        const reqBody = {};
        if (this.state.product_type) {
            reqBody["product_type"] = this.state.product_type;
        }
        if (this.state.product_name) {
            reqBody["product_name"] = this.state.product_name;
        }
        if (this.state.cpu_nums) {
            reqBody["cpu_nums"] = this.state.cpu_nums;
        }
        if (this.state.appearance_structure) {
            reqBody["appearance_structure"] = this.state.appearance_structure;
        }
        if (this.state.gpu_phi_supported_nums) {
            reqBody["gpu_phi_supported_nums"] =
                this.state.gpu_phi_supported_nums;
        }
        if (this.state.nvme_ssd_nums) {
            reqBody["nvme_ssd_nums"] = this.state.nvme_ssd_nums;
        }
        // "cpu_nums":"1",
        // "appearance_structure":"1",
        // "gpu_phi_supported_nums":"1",
        // "nvme_ssd_nums":"1"
        this.setState({ isFinished: false });
        owRequest
            .post(`/office-website/search/product`, { ...reqBody })
            .then((res) => {
                // console.log("res", res);
                this.setState({ isFinished: true });
                if (res && res.code == 2000) {
                    let ret = [];
                    ret = res.data.rows;
                    // console.log("rettt", ret);
                    this.setState({
                        productList: ret || [],
                    });
                }
            })
            .catch((err) => {
                console.log("err", err);
                this.setState({ isFinished: true });
            });
    }

    render() {
        const checkLabelSty = {
            width: !this.state.isMobile ? "10rem" : "12rem",
            textAlign: !this.state.isMobile ? "right" : "center",
            marginRight: "20px",
            fontSize: !this.state.isMobile ? "16px" : "18px",
            marginBottom: !this.state.isMobile ? "0px" : "20px",
        };
        const checkContentSty = {
            flex: 1,
            textAlign: "left",
            marginRight: !this.state.isMobile ? "20px" : "0",
            display: "flex",
            // justifyContent: "space-between",
        };

        const checkItem = {
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "1rem 0",
            flexDirection: !this.state.isMobile ? "row" : "column",
        };
        const checkSty = {
            width: !this.state.isMobile ? "6rem" : "7em",
            marginBottom: !this.state.isMobile ? "0rem" : "1rem",
        };

        const children = [
            // <Banner
            //   id="Banner1_0"
            //   key="Banner1_0"
            //   dataSource={Banner10DataSource}
            //   isMobile={this.state.isMobile}
            // />,
            // <div className="p_tab_box_51_wrapper">
            //     <div className="p_tab_box_51">
            //         {[
            //             { title: "通用服务器", href: "general_machine" },
            //             { title: "AI服务器", href: "ai_machine" },
            //             { title: "EPYC服务器", href: "epyc_machine" },
            //             { title: "AI服务器", href: "aa_machine" },
            //         ].map((item, index) => {
            //             console.log("item", item);
            //             return (
            //                 <a
            //                     href={`#${item.href}`}
            //                     key={"cb" + index}
            //                     className={
            //                         this.state.activeTab == index
            //                             ? "p_tab_item_51_active p_tab_item_51"
            //                             : "p_tab_item_51"
            //                     }
            //                     onClick={() => {
            //                         this.onClickTabItem(index);
            //                     }}
            //                 >
            //                     {item.title}
            //                 </a>
            //             );
            //         })}
            //     </div>
            // </div>,

            // <div>
            //     <CSCButton
            //         title="搜索更多"
            //         onClick={() => {
            //             this.onClickSearchBtn();
            //         }}
            //     />
            // </div>,
            // <div>
            //     {!this.state.isMobile ? (
            //         <img
            //             src={require("../assets/head_news.png")}
            //             alt=""
            //             style={{ width: "100%" }}
            //         />
            //     ) : (
            //         <img
            //             src={require("../assets/head_news.png")}
            //             alt=""
            //             style={{ width: "100%" }}
            //         />
            //     )}
            // </div>,
            <div style={{ position: "relative" }}>
                {!this.state.isMobile ? (
                    <img
                        src={require("../assets/head_product.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                ) : (
                    <img
                        src={require("../assets/head_product.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                <div
                    style={{
                        position: "absolute",
                        top: this.state.isMobile ? "3rem" : "40%",
                        left: this.state.isMobile ? "1rem" : "15%",
                        color: "#fff",
                        fontSize: this.state.isMobile ? "1.4rem" : "2.4vw",
                        zIndex: 100,
                        fontWeight: 700,
                    }}
                >
                    服务器搜索
                </div>
            </div>,
            <div
                key={"cscsearch_1"}
                style={{
                    background: "#f9f9f9",
                    padding: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    paddingTop: "3rem",
                }}
            >
                <Input.Group
                    compact
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                    }}
                >
                    <Select
                        defaultValue="all"
                        onChange={(e) => {
                            this.onChangeSelect(e);
                        }}
                    >
                        <Option value="all">所有服务器</Option>
                        <Option value="1">智算服务器</Option>
                        <Option value="2">信创服务器</Option>
                        <Option value="3">液冷服务器</Option>
                    </Select>
                    <Input
                        style={{ width: "50%" }}
                        // defaultValue="请输入产品型号"
                        placeholder="请输入服务器名称"
                        onChange={this.onChangeInputTxt}
                        onPressEnter={(e) => {
                            this.onPressEnterKey(e);
                        }}
                    />
                    {this.state.isMobile ? (
                        <Button
                            type="primary"
                            size="large"
                            onClick={() => {
                                this.onClickSearch();
                            }}
                        >
                            搜索
                        </Button>
                    ) : (
                        <Button
                            type="primary"
                            icon={"search"}
                            size="large"
                            onClick={() => {
                                this.onClickSearch();
                            }}
                        >
                            搜索
                        </Button>
                    )}
                </Input.Group>
            </div>,
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#f8f8f8",
                }}
            >
                <div
                    key={"cscsearch_2"}
                    style={{
                        background: "#f9f9f9",
                        padding: "30px",
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        paddingTop: "3rem",
                    }}
                >
                    <div style={checkItem}>
                        <div style={checkLabelSty}>
                            <span>处理器数量：</span>
                        </div>
                        <div style={checkContentSty}>
                            <Checkbox.Group
                                style={{ width: "100%" }}
                                onChange={(target) => {
                                    this.onChange_1(target);
                                }}
                                value={this.state.defaultVals_1}
                            >
                                <Checkbox style={checkSty} value="NVM">
                                    不限
                                </Checkbox>
                                <Checkbox style={checkSty} value="1">
                                    1
                                </Checkbox>
                                <Checkbox style={checkSty} value="2">
                                    2
                                </Checkbox>
                                <Checkbox style={checkSty} value="3">
                                    4
                                </Checkbox>
                                <Checkbox style={checkSty} value="4">
                                    8
                                </Checkbox>
                            </Checkbox.Group>
                        </div>
                    </div>
                    <div style={checkItem}>
                        <div style={checkLabelSty}>
                            <span>外观结构：</span>
                        </div>
                        <div style={checkContentSty}>
                            <Checkbox.Group
                                style={{ width: "100%" }}
                                onChange={(target) => {
                                    this.onChange_2(target);
                                }}
                                value={this.state.defaultVals_2}
                            >
                                <Checkbox style={checkSty} value="PFM">
                                    不限
                                </Checkbox>
                                <Checkbox style={checkSty} value="1">
                                    1U
                                </Checkbox>
                                <Checkbox style={checkSty} value="2">
                                    2U
                                </Checkbox>
                                <Checkbox style={checkSty} value="3">
                                    4U
                                </Checkbox>
                                <Checkbox style={checkSty} value="4">
                                    5U及以上
                                </Checkbox>
                            </Checkbox.Group>
                        </div>
                    </div>
                    <div style={checkItem}>
                        <div style={checkLabelSty}>
                            <span>支持GPU/PHI数量：</span>
                        </div>
                        <div style={checkContentSty}>
                            <Checkbox.Group
                                style={{ width: "100%" }}
                                onChange={(target) => {
                                    this.onChange_3(target);
                                }}
                                value={this.state.defaultVals_3}
                            >
                                <Checkbox value="PHI">不限</Checkbox>
                                <Checkbox value="1">2</Checkbox>
                                <Checkbox value="2">4</Checkbox>
                                <Checkbox value="3">6</Checkbox>
                                <Checkbox value="4">8</Checkbox>
                                <Checkbox value="5">10</Checkbox>
                            </Checkbox.Group>
                        </div>
                    </div>
                    <div style={checkItem}>
                        <div style={checkLabelSty}>
                            <span>NVMe SSD支持数量：</span>
                        </div>
                        <div style={checkContentSty}>
                            <Checkbox.Group
                                style={{ width: "100%" }}
                                onChange={(target) => {
                                    this.onChange_4(target);
                                }}
                                value={this.state.defaultVals_4}
                            >
                                <Checkbox value="SSD">不限</Checkbox>
                                <Checkbox value="1">1</Checkbox>
                                <Checkbox value="2">2</Checkbox>
                                <Checkbox value="3">3</Checkbox>
                                <Checkbox value="4">4</Checkbox>
                                <Checkbox value="5">4个以上</Checkbox>
                            </Checkbox.Group>
                        </div>
                    </div>
                    {/* <Row>
                    <Checkbox.Group
                        style={{ width: "100%" }}
                        onChange={(e) => {
                            this.onChange(e);
                        }}
                    >
                        <span>123128734787843: </span>
                        <Checkbox value="A">A</Checkbox>
                        <Checkbox value="A">A</Checkbox>
                        <Checkbox value="A">A</Checkbox>
                        <Checkbox value="A">A</Checkbox>
                        <Checkbox value="A">A</Checkbox>
                    </Checkbox.Group>
                </Row> */}
                    {/* <Form
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 14 }}
                    layout="iniline"
                    // labelAlign="left"
                    // initialValues={{ disabled: componentDisabled }}
                    onValuesChange={(e) => {
                        this.onFormLayoutChange(e);
                    }}
                    // disabled={componentDisabled}
                >
                    <Form.Item name="reportCycle" label="123123">
                        <Checkbox.Group
                            options={this.state.cycleOptions}
                            onChange={this.onChangeCycle}
                        />
                    </Form.Item>
                    <Form.Item name="reportCycle" label="123123">
                        <Checkbox.Group
                            options={this.state.cycleOptions1}
                            onChange={this.onChangeCycle}
                        />
                    </Form.Item>

                    <div>
                        <Form.Item
                            label="Form disabled"
                            name="disabled"
                            valuePropName="checked"
                        >
                            <Checkbox>disabled</Checkbox>
                        </Form.Item>
                    </div>
                    <Form.Item label="Form disabled:" name="disabled">
                        <Checkbox value="A">不限</Checkbox>
                        <Checkbox value="A">1</Checkbox>
                        <Checkbox value="A">2</Checkbox>
                        <Checkbox value="A">3</Checkbox>
                        <Checkbox value="A">8</Checkbox>
                    </Form.Item>
                    <Form.Item
                        label="Form disabled:"
                        name="disabled"
                        valuePropName="checked"
                    >
                        <Checkbox value="A">不限</Checkbox>
                        <Checkbox value="A">1</Checkbox>
                        <Checkbox value="A">2</Checkbox>
                        <Checkbox value="A">3</Checkbox>
                        <Checkbox value="A">4</Checkbox>
                        <Checkbox value="A">4个以上</Checkbox>
                    </Form.Item>
                </Form> */}
                </div>
            </div>,
            <div>
                {this.state.productList.length ? (
                    <Content5
                        id="general_machine"
                        key="general_machine"
                        dataSource={Content50DataSource}
                        productList={this.state.productList || []}
                        isMobile={this.state.isMobile}
                    />
                ) : (
                    <div style={{ minHeight: "600px", background: "#f8f8f8" }}>
                        {this.state.isFinished &&
                        this.state.productList.length == 0 ? (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={""}
                                style={{
                                    margin: 0,
                                    marginTop: "50px",
                                }}
                            />
                        ) : null}
                    </div>
                )}
            </div>,

            // <Content5
            //     id="ai_machine"
            //     key="ai_machine"
            //     dataSource={Content50DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <Content5
            //     id="epyc_machine"
            //     key="epyc_machine"
            //     dataSource={Content50DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <Content5
            //     id="aa_machine"
            //     key="aa_machine"
            //     dataSource={Content50DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <div className="c_a_1_1_title_wrapper">
            //     <h1 className="c_a_1_1_title">通用服务器</h1>
            // </div>,
            // <div className="product_box">
            //     {[1, 1, 1, 1, 1, 1, 1, 1].map((item) => {
            //         return this.getProductItem(item);
            //     })}
            // </div>,
            // <Content0
            //     id="Content0_0"
            //     key="Content0_0"
            //     dataSource={Content00DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <Content7
            //   id="Content7_0"
            //   key="Content7_0"
            //   dataSource={Content70DataSource}
            //   isMobile={this.state.isMobile}
            // />,
        ];
        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
                style={{ background: "#f8f8f8" }}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {this.state.show && children}
                {/* 如果不是 dva 2.0 替换成 {children} end */}

                <CSCBackTop
                    isMobile={this.state.isMobile}
                    key="product_search"
                />
                {/* <Spin spinning={!this.state.isFinished} delay={500} /> */}
            </div>
        );
    }
}
