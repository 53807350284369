import React from "react";
import "./widget.less";

export default function CSCDecorateLine(props) {
    const { children, isMobile } = props;
    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div
                style={{
                    width: isMobile ? "5rem" : "32rem",
                    height: "1px",
                    background: "#cbcccb",
                    // marginBottom: "16px",
                }}
            ></div>
            <div
                style={{
                    width: isMobile ? "0.4rem" : "0.9rem",
                    height: isMobile ? "0.4rem" : "0.9rem",
                    borderRadius: "50%",
                    background: "#cbcccb",
                    marginRight: isMobile ? "1rem" : "3rem",
                    // marginBottom: "16px",
                }}
            ></div>
            {children}
            <div
                style={{
                    width: isMobile ? "0.4rem" : "0.9rem",
                    height: isMobile ? "0.4rem" : "0.9rem",
                    borderRadius: "50%",
                    background: "#cbcccb",
                    marginLeft: isMobile ? "1rem" : "3rem",
                    // marginBottom: "16px",
                }}
            ></div>
            <div
                style={{
                    width: isMobile ? "5rem" : "32rem",
                    height: "1px",
                    background: "#cbcccb",
                    // marginLeft: "3rem",
                    // marginBottom: "16px",
                }}
            ></div>
        </div>
    );
}
