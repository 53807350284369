import React from "react";
import TweenOne from "rc-tween-one";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
// import QueueAnim from "rc-queue-anim";
import { Row, Col } from "antd";
import { getChildrenToRender } from "./utils";
import { isImg } from "./utils";

class Footer extends React.Component {
    static defaultProps = {
        className: "footer1",
    };

    getLiChildren = (data) =>
        data.map((item, i) => {
            const { title, childWrapper, ...itemProps } = item;
            console.log("11111foottttt", itemProps);
            return (
                <Col
                    key={i.toString()}
                    {...itemProps}
                    title={null}
                    content={null}
                >
                    <h2 {...title}>
                        {typeof title.children === "string" &&
                        title.children.match(isImg) ? (
                            <img src={title.children} width="100%" alt="img" />
                        ) : (
                            title.children
                        )}
                    </h2>
                    <div {...childWrapper}>
                        {childWrapper.children.map(getChildrenToRender)}
                    </div>
                </Col>
            );
        });

    render() {
        const { ...props } = this.props;
        const { dataSource } = props;
        delete props.dataSource;
        delete props.isMobile;
        const childrenToRender = this.getLiChildren(dataSource.block.children);
        return (
            <div {...props} {...dataSource.wrapper}>
                <OverPack {...dataSource.OverPack}>
                    <TweenOne
                        type="bottom"
                        key="ul"
                        leaveReverse
                        component={Row}
                        {...dataSource.block}
                        forcedReplay={false}
                    >
                        {childrenToRender}
                    </TweenOne>
                    <TweenOne
                        forcedReplay={false}
                        animation={{ y: "+=30", opacity: 0, type: "from" }}
                        key="copyright"
                        {...dataSource.copyrightWrapper}
                    >
                        <div {...dataSource.copyrightPage}>
                            <div {...dataSource.copyright}>
                                {dataSource.copyright.children}
                            </div>
                        </div>
                    </TweenOne>
                </OverPack>
            </div>
        );
    }
}

export default Footer;
