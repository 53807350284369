/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import { Row, Col, Empty, BackTop } from "antd";
import Content0 from "./Content0";
import Content7 from "./Content7";
import Banner from "../Banner/index";
import CSCButton from "../widget/CSCButton";
import CSCBackTop from "../widget/CSCBackTop";
import Content5 from "./Content5";
import owRequest from "../libs/request";

import {
    Content00DataSource,
    Content70DataSource,
    Banner10DataSource,
    Content50DataSource,
} from "./data.source.js";

import "./less/antMotionStyle.less";
import "./less/product.less";

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const location = window.location;

export default class Product extends React.Component {
    constructor(props) {
        super(props);
        this.typesOptions = [
            {
                id: "1",
                name: "智算服务器",
                children: [
                    {
                        id: "11",
                        name: "Intel至强服务器",
                        children: [],
                    },
                    {
                        id: "12",
                        name: "AMD EPYC服务器",
                        children: [],
                    },
                    {
                        id: "13",
                        name: "AI服务器",
                        children: [],
                    },
                    {
                        id: "14",
                        name: "边缘计算服务器",
                        children: [],
                    },
                ],
            },
            {
                id: "2",
                name: "信创服务器",
                children: [
                    {
                        id: "21",
                        name: "飞腾平台服务器",
                        children: [],
                    },
                    {
                        id: "22",
                        name: "龙芯平台服务器",
                        children: [],
                    },
                    // {
                    //     id: "23",
                    //     name: "鲲鹏平台服务器",
                    //     children: [],
                    // },
                    {
                        id: "24",
                        name: "C86平台服务器",
                        children: [],
                    },
                ],
            },
            {
                id: "3",
                name: "液冷服务器",
            },
            {
                id: "4",
                name: "微模块数据中心",
            },
            {
                id: "5",
                name: "信创工作站",
            },
            {
                id: "6",
                name: "集中式存储",
                children: [
                    {
                        id: "61",
                        name: "X86统一存储",
                        children: [],
                    },
                    {
                        id: "62",
                        name: "信创统一存储",
                        children: [],
                    },
                ],
            },
            {
                id: "7",
                name: "分布式存储",
                children: [
                    {
                        id: "71",
                        name: "X86分布式存储",
                        children: [],
                    },
                    {
                        id: "72",
                        name: "信创分布式存储",
                        children: [],
                    },
                    // {
                    //     id: "73",
                    //     name: "全闪分布式存储",
                    //     children: [],
                    // },
                ],
            },
            {
                id: "8",
                name: "云平台/虚拟化",
            },
            {
                id: "9",
                name: "超融合",
            },
            {
                id: "10",
                name: "云桌面",
            },
            // {
            //     id: "11",
            //     name: "PAAS平台",
            // },
            // {
            //     id: "12",
            //     name: "大数据",
            // },
            // {
            //     id: "13",
            //     name: "安全",
            // },
        ];
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
            activeTab: 0,
            productAssembleData: [],
        };
    }

    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
                window.scrollTo(0, 0);
            }, 500);
        }

        // product

        let url = window.location.href;
        let pCode = url.split("?p=")[1];
        // console.log("pCode", pCode);
        this.pCode = pCode;
        this.getProductList(pCode);

        /* 如果不是 dva 2.0 请删除 end */
        // { name: "智算服务器", path: "/product?p=1" },
        // { name: "信创服务器", path: "/product?p=2" },
        // { name: "液冷服务器", path: "/product?p=3" },
        // { name: "微模块数据中心", path: "/product?p=4" },
        // { name: "国产X86平台工作站", path: "/product?p=5" },
    }

    getPCodeName() {
        let str = "";
        for (let item of this.typesOptions) {
            if (item.id == this.pCode) {
                str = item.name;
            }
        }
        return str;
    }

    getProductList(pCode) {
        owRequest
            .get(`/office-website/product?product_type=${pCode}`)
            .then((res) => {
                // console.log("res", res);
                if (res && res.code == 2000) {
                    let ret = [];
                    let keyName = "";
                    let keyId = "";
                    for (let item of this.typesOptions) {
                        if (item.id == this.pCode) {
                            if (item.children) {
                                ret = item.children || [];
                                break;
                            } else {
                                keyName = item.name;
                                keyId = item.id;
                            }
                        }
                    }

                    // console.log("typecode", ret);
                    if (ret && ret.length) {
                        for (let item of res.data.rows) {
                            for (let n of ret) {
                                if (!item) {
                                    break;
                                }
                                if (n.id == item.product_sub_type) {
                                    n["children"].push(item);
                                }
                            }
                        }
                        this.setState({ productAssembleData: ret || [] });
                    } else {
                        let arr = [];
                        let obj = {
                            id: keyId,
                            name: keyName,
                            children: res.data.rows,
                        };
                        arr.push(obj);
                        // console.log("128918182", arr);
                        this.setState({ productAssembleData: arr || [] });
                    }
                    // console.log("rettt", ret);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    }
    animateScroll(element, speed) {
        let rect = element.getBoundingClientRect();
        // console.log("rect_____________________", rect.top);
        //获取元素相对窗口的top值，此处应加上窗口本身的偏移
        let top = window.pageYOffset + rect.top;
        let currentTop = 0;
        let requestId;
        //采用requestAnimationFrame，平滑动画
        function step(timestamp) {
            currentTop += speed;
            if (currentTop <= top) {
                window.scrollTo(0, currentTop);
                requestId = window.requestAnimationFrame(step);
            } else {
                window.cancelAnimationFrame(requestId);
            }
        }
        window.requestAnimationFrame(step);
    }

    onClickTabItem(activeTab, id) {
        // console.log("activeTab_id", activeTab, this.state.productAssembleData);
        this.setState({ activeTab: activeTab });
        // window.scrollTo({
        //     left: 0, // x坐标
        //     top: 400, // y 坐标
        //     // top: 3260.5556640625, // y 坐标

        //     behavior: "smooth", // 可选值：smooth、instant、auto
        // });
        // document.geti
        // console.log(TAG, "BottomView next:", myRef);
        // let yHeight = 0;
        // if (!this.state.isMobile) {
        //     for (let i = 0; i < activeTab + 1; i++) {
        //         let item = this.state.productAssembleData[i];
        //         let len = item.children ? item.children.length : 0;
        //         yHeight = yHeight + Math.ceil(len / 5) * 660;
        //         console.log("9999", len, yHeight);
        //     }
        // }

        let anchorElement = document.getElementById(id);
        // console.log(
        //     "anchorElement",
        //     anchorElement,
        //     anchorElement.scrollTop,
        //     anchorElement.getBoundingClientRect()
        // );
        const yHeight =
            anchorElement && anchorElement.getBoundingClientRect().y;

        // this.animateScroll(anchorElement, 20);
        // return;

        if (activeTab == 0) {
            window.scrollTo({
                left: 0, // x坐标
                top: yHeight, // y 坐标
                behavior: "smooth", // 可选值：smooth、instant、auto
            });
            return;
        }

        // console.log(yHeight);
        // console.log("yHeight", yHeight);
        // setTimeout(() => {
        window.scrollTo({
            left: 0, // x坐标
            // top: yHeight + 400, // y 坐标
            top: yHeight, // y 坐标

            behavior: "smooth", // 可选值：smooth、instant、auto
        });
        // }, 1000);
        // window.scrollTo(0, yHeight, {behavior: ‘smooth’});
    }

    onClickSearchBtn() {
        window.open("/product/search");
    }
    adaptHeight(Content50DataSource) {
        if (Content50DataSource.length == 0) {
            return "500px";
        }
        // return "auto";
        // console.log(this.state.isMobile, "23983sjdhhjdhjds");
        if (this.state.isMobile) {
            // console.log("93388383");
            let result = this.state.isMobile
                ? Content50DataSource.length * 290
                : Math.ceil(Content50DataSource.length / 4) * 280;
            // console.log("sdkjiueiuiuer", result);
            result = result + 400;
            return result + "px";
        } else {
            let result = this.state.isMobile
                ? Content50DataSource.length * 320
                : Math.ceil(Content50DataSource.length / 4) * 280;
            // console.log("181273737", result);
            result = result + 400;
            return result + "px";
        }

        if (this.state.isMobile) {
            const result = this.state.isMobile
                ? Content50DataSource.length * 340 + "px"
                : Math.ceil(Content50DataSource.length / 4) * 340 + "px";
            return result;
        }
    }

    render() {
        let arr = [];
        for (let item of this.typesOptions) {
            if (item.id == this.pCode) {
                arr = item.children;
                break;
            }
        }
        // eslint-disable-next-line no-sparse-arrays
        const children = [
            // <Banner
            //   id="Banner1_0"
            //   key="Banner1_0"
            //   dataSource={Banner10DataSource}
            //   isMobile={this.state.isMobile}
            // />,
            <div style={{ position: "relative" }}>
                {/* {!this.state.isMobile ? (
                    <img
                        src={require("../assets/head_product.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                ) : (
                    <img
                        src={require("../assets/head_product.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )} */}
                {this.pCode == 1 && (
                    <img
                        src={require("../assets/head_product_1.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.pCode == 2 && (
                    <img
                        src={require("../assets/head_product_2.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.pCode == 3 && (
                    <img
                        src={require("../assets/head_product_3.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.pCode == 4 && (
                    <img
                        src={require("../assets/head_product_4.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.pCode == 5 && (
                    <img
                        src={require("../assets/head_product_5.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.pCode == 6 && (
                    <img
                        src={require("../assets/head_product_6.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.pCode == 7 && (
                    <img
                        src={require("../assets/head_product_7.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.pCode == 8 && (
                    <img
                        src={require("../assets/head_product_8.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.pCode == 9 && (
                    <img
                        src={require("../assets/head_product_9.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                {this.pCode == 10 && (
                    <img
                        src={require("../assets/head_product_10.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                 {this.pCode == 11 && (
                    <img
                        src={require("../assets/head_product_11.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                 {this.pCode == 12 && (
                    <img
                        src={require("../assets/head_product_12.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
                 {this.pCode == 13 && (
                    <img
                        src={require("../assets/head_product_13.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}

                {/* <div
                    style={{
                        position: "absolute",
                        // top: this.state.isMobile ? "3rem" : "15rem",
                        // left: this.state.isMobile ? "2rem" : "16rem",
                        // color: "#fff",
                        // fontSize: this.state.isMobile ? "20px" : "42px",
                        top: this.state.isMobile ? "3rem" : "40%",
                        left: this.state.isMobile ? "1rem" : "15%",
                        color: "#fff",
                        fontSize: this.state.isMobile ? "1.4rem" : "2.4vw",
                        zIndex: 100,
                        fontWeight: 700,
                    }}
                >
                    {this.getPCodeName()}
                </div> */}
            </div>,
            <div className="p_tab_box_51_wrapper" key={"p_tab_box_51_wrapper"} style={{background:"#fff"}}>
                {/* <div className="p_tab_box_51">
                    {[
                        { title: "通用服务器", href: "general_machine" },
                        { title: "AI服务器", href: "ai_machine" },
                        { title: "EPYC服务器", href: "epyc_machine" },
                        { title: "边缘计算服务器", href: "byjs_machine" },
                    ].map((item, index) => {
                        console.log("item", item);
                        return (
                            <a
                                // onClick = {()=>{}}
                                // href={`#${item.href}`}
                                key={"cb" + index}
                                className={
                                    this.state.activeTab == index
                                        ? "p_tab_item_51_active p_tab_item_51"
                                        : "p_tab_item_51"
                                }
                                onClick={() => {
                                    this.onClickTabItem(index, item.href);
                                }}
                            >
                                {item.title}
                            </a>
                        );
                    })}
                </div> */}
                {this.state.productAssembleData.length && this.state.productAssembleData.length>1 
                    ?
                    <div className="p_tab_box_51_123">
                    {/* {JSON.stringify(this.state.productAssembleData)} */}
                    {this.state.productAssembleData.length 
                    &&
                    this.state.productAssembleData.length > 1
                        ? this.state.productAssembleData.map((item, index) => {
                              return (
                                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                  <a
                                      key={"cb" + index}
                                      className={
                                          this.state.activeTab == index
                                              ? "p_tab_item_51_active p_tab_item_51"
                                              : "p_tab_item_51"
                                      }
                                      style={{
                                          width: this.state.isMobile
                                              ? "5rem"
                                              : "auto",
                                        //   margin: "0 2px",
                                      }}
                                      onClick={() => {
                                          this.onClickTabItem(index, item.id);
                                      }}
                                  >
                                      {item.name}
                                  </a>
                              );
                          })
                       
                        : null}
                </div> : null}

                 {this.state.productAssembleData.length 
                        &&
                    this.state.productAssembleData.length === 1 ? 
                    <div className="p_tab_box_51_index1">
                    {/* {JSON.stringify(this.state.productAssembleData)} */}
                        {this.state.productAssembleData.length 
                            &&
                        this.state.productAssembleData.length === 1 ?
                                (<div
                                    key={"cb1"}
                                    className={"p_tab_item_51_active p_tab_item_51"}
                                    style={{
                                        maxWidth: this.state.isMobile
                                            ? "4rem !important"
                                            : "4rem !important",
                                        minWidth: this.state.isMobile
                                            ? "4rem !important"
                                            : "4rem !important",
                                        border:"0px !important"
                                    }}
                                    onClick={() => {
                                        this.onClickTabItem(0, this.state.productAssembleData[0].id);
                                    }}
                                >
                                    {this.state.productAssembleData[0].name}
                                </div>)
                        : null}
                </div> : null}
              
            </div>,
            <div className="line_bar"></div>,
            <div>
                {this.pCode == 1 || this.pCode == 2 || this.pCode == 3 ? (
                    <div key={"p_search_box"}>
                        <CSCButton
                            title="搜索更多"
                            onClick={() => {
                                this.onClickSearchBtn();
                            }}
                        />
                    </div>
                ) : null}
            </div>,
            // <div style={{ background: "#fff" }}>
            //     {this.state.productAssembleData.length == 0 ? (
            //         <Empty
            //             image={Empty.PRESENTED_IMAGE_SIMPLE}
            //             description={""}
            //         />
            //     ) : null}
            // </div>,
            <div style={{}}>
                {this.state.productAssembleData.map((item) => {
                    return (
                        <div
                            id={item.id}
                            key={item.id}
                            style={{
                                height: this.adaptHeight(item.children),
                                width: "100%",
                                background: "#fafafa",
                                minHeight: "500px",
                                // height: (item.children.length / 5) * 800 + "px",
                            }}
                        >
                            <Content5
                                // id="aa_machine"
                                // key="aa_machine"
                                dataSource={Content50DataSource}
                                productChildData={item.children}
                                isMobile={this.state.isMobile}
                                content5title={item.name}
                            />
                            ,
                        </div>
                    );
                })}
            </div>,


            ,
        ];
        return (
            <div
                className="templates-wrapper"
                style={{ background: "#fafafa", paddingBottom: "160px" }}
                ref={(d) => {
                    this.dom = d;
                }}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {this.state.show && children}
                {/* 如果不是 dva 2.0 替换成 {children} end */}

                <CSCBackTop isMobile={this.state.isMobile} key="product_list" />
            </div>
        );
    }
}

const style = React.cree;
