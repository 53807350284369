import axios from "axios";
import config from "./config";

const service = axios.create({
    baseURL: config.dev_url,
    timeout: 20000,
    headers: {},
});
// 添加请求拦截器(可以检查是否登录)
service.interceptors.request.use(
    (config) => {
        // config.headers["token"] = localStorage.getItem("user_token");
        // config.data = qs.stringify(config.data);
        return config;
    },
    (error) => {
        console.log("err" + error); // for debug
        return Promise.reject(error);
    }
);

// 添加响应拦截器
service.interceptors.response.use(
    (response) => {
        // if (response && response.headers && response.headers["jh-token"]) {
        //     localStorage.setItem("user_token", response.headers["jh-token"]);
        // }
        const data = response.data;
        // if (data && data.code === "1001") {
        //     localStorage.removeItem("user_token");
        //     localStorage.removeItem("user_type");
        //     location.href = "/apply/#/login";
        //     Toast(data.msg || "登陆失效，请重新登录");
        // }
        return data;
    },
    (error) => {
        console.log("err" + error);
        return Promise.reject(error);
    }
);

export default service;
