/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import CSCTitle from "../../widget/CSCTitle";
import { MailFilled, EnvironmentFilled } from "@ant-design/icons";
import owRequest from "../../libs/request";

import "./less/antMotionStyle.less";
import "./less/product.less";

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const location = window.location;

const textSty = {
    fontSize: isMobile ? "13px" : "18px",
    // textIndent: isMobile ? "20px" : "40px",
    maxWidth: "980px",
    padding: isMobile ? "15px" : "20px",
    margin: "0 auto",
};

export default class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
            activeTab: 0,
            jobObj: {},
        };
    }

    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
        /* 如果不是 dva 2.0 请删除 end */
        let url = window.location.href;
        console.log("this.props.location", this.props);
        // let jCode = url.split("?jobid=")[1];
        const rCode = this.getUrlParam("rcode");
        const jCode = this.getUrlParam("jobid");

        // const { jobid, rCode } = this.props.location.query;
        this.jCode = jCode;
        this.rCode = rCode;
        console.log("jobid_rCode", this.jCode, this.rCode);
        this.getRecruitDetail();
    }
    getUrlParam(paraName) {
        var url = document.location.toString();
        var arrObj = url.split("?");

        if (arrObj.length > 1) {
            var arrPara = arrObj[1].split("&");
            var arr;

            for (var i = 0; i < arrPara.length; i++) {
                arr = arrPara[i].split("=");

                if (arr != null && arr[0] == paraName) {
                    return arr[1];
                }
            }
            return "";
        } else {
            return "";
        }
    }

    getRecruitDetail() {
        owRequest
            .get(`/office-website/recruit/detail?id=${this.jCode}`)
            .then((res) => {
                // console.log("res", res);
                if (res && res.code == 2000) {
                    let ret = [];
                    if (res.data.rows && res.data.rows.length) {
                        ret = res.data.rows[0];
                    }
                    this.setState({
                        jobObj: ret,
                    });
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    }

    onClickTabItem(activeTab, id) {
        this.setState({ activeTab: activeTab });
        // document.geti
        // console.log(TAG, "BottomView next:", myRef);
        let anchorElement = document.getElementById(id);
        // console.log(
        //     "anchorElement",
        //     anchorElement,
        //     anchorElement.getBoundingClientRect()
        // );
        const yHeight = anchorElement.getBoundingClientRect().y;

        if (activeTab == 0) {
            window.scrollTo(0, 0);
        }

        // console.log("yHeight", yHeight);
        window.scrollTo({
            left: 0, // x坐标
            top: yHeight - 100, // y 坐标
            behavior: "smooth", // 可选值：smooth、instant、auto
        });
        // window.scrollTo(0, yHeight, {behavior: ‘smooth’});
    }

    onClickSearchBtn() {
        window.open("/product/search");
    }

    adaptHeight(Content50DataSource) {
        // return "auto";
        const result = this.state.isMobile
            ? Content50DataSource.block.children.length * 340 + "px"
            : Math.ceil(Content50DataSource.block.children.length / 4) * 340 +
              "px";
        return result;
    }

     getServiceScope(){
     return (
            <div
                style={textSty}
                dangerouslySetInnerHTML={{
                    __html: `<div class="myTab_block NeiRong" style="border:0 none;padding-top:0;">
    <p style="text-indent: 2em;">
        近日我公司（超云数字技术集团有限公司）发现有不明机构或人员通过互联网APP、微信等渠道冒用我公司招聘人员的身份，发布关于我公司的虚假信息或使用企业微信、其他独立的APP等添加求职者好友，以岗前培训、试岗考核等多种名义，诱导求职者进行刷单、缴费等活动，敛取财物。    
    </p >
    <br>
    <p style="text-indent: 2em;">
        为规范市场环境，保护广大求职者利益，维护我公司合法权益及形象，我公司在此严正声明如下:
    </p >
    <br>
    <p style="text-indent: 2em;">
        一、我公司从未委托任何第三方组织或个人代为招聘人员或单独开展招聘活动。上述不明机构或人员的行为与我司不存在任何关系。
    </p >
    <br>
    <p style="text-indent: 2em;">
        二、我公司在招聘过程中从未要求求职者缴纳任何押金、保证金、手续费等任何形式的费用，从未以岗前培训、试岗考核等名义收取费用，从未编辑或出版过任何关于面试、笔试等应聘相关的内部资料。    
    </p >
    <br>
    <p style="text-indent: 2em;">
        三、对于冒用我公司名义虚假招聘、涉嫌欺诈以及其他一切侵犯我公司合法权益的非法行为，我公司保留追究其法律责任的权利。    
    </p >
    <br>
    <p style="text-indent: 2em;">
        请广大求职者提高警惕，注意防范风险，若发现有人冒用我公司名义或冒充我公司人员实施欺诈，请及时向相关信息发布账号所属平台进行投诉、举报，并向公安机关报案。    
    </p >
    <br>
    <p style="text-indent: 2em;">
        特此声明!    
    </p >
    <br>
    <p style="text-align: right;">
        超云数字技术集团有限公司    
    </p >
    <br>
    <p style="text-align: right;">
        2024年06月18日
    </p >
</div>`,
                }}
            ></div>
        );
    }

    render() {
        const labelSty = {
            width: "0.3rem",
            height: "1.2rem",
            display: "inline-block",
            verticalAlign: "middle",
            marginRight: "12px",
            // marginBottom: "5px",
            background: "#2FB467",
        };
        const p1Sty = {
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
        };
        const children = [
            <div>
                <img
                    src={require("../../assets/recruit_explain.png")}
                    alt=""
                    style={{ width: "100%" }}
                />
            </div>,

           <div
                id="services_scope"
                key="services_scope"
                style={{
                    height: "auto",
                    width: "100%",
                    background: "#fff",
                    paddingBottom: "80px",
                    paddingTop: "80px",
                }}
            >
                <CSCTitle
                    title="关于不法分子冒用我公司名义虚假招聘的声明"
                    key="services_scope"
                    isMobile={this.state.isMobile}
                    style={{
                        margin: 0,
                        height: "auto",
                        marginBottom: "30px",
                    }}
                    textSty={{
                        fontSize: this.state.isMobile ? "20px" : "28px",
                        fontWeight: "700",
                    }}
                />
                {this.getServiceScope()},
            </div>,
        ];
        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {this.state.show && children}
                {/* 如果不是 dva 2.0 替换成 {children} end */}
            </div>
        );
    }
}

const style = React.cree;
