import React from "react";
// import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import { Row, Col, Carousel } from "antd";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import CSCDecorateLine from "../widget/CSCDecorateLine";

class Content1 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 1,
            activeSolution: 1,
            hoverIndex: 0,
            staticData: [
                {
                    code: 1,
                    id: "cab_1",
                    title: "政府与公共事业",
                    imgUrl: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg-qn.51miz.com%2Fpreview%2Felement%2F00%2F01%2F08%2F71%2FE-1087125-0F522198.jpg&refer=http%3A%2F%2Fimg-qn.51miz.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1658987603&t=36b289756a95e4eb6a620d5720a60636",
                    row1: [
                        {
                            title: "政府与公共事业",
                            action: "",
                        },
                        {
                            title: "互联网",
                            action: "",
                        },
                    ],
                    row2: [
                        {
                            title: "互联网",
                            action: "",
                        },
                        {
                            title: "互联网",
                            action: "",
                        },
                    ],
                },
                {
                    code: 2,
                    id: "cab_2",
                    title: "互联网",
                    imgUrl: "https://img1.baidu.com/it/u=3581445254,3656625954&fm=253&fmt=auto&app=138&f=JPEG?w=658&h=298",
                    row1: [
                        {
                            title: "互联网",
                            action: "",
                        },
                        {
                            title: "互联网",
                            action: "",
                        },
                    ],
                    row2: [
                        {
                            title: "互联网",
                            action: "",
                        },
                        {
                            title: "互联网",
                            action: "",
                        },
                    ],
                },
                {
                    code: 3,
                    id: "cab_3",
                    title: "互联网",
                    imgUrl: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic.616pic.com%2Fbg_w1180%2F00%2F03%2F34%2FAXae2808oj.jpg&refer=http%3A%2F%2Fpic.616pic.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1658992969&t=a7e5b812d340e2134defb16c5fa5641b",
                    row1: [
                        {
                            title: "互联网",
                            action: "",
                        },
                        {
                            title: "互联网",
                            action: "",
                        },
                    ],
                    row2: [
                        {
                            title: "互联网",
                            action: "",
                        },
                        {
                            title: "互联网",
                            action: "",
                        },
                    ],
                },
            ],
        };
        this.CarouselRef = null;
    }

    onClickTabItem(item) {
        // console.log("item", item);
        this.CarouselRef.goTo(item.code - 1);
        this.setState({
            activeTab: item.code,
        });
    }

    onChange = (e) => {
        this.setState({
            activeTab: e + 1,
        });
    };

    onClickSolutionItem = (e) => {
        this.setState({ activeSolution: e });
    };

    handleMouseEnter = (e) => {
        this.setState({ hoverIndex: e });
    };
    handleMouseLeave = (e) => {
        this.setState({ hoverIndex: 0 });
    };

    render() {
        const { ...tagProps } = this.props;
        const { dataSource, isMobile } = tagProps;
        delete tagProps.dataSource;
        const staticData_1 = [
            { id: 1, name: "党务政务" },
            // { id: 2, name: "航天军工" },
            { id: 3, name: "教科研" },
            { id: 4, name: "医疗" },
            { id: 5, name: "公安" },
        ];

        const staticData_2 = [
            { id: 6, name: "互联网" },
            { id: 7, name: "金融" },
            { id: 8, name: "电信" },
            { id: 9, name: "能源" },
            { id: 10, name: "交通" },
            // { id: 11, name: "ISV" },
        ];
        const staticData_3 = [
            { id: 12, name: "云计算" },
            { id: 13, name: "超融合" },
            { id: 14, name: "高性能计算" },
            { id: 15, name: "人工智能" },
            { id: 16, name: "数据中心" },
        ];
        // delete tagProps.isMobile;
        const bottomTextSty = {
            textAlign: "center",
            fontSize: "28px",
            fontWeight: "600",
            position: "absolute",
            top: "30px",
            color: "#fff",
        };
        const activeSty = {
            flex: 10,
            transition: "all 0.5s",
            position: "relative",
            display: "flex",
            justifyContent: "center",
        };
        const inActiveSty = {
            flex: 3,
            transition: "all 0.4s",
            position: "relative",
            display: "flex",
            justifyContent: "center",
        };
        const contentItem = {
            color: "#fff",
            borderBottom: "2px solid #fff",
            paddingBottom: "5px",
            fontWeight: "600",
            fontSize: isMobile ? "12px" : "20px",
            marginBottom: isMobile ? "10px" : "2rem",
            maxWidth: isMobile ? "6rem" : "10rem",
            minWidth: isMobile ? "5rem" : "10rem",
            cursor: "pointer",
        };
        const contentBox = {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: "4rem",
            width: "100%",
            marginLeft: "10px",
            position: "absolute",
            left: "50px",
            top: "70px",
            zIndex: 2000,
        };
        const bg_1 = {
            height: "600px",
            background: "url(" + require("../assets/s_1.png") + ") ",
        };
        const bg_2 = {
            height: "600px",
            background: "url(" + require("../assets/s_2.png") + ") ",
        };
        const bg_3 = {
            height: "600px",
            background: "url(" + require("../assets/s_3.png") + ") ",
        };
        const mobild_box_sty = {
            width: "100%",
            position: "relative",
            height: "280px",
            marginBottom: "60px",
        };
        return (
            <div
                {...tagProps}
                {...dataSource.wrapper}
                style={{
                    width: "100%",
                    height: isMobile ? "1200px" : "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <OverPack
                    {...dataSource.OverPack}
                    component={Row}
                    style={{
                        // minWidth: "100rem",
                        width: "96vw",
                        // width: "120rem",
                        maxWidth: "100rem",
                        // marginLeft: "2vw",
                        // marginRight: "2vw",

                        height: isMobile ? "1200px" : "auto",
                    }}
                >
                    <TweenOne
                               forcedReplay={false}
                        type="bottom"
                        key="block"
                        leaveReverse
                        style={{ height: isMobile ? "auto" : "auto" }}
                    >
                        <div
                            className="c_a_1_1_title_wrapper"
                            style={{
                                marginTop: "0px",
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <CSCDecorateLine
                                children={
                                    <h1 className="c_a_1_1_title">解决方案</h1>
                                }
                                isMobile={isMobile}
                            />
                        </div>
                        {!isMobile ? (
                            <div
                                style={{
                                    display: "flex",
                                    alignItem: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    width: "100%",
                                }}
                            >
                                <div
                                    // className={"hover_sty"}
                                    style={
                                        this.state.activeSolution == 1
                                            ? { ...activeSty, ...bg_1 }
                                            : { ...inActiveSty, ...bg_1 }
                                    }
                                    onMouseEnter={(e) => {
                                        // console.log("eeee", e);
                                        this.onClickSolutionItem(1);
                                    }}
                                >
                                    <div style={contentBox}>
                                        {staticData_1.map((item) => {
                                            return (
                                                <div
                                                    onClick={() => {
                                                        window.open(
                                                            `/solution?s=${item.id}`
                                                        );
                                                    }}
                                                    style={{
                                                        ...contentItem,
                                                        color:
                                                            this.state
                                                                .hoverSolution ==
                                                            item.id
                                                                ? "#2FB467"
                                                                : "#fff",
                                                        borderBottom:
                                                            this.state
                                                                .hoverSolution ==
                                                            item.id
                                                                ? "1px solid #2FB467"
                                                                : "1px solid #fff",
                                                    }}
                                                    onMouseEnter={(e) => {
                                                        this.setState({
                                                            hoverSolution:
                                                                item.id,
                                                        });
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        this.setState({
                                                            hoverSolution: null,
                                                        });
                                                    }}
                                                >
                                                    {item.name}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div style={bottomTextSty}>
                                        政府与公共事业
                                    </div>
                                </div>
                                <div
                                    style={
                                        this.state.activeSolution == 2
                                            ? { ...activeSty, ...bg_2 }
                                            : { ...inActiveSty, ...bg_2 }
                                    }
                                    onMouseEnter={() => {
                                        this.onClickSolutionItem(2);
                                    }}
                                >
                                    <div style={contentBox}>
                                        {staticData_2.map((item) => {
                                            return (
                                                <div
                                                    // style={contentItem}
                                                    onClick={() => {
                                                        window.open(
                                                            `/solution?s=${item.id}`
                                                        );
                                                    }}
                                                    style={{
                                                        ...contentItem,
                                                        color:
                                                            this.state
                                                                .hoverSolution ==
                                                            item.id
                                                                ? "#2FB467"
                                                                : "#fff",
                                                        borderBottom:
                                                            this.state
                                                                .hoverSolution ==
                                                            item.id
                                                                ? "1px solid #2FB467"
                                                                : "1px solid #fff",
                                                    }}
                                                    onMouseEnter={(e) => {
                                                        this.setState({
                                                            hoverSolution:
                                                                item.id,
                                                        });
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        this.setState({
                                                            hoverSolution: null,
                                                        });
                                                    }}
                                                >
                                                    {item.name}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div style={bottomTextSty}>
                                        企业解决方案
                                    </div>
                                </div>
                                <div
                                    style={
                                        this.state.activeSolution == 3
                                            ? { ...activeSty, ...bg_3 }
                                            : { ...inActiveSty, ...bg_3 }
                                    }
                                    onMouseEnter={() => {
                                        this.onClickSolutionItem(3);
                                    }}
                                >
                                    <div style={contentBox}>
                                        {staticData_3.map((item) => {
                                            return (
                                                <div
                                                    // style={contentItem}
                                                    onClick={() => {
                                                        window.open(
                                                            `/solution?s=${item.id}`
                                                        );
                                                    }}
                                                    style={{
                                                        ...contentItem,
                                                        color:
                                                            this.state
                                                                .hoverSolution ==
                                                            item.id
                                                                ? "#2FB467"
                                                                : "#fff",
                                                        borderBottom:
                                                            this.state
                                                                .hoverSolution ==
                                                            item.id
                                                                ? "1px solid #2FB467"
                                                                : "1px solid #fff",
                                                    }}
                                                    onMouseEnter={(e) => {
                                                        this.setState({
                                                            hoverSolution:
                                                                item.id,
                                                        });
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        this.setState({
                                                            hoverSolution: null,
                                                        });
                                                    }}
                                                >
                                                    {item.name}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div style={bottomTextSty}>
                                        云计算与数据中心
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <div style={mobild_box_sty}>
                                    <img
                                        src={require("../assets/s_1.png")}
                                        alt=""
                                        style={{ width: "100%" }}
                                    />
                                    <div
                                        style={{
                                            position: "absolute",
                                            left: "20px",
                                            top: "20px",
                                        }}
                                    >
                                        {staticData_1.map((item) => {
                                            return (
                                                <div
                                                    style={contentItem}
                                                    onClick={() => {
                                                        window.location.href = `/solution?s=${item.id}`;
                                                    }}
                                                >
                                                    {item.name}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div style={mobild_box_sty}>
                                    <img
                                        src={require("../assets/s_2.png")}
                                        alt=""
                                        style={{ width: "100%" }}
                                    />
                                    <div
                                        style={{
                                            position: "absolute",
                                            left: "20px",
                                            top: "20px",
                                        }}
                                    >
                                        {staticData_2.map((item) => {
                                            return (
                                                <div
                                                    style={contentItem}
                                                    onClick={() => {
                                                        window.location.href = `/solution?s=${item.id}`;
                                                    }}
                                                >
                                                    {item.name}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div style={mobild_box_sty}>
                                    <img
                                        src={require("../assets/s_3.png")}
                                        alt=""
                                        style={{ width: "100%" }}
                                    />
                                    <div
                                        style={{
                                            position: "absolute",
                                            left: "20px",
                                            top: "20px",
                                        }}
                                    >
                                        {staticData_3.map((item) => {
                                            return (
                                                <div
                                                    style={contentItem}
                                                    onClick={() => {
                                                        window.location.href = `/solution?s=${item.id}`;
                                                    }}
                                                >
                                                    {item.name}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* <div key={"c_a_1_1_key"}>
                            <Carousel
                                effect="fade"
                                autoplay={true}
                                dots={false}
                                autoplaySpeed={20000}
                                afterChange={this.onChange}
                                ref={(el) => {
                                    this.CarouselRef = el;
                                }}
                            >
                                {this.state.staticData.map((item) => {
                                    return (
                                        <div
                                            className="c_a_1_bg_img"
                                            key={item.id}
                                        >
                                            <div>
                                                <img src={item.imgUrl} alt="" />
                                            </div>
                                            <div className="c_a_1_contenr">
                                                <div className="c_a_1_contenr_row">
                                                    <div className="row_item">
                                                        {item.row1 &&
                                                        item.row1[0]
                                                            ? item.row1[0].title
                                                            : ""}
                                                    </div>
                                                    <div className="row_item">
                                                        {item.row1 &&
                                                        item.row1[1]
                                                            ? item.row1[1].title
                                                            : ""}
                                                    </div>
                                                </div>
                                                <div className="c_a_1_contenr_row">
                                                    <div className="row_item">
                                                        {item.row2 &&
                                                        item.row2[0]
                                                            ? item.row2[0].title
                                                            : ""}
                                                    </div>
                                                    <div className="row_item">
                                                        {item.row2 &&
                                                        item.row2[1]
                                                            ? item.row2[1].title
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </Carousel>
                            <div className="c_a_1_tab">
                                {this.state.staticData.map((item) => {
                                    return (
                                        <div
                                            key={`ca1_tab_${item.code}`}
                                            className={
                                                item.code ==
                                                this.state.activeTab
                                                    ? "tab_item tab_item_active"
                                                    : "tab_item "
                                            }
                                            onClick={(e) => {
                                                this.onClickTabItem(item);
                                            }}
                                        >
                                            {item.title}
                                        </div>
                                    );
                                })}
                            </div>
                        </div> */}
                    </TweenOne>
                </OverPack>
            </div>
        );
    }
}

export default Content1;
