/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import CSCTitle from "../../widget/CSCTitle";
import { MailFilled, EnvironmentFilled } from "@ant-design/icons";
import owRequest from "../../libs/request";

import "./less/antMotionStyle.less";
import "./less/product.less";

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const location = window.location;

const textSty = {
    fontSize: isMobile ? "13px" : "18px",
    // textIndent: isMobile ? "20px" : "40px",
    maxWidth: "980px",
    padding: isMobile ? "15px" : "20px",
    margin: "0 auto",
};

export default class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
            activeTab: 0,
            jobObj: {},
        };
    }

    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
        /* 如果不是 dva 2.0 请删除 end */
        let url = window.location.href;
        console.log("this.props.location", this.props);
        // let jCode = url.split("?jobid=")[1];
        const rCode = this.getUrlParam("rcode");
        const jCode = this.getUrlParam("jobid");

        // const { jobid, rCode } = this.props.location.query;
        this.jCode = jCode;
        this.rCode = rCode;
        console.log("jobid_rCode", this.jCode, this.rCode);
        this.getRecruitDetail();
    }
    getUrlParam(paraName) {
        var url = document.location.toString();
        var arrObj = url.split("?");

        if (arrObj.length > 1) {
            var arrPara = arrObj[1].split("&");
            var arr;

            for (var i = 0; i < arrPara.length; i++) {
                arr = arrPara[i].split("=");

                if (arr != null && arr[0] == paraName) {
                    return arr[1];
                }
            }
            return "";
        } else {
            return "";
        }
    }

    getRecruitDetail() {
        owRequest
            .get(`/office-website/recruit/detail?id=${this.jCode}`)
            .then((res) => {
                // console.log("res", res);
                if (res && res.code == 2000) {
                    let ret = [];
                    if (res.data.rows && res.data.rows.length) {
                        ret = res.data.rows[0];
                    }
                    this.setState({
                        jobObj: ret,
                    });
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    }

    onClickTabItem(activeTab, id) {
        this.setState({ activeTab: activeTab });
        // document.geti
        // console.log(TAG, "BottomView next:", myRef);
        let anchorElement = document.getElementById(id);
        // console.log(
        //     "anchorElement",
        //     anchorElement,
        //     anchorElement.getBoundingClientRect()
        // );
        const yHeight = anchorElement.getBoundingClientRect().y;

        if (activeTab == 0) {
            window.scrollTo(0, 0);
        }

        // console.log("yHeight", yHeight);
        window.scrollTo({
            left: 0, // x坐标
            top: yHeight - 100, // y 坐标
            behavior: "smooth", // 可选值：smooth、instant、auto
        });
        // window.scrollTo(0, yHeight, {behavior: ‘smooth’});
    }

    onClickSearchBtn() {
        window.open("/product/search");
    }

    adaptHeight(Content50DataSource) {
        // return "auto";
        const result = this.state.isMobile
            ? Content50DataSource.block.children.length * 340 + "px"
            : Math.ceil(Content50DataSource.block.children.length / 4) * 340 +
              "px";
        return result;
    }

    render() {
        const labelSty = {
            width: "0.3rem",
            height: "1.2rem",
            display: "inline-block",
            verticalAlign: "middle",
            marginRight: "12px",
            // marginBottom: "5px",
            background: "#2FB467",
        };
        const p1Sty = {
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
        };
        const children = [
            // <Banner
            //     id="Banner1_0"
            //     key="Banner1_0"
            //     dataSource={Banner10DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            <div>
                {/* {!this.state.isMobile ? (
                    <img
                        src={require("../../assets/head_recurite.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                ) : (
                    <img
                        src={require("../../assets/head_recurite.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )} */}
                {this.rCode == 2 ? (
                    <img
                        src={require("../../assets/head_recurite.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                ) : (
                    <img
                        src={require("../../assets/head_recurite_2.png")}
                        alt=""
                        style={{ width: "100%" }}
                    />
                )}
            </div>,

            <div
                id="services_scope"
                key="services_scope"
                style={{
                    height: "auto",
                    width: "100%",
                    background: "#fff",
                    paddingBottom: "60px",
                    paddingTop: "50px",
                }}
            >
                <CSCTitle
                    title={this.state.jobObj.job_title || ""}
                    key="services_scope"
                    isMobile={this.state.isMobile}
                    style={{
                        margin: 0,
                        height: "auto",
                    }}
                    textSty={{
                        fontSize: this.state.isMobile ? "20px" : "28px",
                        fontWeight: "700",
                    }}
                />
                <div
                    style={{
                        ...textSty,
                        textAlign: "center",
                        fontSize: this.state.isMobile ? "1rem" : "1.3rem",
                    }}
                >
                    {/* <div>北京 | 本科及以上 | 1-3年 | 招聘人数：1 | 全职</div> */}
                    {/* <div>北京 | 本科及以上 | {job_work_experience} | 招聘人数：1 | 全职</div> */}
                    <div>
                        {this.state.jobObj.job_work_place}{" "}
                        <span style={{ margin: "0 10px" }}> | </span>
                        {this.state.jobObj.job_academic_requirement}{" "}
                        <span style={{ margin: "0 10px" }}> | </span>
                        {this.state.jobObj.job_work_experience}
                        <span style={{ margin: "0 10px" }}> | </span>
                        <span style={{ margin: "0 10px" }}> </span> 招聘人数：
                        {this.state.jobObj.recruit_number}
                        <span style={{ margin: "0 10px" }}> | </span>
                        {this.state.jobObj.job_type}
                    </div>
                </div>
                <div style={{ ...textSty }}>
                    <div style={p1Sty}>
                        <div style={labelSty}></div>
                        <div
                            style={{
                                fontWeight: 600,
                                fontSize: "18px",
                            }}
                        >
                            岗位职责
                        </div>
                    </div>
                    <div style={{ lineHeight: "44px", whiteSpace: "pre-wrap" }}>
                        {this.state.jobObj.job_responsibilities
                            ? this.state.jobObj.job_responsibilities.replace(
                                  /\\n/g,
                                  "<br/>"
                              )
                            : ""}
                    </div>
                </div>
                <div style={{ ...textSty }}>
                    <div style={p1Sty}>
                        <div style={labelSty}></div>
                        <div
                            style={{
                                fontWeight: 600,
                                fontSize: "18px",
                            }}
                        >
                            任职要求
                        </div>
                    </div>
                    <div style={{ lineHeight: "44px", whiteSpace: "pre-wrap" }}>
                        {this.state.jobObj.job_requirements
                            ? this.state.jobObj.job_requirements.replace(
                                  /\\n/g,
                                  "<br/>"
                              )
                            : ""}
                    </div>
                </div>
                <div style={{ ...textSty }}>
                    <div style={p1Sty}>
                        <div style={labelSty}></div>
                        <div
                            style={{
                                fontWeight: 600,
                                fontSize: "18px",
                            }}
                        >
                            工作地点
                        </div>
                    </div>
                    <div>
                        <EnvironmentFilled style={{ marginLeft: "10px" }} />
                        <span style={{ marginLeft: "10px" }}>
                            {" "}
                            {this.state.jobObj.job_work_place || ""}
                        </span>
                    </div>
                </div>
                <div style={{ ...textSty }}>
                    <div style={p1Sty}>
                        <div style={labelSty}></div>
                        <div
                            style={{
                                fontWeight: 600,
                                fontSize: "18px",
                            }}
                        >
                            投递邮箱
                        </div>
                    </div>
                    <div>
                        <MailFilled style={{ marginLeft: "10px" }} />
                        <span style={{ marginLeft: "10px" }}>
                            {this.state.jobObj.deliver_email || ""}
                        </span>
                    </div>
                </div>
            </div>,
            // <div
            //     id="services_type"
            //     key="services_type"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="服务种类"
            //         key="services_type"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getServiceTypes()},
            // </div>,
            // <div
            //     id="services_stander"
            //     key="services_stander"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="服务标准"
            //         key="services_stander"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getServicesStander()},
            // </div>,

            // <div
            //     id="other_tips"
            //     key="other_tips"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="其它说明"
            //         key="other_tips"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getOtherTips()}
            // </div>,
        ];
        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {this.state.show && children}
                {/* 如果不是 dva 2.0 替换成 {children} end */}
            </div>
        );
    }
}

const style = React.cree;
