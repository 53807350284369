export default [
    {
        value: '1',
        label: 'ISV',
    },
    {
        value: '2',
        label: '互联网',
    },
    {
        value: '3',
        label: '教育',
    },
    {
        value: '4',
        label: '军队军工',
    },
    {
        value: '5',
        label: '能源',
    },
    {
        value: '6',
        label: '运营商',
    },
    {
        value: '7',
        label: '金融',
    },
    {
        value: '8',
        label: '医疗卫生',
    },
    {
        value: '9',
        label: '政府公共事业',
    },
    {
        value: '10',
        label: '公检司法',
    },
    {
        value: '11',
        label: '交通运输',
    },
    {
        value: '12',
        label: '科研单位',
    },
    {
        value: '13',
        label: '制造',
    },
    {
        value: '14',
        label: '其他',
    },
]
