/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import CSCTitle from "../../widget/CSCTitle";
import CSCBackTop from "../../widget/CSCBackTop";

import { BackTop } from "antd";

import "./less/antMotionStyle.less";
// import "./less/product.less";

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const location = window.location;

const textSty = {
    fontSize: isMobile ? "13px" : "18px",
    // textIndent: isMobile ? "20px" : "40px",
    maxWidth: "980px",
    padding: isMobile ? "15px" : "20px",
    margin: "0 auto",
};

export default class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
            activeTab: 0,
        };
    }

    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
        /* 如果不是 dva 2.0 请删除 end */
    }

    // 服务范围
    getServiceScope() {
        return (
            <div style={textSty}>
                本服务标准针对以下几种情况提出：
                <br />
                1、
                产品超出保修期，客户未购买延保服务的产品，需要超云提供现场服务的情况；
                <br />
                2、
                产品在保修期内，但服务内容超出《超云产品标准保修政策》所规定的服务范围的情况。
            </div>
        );
    }

    // 服务种类
    getServiceTypes() {
        return (
            <div style={textSty}>
                1、 硬件安装
                <br />
                2、 硬件维修 <br />
                3、 硬件配置扩容 <br />
                4、 操作系统的安装与调试 <br />
                5、 磁盘阵列的重建、修改、创建等服务 <br /> 6、 Call Center服务
                <br />
                7、 项目服务：包括驻场、技术服务日、布线、除尘、巡检等
                本服务标准针对以下几种情况提出：
                <br />
            </div>
        );
    }

    // 特别说明
    getOtherTips() {
        return (
            <div style={textSty}>
                1、
                经超云有偿服务更换或增加的产品部件自有偿服务完成之日起享有12个月的保修期。
                <br />
                2、
                增值服务收费标准请拨打超云服务中心热线服务支持电话：4006330360。
            </div>
        );
    }

    getTitleDes() {
        return (
            <div style={{ ...textSty, textIndent: isMobile ? "20px" : "40px" }}>
                超云数字技术集团有限公司（下简称“超云”）通过统一的管理、统一的培训、统一的服务标准、统一的规范和统一的考核，使得客户可以享受到周到、完美、技术专业、形象亲和的超云售后服务。
            </div>
        );
    }

    getServicesStander() {
        return <div style={textSty}>服务标准</div>;
    }

    onClickTabItem(activeTab, id) {
        this.setState({ activeTab: activeTab });
        // document.geti
        // console.log(TAG, "BottomView next:", myRef);
        let anchorElement = document.getElementById(id);
        // console.log(
        //     "anchorElement",
        //     anchorElement,
        //     anchorElement.getBoundingClientRect()
        // );
        const yHeight = anchorElement.getBoundingClientRect().y;

        if (activeTab == 0) {
            window.scrollTo(0, 0);
        }

        // console.log("yHeight", yHeight);
        window.scrollTo({
            left: 0, // x坐标
            top: yHeight - 100, // y 坐标
            behavior: "smooth", // 可选值：smooth、instant、auto
        });
        // window.scrollTo(0, yHeight, {behavior: ‘smooth’});
    }

    onClickSearchBtn() {
        window.open("/product/search");
    }

    adaptHeight(Content50DataSource) {
        // return "auto";
        const result = this.state.isMobile
            ? Content50DataSource.block.children.length * 340 + "px"
            : Math.ceil(Content50DataSource.block.children.length / 4) * 340 +
              "px";
        return result;
    }

    render() {
        const children = [
            // <Banner
            //     id="Banner1_0"
            //     key="Banner1_0"
            //     dataSource={Banner10DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <img
            //     src="https://img2.baidu.com/it/u=3639955691,11916907&fm=253&fmt=auto&app=138&f=JPEG?w=658&h=171"
            //     alt=""
            //     style={{ width: "100%" }}
            // />,
            <div style={{ position: "relative" }}>
                <img
                    src={require("../../assets/techsuupport_bg_2.png")}
                    alt=""
                    style={{ width: "100%" }}
                />

                {/* <div
                    style={{
                        position: "absolute",
                        // top: this.state.isMobile ? "3rem" : "16rem",
                        // left: this.state.isMobile ? "2rem" : "16rem",
                        top: this.state.isMobile ? "3rem" : "40%",
                        left: this.state.isMobile ? "1rem" : "15%",
                        color: "#fff",
                        fontSize: this.state.isMobile ? "1.4rem" : "2.4vw",
                        zIndex: 100,
                        fontWeight: 700,
                    }}
                >
                    技术支持
                </div> */}
            </div>,
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "0 auto",
                }}
            >
                <CSCTitle
                    title="增值服务"
                    key="warranty_period"
                    isMobile={this.state.isMobile}
                    style={{
                        margin: 0,
                        height: "auto",
                        marginTop: "60px",
                        marginBottom: "40px",
                    }}
                    textSty={{
                        fontSize: this.state.isMobile ? "20px" : "28px",
                        fontWeight: "700",
                    }}
                />
            </div>,
            <div
                style={{
                    maxWidth: "1000px",
                    // display: "flex",
                    // alignItems: "center",
                    // justifyContent: "center",
                    // margin: "0 auto",
                    // marginTop: "4reme",
                    // marginBottom: "4reme",
                    // paddingLeft: "5px",
                    // paddingRight: "5px",
                    // paddingBottom: "7rem",
                    padding: "20px",
                    // background: "#f6f6f6",
                    position: "relative",
                    fontSize: "16px",
                    margin: "0 auto",
                }}
                dangerouslySetInnerHTML={{
                    __html: `
                    <p style="text-indent:24.0000pt;">
                    超云数字技术集团有限公司（下简称“超云”）通过统一的管理、统一的培训、统一的服务标准、统一的规范和统一的考核，使得客户可以享受到周到、完美、技术专业、形象亲和的超云售后服务。
                </p>
                <p class="NewStyle17" style="margin-left:36.0000pt;text-indent:-36.0000pt;margin-top:15pt;margin-bottom: 6pt;">
                    一、&nbsp;增值服务范围
                </p>
                <p style="text-indent:21pt;">
                    本服务标准针对以下几种情况提出：&nbsp;
                </p>
                <p class="NewStyle17" style="margin-left:42.55pt;text-indent:-21.55pt;">
                    1、&nbsp;产品超出保修期，客户未购买延保服务的产品，需要超云提供现场服务的情况；
                </p>
                <p class="NewStyle17" style="margin-left:42.55pt;text-indent:-21.55pt;">
                    2、&nbsp;产品在保修期内，但服务内容超出《超云产品标准保修政策》所规定的服务范围的情况。
                </p>
                <p class="NewStyle17" style="margin-left:36.0000pt;text-indent:-36.0000pt;margin-top:15pt;margin-bottom: 6pt;">
                    二、&nbsp;增值服务种类
                </p>
                <p style="text-indent:21pt;">
                    超云可向用户提供的增值服务种类包括：
                </p>
                <p class="NewStyle17" style="margin-left:39pt;text-indent:-18pt;"
                    1、&nbsp;硬件安装
                </p>
                <p class="NewStyle17" style="margin-left:39pt;text-indent:-18pt;">
                    2、&nbsp;硬件维修
                </p>
                <p class="NewStyle17" style="margin-left:39pt;text-indent:-18pt;">
                    3、&nbsp;硬件配置扩容
                </p>
                <p class="NewStyle17" style="margin-left:39pt;text-indent:-18pt;">
                    4、&nbsp;操作系统的安装与调试
                </p>
                <p class="NewStyle17" style="margin-left:39pt;text-indent:-18pt;">
                    5、&nbsp;磁盘阵列的重建、修改、创建等服务
                </p>
                        <p class="NewStyle17" style="margin-left:39pt;text-indent:-18pt;">
                            6、&nbsp;Call Center服务
                        </p>
                        <p class="NewStyle17" style="margin-left:39pt;text-indent:-18pt;">
                            7、&nbsp;项目服务：包括驻场、技术服务日、布线、除尘、巡检等
                        </p>
                <p class="NewStyle17" style="margin-left:36.0000pt;text-indent:-36.0000pt;margin-top:20pt;margin-bottom: 6pt;">
                    三、&nbsp;具体服务标准
                </p>
                <table class="MsoNormalTable" style="width:100%; text-align: center;">
                    <tbody style="background:#fff;">
                        <tr>
                            <td width="120" valign="top" >
                                <p class="NewStyle15" style="text-indent:0.0000pt;text-align:center;">
                                    <span style="font-weight:bold;font-size:12pt;color:#00AA71;">服务类型</span>
                                </p>
                            </td>
                            <td width="900" valign="top" colspan="2">
                                <p class="NewStyle15" style="text-indent:0.0000pt;text-align:center;">
                                    <span style="font-weight:bold;font-size:12pt; color:#00AA71;">服务内容</span>
                                </p>
                            </td>
                            <td width="380" valign="top">
                                <p class="NewStyle15" style="text-indent:0.0000pt;text-align:center;">
                                    <span style="font-weight:bold;font-size:12pt;color:#00AA71;">说明</span>
                                </p>
                            </td>
                            <td width="150" valign="top">
                                <p class="NewStyle15" style="text-indent:0.0000pt;text-align:center;">
                                    <span style="font-weight:bold;font-size:12pt;color:#00AA71;">现场服务级别</span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top" rowspan="4">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">安装服务</span>
                                </p>
                            </td>
                            <td valign="top" rowspan="2">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">设备安装</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">服务器/存储</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">服务器、存储设备的安装、上架、绑线</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">5*8*NBD</span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">附件（扩容）</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">用户新购买配件，如硬盘、内存、电源、CPU等安装</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">5*8*NBD</span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top" rowspan="2">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">OS/软件</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">BIOS/FW升级/驱动程序优化</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">对故障设备、或有计划的批量进行硬件FW、BIOS升级，以解决现有问题或预防性措施</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">5*8*NBD</span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">MS Windows/linux/虚拟化系统</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">按用户要求，对设备进行现场的OS安装。用户用提供正版介质</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">5*8*NBD</span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top" rowspan="3">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">维修服务</span>
                                </p>
                            </td>
                            <td valign="top" rowspan="3">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">服务器、存储</span>
                                </p>
                            </td>
                            <td valign="top" rowspan="3">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">维护</span>
                                </p>
                            </td>
                            <td valign="top" rowspan="3">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">现场进行对服务器、存储设备的检测、排障、备件更换等维修工作</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">5*8*NBD</span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">5*8*4</span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">7*24*4</span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">数据迁移</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">数据迁移</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">数据迁移</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">系统、应用、数据库等</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">7*24</span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">Call Center</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">客服中心Case受理</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">call center支持</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">当服务器操作系统突发故障时，导致客户无法远程控制或操作系统异常的，第一时间受理客户请求，并转接至坐席工程师处理故障诊断</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">7*24</span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top" rowspan="6">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">项目服务</span>
                                </p>
                            </td>
                            <td valign="top" rowspan="2">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">驻场</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">服务器/存储IDC驻场</span>
                                </p>
                            </td>
                            <td valign="top" style="text-align:left;">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">
                                        1.响应客户工作请求，执行具体操作
                                    </span>
                                </p>
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">
                                        2.问题升级及情况汇报
                                    </span>
                                </p>
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">
                                        3.编写当日工作记录
                                    </span>
                                </p>
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">
                                        4.完成上级指派的其他工作
                                    </span>
                                </p>
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">
                                        5.按要求日常机房巡检
                                    </span>
                                </p>
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">
                                        6.及时响应用户需求，故障设备的检测，处理，维修
                                    </span>
                                </p>
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">
                                        7. 周报，月报的准时提交
                                    </span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">5*8</span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">IDC资产管理</span>
                                </p>
                            </td>
                            <td valign="top" style="text-align:left;">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">
                                        1.资产出入库登记
                                    </span>
                                </p>
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">
                                        2.配合操作 岗完成日常工单，负责对机房操作所需设备及零件的 准备及出库 工作；
                                    </span>
                                </p>
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">
                                        3.负责对下线服务器，网络设备零件配的资产核查并入库工作；
                                    </span>
                                </p>
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">
                                        4.按照流程及规范要求完成资产岗工单操作 设备上架组装记录序列号信息
                                    </span>
                                </p>
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">
                                        5.进行定期资产盘点，对现场资产的安全负责，按时提供资产报告
                                    </span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">5*8</span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">技术服务日</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">现场人员支持</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">
                                        为满足用户的特殊需求，提供1人/天的现场值守服务
                                    </span>
                                </p>                        
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">5*8</span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">布线</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">按实际需求、规模按项目报价</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">
                                        -
                                    </span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">-</span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">除尘</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">服务器、存储</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">
                                        按客户工作需求，执行具体设备除尘
                                    </span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">-</span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">巡检</span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">服务器、存储</span>
                                </p>
                            </td>
                            <td valign="top" style="text-align:left;">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">
                                        1.按客户工作需求，执行具体设备巡检
                                    </span>
                                </p>
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">
                                        2.问题升级及情况汇报
                                    </span>
                                </p>
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">
                                        3.编写巡检工作记录
                                    </span>
                                </p>
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">
                                        4.提交巡检记录报告
                                    </span>
                                </p>
                            </td>
                            <td valign="top">
                                <p class="NewStyle15" style="text-indent:0pt;">
                                    <span style="font-size:12pt;">-</span>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p class="NewStyle17" style="margin-left:36.0000pt;text-indent:-36.0000pt;margin-top:40pt;margin-bottom: 6pt;">
                    四、&nbsp;其他说明
                </p>
                <p class="NewStyle17" style="text-indent:21pt;">
                    1、&nbsp;经超云有偿服务更换或增加的产品部件自有偿服务完成之日起享有12个月的保修期。
                </p>
                <p class="NewStyle17" style="text-indent:21pt; margin-bottom:60pt;">
                    2、&nbsp;增值服务收费标准请拨打超云服务中心热线服务支持电话：4006330360。
                </p>
                `,
                }}
            ></div>,
        ];
        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
                style={{ background:"rgba(248, 247, 247)"}}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {this.state.show && children}

                <CSCBackTop isMobile={this.state.isMobile} key="value_add" />
                {/* 如果不是 dva 2.0 替换成 {children} end */}
            </div>
        );
    }
}

const style = React.cree;
