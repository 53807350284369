import React from "react";

export const Banner10DataSource = {
    wrapper: { className: "banner1" },
    BannerAnim: {
        children: [
            {
                name: "elem0",
                BannerElement: { className: "banner-user-elem" },
                textWrapper: { className: "banner1-text-wrapper" },
                bg: { className: "bg bg0" },
                title: {
                    className: "banner1-title",
                    children:
                        "https://img0.baidu.com/it/u=2992887792,3732015758&fm=253&fmt=auto&app=138&f=JPEG?w=960&h=500",
                },
                content: {
                    className: "banner1-content",
                    children: "一个高效的页面动画解决方案",
                },
                button: { className: "banner1-button", children: "Learn More" },
            },

            // {
            //     name: "elem1",
            //     BannerElement: { className: "banner-user-elem" },
            //     textWrapper: { className: "banner1-text-wrapper" },
            //     bg: { className: "bg bg1" },
            //     title: {
            //         className: "banner1-title",
            //         children:
            //             "https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png",
            //     },
            //     content: {
            //         className: "banner1-content",
            //         children: "一个高效的页面动画解决方案",
            //     },
            //     button: { className: "banner1-button", children: "Learn More" },
            // },
            // {
            //     name: "elem2",
            //     BannerElement: { className: "banner-user-elem" },
            //     textWrapper: { className: "banner1-text-wrapper" },
            //     bg: { className: "bg bg1" },
            //     title: {
            //         className: "banner1-title",
            //         children:
            //             "https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png",
            //     },
            //     content: {
            //         className: "banner1-content",
            //         children: "一个高效的页面动画解决方案",
            //     },
            //     button: { className: "banner1-button", children: "Learn More" },
            // },
        ],
    },
};
export const Feature20DataSource = {
    wrapper: { className: "home-page-wrapper content2-wrapper" },
    OverPack: { className: "home-page content2", playScale: 0.3 },
    imgWrapper: { className: "content2-img", md: 10, xs: 24 },
    img: {
        children:
            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
    },
    textWrapper: { className: "content2-text", md: 14, xs: 24 },
    title: { className: "content2-title", children: "R8428 G12" },
    content: {
        className: "content2-content",
        children:
            "超云 R8424 G12 是基于英特尔®第三代 至强®可扩展处理器系列处理器设计的一款高 性能 GPU 服务器，该产品以模块化设计为基础，CPU+GPU 异构计算架构，协调处理单元间高 速 IO 交换技术，具备高性能，高可靠和易维护特点，主要部署应用气候和天气建模、CFD、 CAE、计算物理、生物化学模拟、数据分析、卫星成像和计算金融学等科学计算。",
    },
};

export const Content50DataSource = {
    wrapper: { className: "home-page-wrapper content5-wrapper" },
    page: { className: "home-page content5" },
    OverPack: { playScale: 0.3, className: "" },
    titleWrapper: {
        className: "title-wrapper",
        children: [
            { name: "title", children: "通用服务器", className: "title-h1" },
            {
                name: "content",
                className: "title-content",
                children: "",
            },
        ],
    },
    block: {
        className: "content5-img-wrapper",
        gutter: 16,
        children: [
            {
                name: "block0",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block1",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block2",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block3",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block4",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children: "超云R5210 G12 111111",
                    },
                },
            },
            {
                name: "block5",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block6",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block7",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block8",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
            {
                name: "block9",
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children:
                            "https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg",
                    },
                    content: { children: "R8428 G11" },
                    desc: {
                        children:
                            "超云R5210 G12 服务器是第三代英特尔®至强®可扩展处理器研发的2U通用型机架式11111111111",
                    },
                },
            },
        ],
    },
};
