import React from "react";
import { Row, Col } from "antd";
import { TweenOneGroup } from "rc-tween-one";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import TweenOne from "rc-tween-one";
import config from "../libs/config";
import { getChildrenToRender } from "./utils";

class Content5 extends React.PureComponent {
    jumpToDetail(item) {
        // console.log("jumpToDetail", item);
        window.open(`/product/detail?productid=${item.name}`);
    }

    getChildrenToRender = (data) =>
        data.map((item) => {
            const { ...props } = this.props;
            return (
                <Col key={item.name} {...item}>
                    <a
                        {...item.children.wrapper}
                        style={{ height: "17rem" }}
                        onClick={() => {
                            this.jumpToDetail(item);
                        }}
                    >
                        <span {...item.children.img}>
                            <img
                                src={item.children.img.children}
                                height="100%"
                                alt="img"
                            />
                            {/* <img
                                src={require("../assets/4.jpeg")}
                                height="100%"
                                alt="img"
                            /> */}
                        </span>
                        {item.children.content ? (
                            <p
                                style={{
                                    textAlign: "center",
                                    fontSize: "22px",
                                    fontWeight: 600,
                                    color: "#2FB467",
                                }}
                                {...item.children.content}
                            >
                                {item.children.content.children}
                            </p>
                        ) : null}
                        {item.children.desc ? (
                            <p
                                className="desc_text"
                                style={{
                                    textAlign: "start",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                    paddingBottom: "10px",
                                    textIndent: "20px",
                                    color: "#999",
                                }}
                                {...item.children.desc}
                            >
                                {/* {props.isMobile
                                    ? item.children.desc.children
                                    : item.children.desc.children &&
                                      item.children.desc.children.length > 40
                                    ? item.children.desc.children.slice(0, 40) +
                                      "..."
                                    : item.children.desc.children} */}
                                {item.children.desc.children || ""}
                            </p>
                        ) : null}
                    </a>
                </Col>
            );
        });

    render() {
        const { productList = [], ...props } = this.props;
        const { dataSource } = props;
        delete props.dataSource;
        delete props.isMobile;
        let ret = [];
        for (let item of productList) {
            // console.log("item", item);
            ret.push({
                name: item.id,
                className: "block",
                md: 6,
                xs: 24,
                children: {
                    wrapper: { className: "content5-block-content" },
                    img: {
                        children: config.pic_url + item.product_image_url,
                    },
                    content: { children: item.product_name },
                    desc: {
                        children: item.product_desc,
                    },
                },
            });
        }
        // const childrenToRender = this.getChildrenToRender(
        //     dataSource.block.children
        // );
        const childrenToRender = this.getChildrenToRender(ret);

        return (
            <div
                {...props}
                {...dataSource.wrapper}
                style={{ paddingTop: "0px", height: "auto" }}
            >
                <div
                    {...dataSource.page}
                    style={{ paddingTop: "70px", paddingBottom: "70px" }}
                >
                    {/* <div key="title" {...dataSource.titleWrapper}>
                        {dataSource.titleWrapper.children.map(
                            getChildrenToRender
                        )}
                    </div> */}
                    <OverPack
                        className={`content-template ${props.className}`}
                        {...dataSource.OverPack}
                    >
                        <TweenOne
                            forcedReplay={false}
                            component={Row}
                            key="ul"
                            enter={{
                                y: "+=30",
                                opacity: 0,
                                type: "from",
                                ease: "easeInOutQuad",
                            }}
                            leave={{
                                y: "+=30",
                                opacity: 0,
                                ease: "easeInOutQuad",
                            }}
                            {...dataSource.block}
                        >
                            {childrenToRender}
                        </TweenOne>
                    </OverPack>
                </div>
            </div>
        );
    }
}

export default Content5;
