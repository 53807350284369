/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { Row, Col, Anchor, BackTop } from "antd";
import { enquireScreen } from "enquire-js";
import CSCTitle from "../../widget/CSCTitle";
import CSCBackTop from "../../widget/CSCBackTop";

import "./less/antMotionStyle.less";
import "./less/product.less";

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const location = window.location;

const textSty = {
    fontSize: isMobile ? "13px" : "18px",
    textIndent: isMobile ? "20px" : "35px",
    maxWidth: "980px",
    padding: isMobile ? "15px" : "20px",
    margin: "0 auto",
};

export default class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
            activeTab: 0,
        };
    }

    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
        /* 如果不是 dva 2.0 请删除 end */
    }

    getServiceScope(){
     return (
            <div
                style={textSty}
                dangerouslySetInnerHTML={{
                    __html: `<div class="myTab_block NeiRong" style="border:0 none;padding-top:0;">
    <p>
        超云PSIRT负责接受、处理和公开披露超云产品和解决方案相关的安全漏洞，同时超云PSIRT是公司对漏洞信息进行披露的唯一出口。超云鼓励漏洞研究人员、行业组织、政府机构和供应商主动将与超云产品相关的安全漏洞报告给超云PSIRT。
    </p>
    <br>
    <p>
        PSIRT电子邮箱：
        <a
            style="color:blue;"
            href=" "
        >
            PSIRT@chinasupercloud.com
        </a>
    </p>
    <br>
    <p>安全公告</p>
    <br>
    <p style="font-size: 24px;text-align: center;">
        <b>Intel Platform Update (IPU) Update 2023.1, February 2023</b>
    </p>
    <br>
    <p>
        <b>More Information:</b>
    </p>
    <p>
        <b>Security Advisories That Affect BIOS:</b>
    </p>
    <p>
        INTEL-SA-00700 – 2021.3 IPU – Intel<sup>&reg;</sup> Atom<sup>&reg;</sup> and Intel<sup>&reg;</sup> Xeon<sup>&reg;</sup> Scalable Processors Advisory
    </p>
    <p>
        <b>Summary:</b> A potential security vulnerability in some Intel<sup>&reg;</sup> Atom<sup>&reg;</sup> and Intel<sup>&reg;</sup> Xeon<sup>&reg;</sup> Scalable Processors may allow escalation of privilege. Intel is releasing firmware updates to mitigate this potential vulnerability.
    </p>
    <p>
        <b>Severity:</b> High
    </p>
    <p>
        INTEL-SA-00717 – 2023.1 IPU – BIOS Advisory
    </p>
    <p>
        <b>Summary:</b> Potential security vulnerabilities in the BIOS firmware and Intel<sup>&reg;</sup> Trusted Execution Technology (TXT) Secure Initialization (SINIT) Authenticated Code Modules (ACM) for some Intel<sup>&reg;</sup> Processors may allow escalation of privilege.
    </p>
    <p>Intel is releasing BIOS updates to mitigate these potential vulnerabilities.</p>
    <p>
        <b>Severity:</b> High
    </p>
    <p>
        INTEL-SA-00718 – 2023.1 IPU – Intel<sup>&reg;</sup> Chipset Firmware Advisory
    </p>
    <p>
        <b>Summary:</b> Potential security vulnerabilities in the Intel<sup>&reg;</sup> Server Platform Services (SPS) firmware may allow escalation of privilege. Intel is releasing firmware updates to mitigate this potential vulnerability.
    </p>
    <p>
        <b>Severity:</b> High
    </p>
    <p>
        INTEL-SA-00730 – 2023.1 IPU – 3rd Gen Intel<sup>&reg;</sup> Xeon<sup>&reg;</sup> Scalable Processors Advisory
    </p>
    <p>
        <b>Summary:</b> A potential security vulnerability in some 3rd Generation Intel<sup>&reg;</sup> Xeon<sup>&reg;</sup> Scalable Processors may allow information disclosure. Intel is releasing microcode updates to mitigate this potential vulnerability.
    </p>
    <p>
        <b>Severity:</b> Medium
    </p>
    <p>
        INTEL-SA-00738 – 2023.1 IPU – Intel<sup>&reg;</sup> Xeon<sup>&reg;</sup> Processor Advisory
    </p>
    <p>
        <b>Summary:</b> A potential security vulnerability in some Intel<sup>&reg;</sup> Xeon<sup>&reg;</sup> Processors with Intel<sup>&reg;</sup> Software Guard Extensions (SGX) may allow escalation of privilege. Intel is releasing firmware updates to mitigate this potential vulnerability.
    </p>
    <p>
        <b>Severity:</b> High
    </p>
    <p>
        INTEL-SA-00767 – 2023.1 IPU – Intel<sup>&reg;</sup> Processor Advisory
    </p>
    <p>
        <b>Summary:</b> A potential security vulnerability in some Intel<sup>&reg;</sup> Processors with Intel<sup>&reg;</sup> Software Guard Extensions (SGX) may allow information disclosure. Intel is releasing firmware updates to address this potential vulnerability.
    </p>
    <p>
        <b>Severity:</b> Medium
    </p>
    <br>
    <p>
        <b>Additional Resources:</b>
    </p>
    <p>
        <b>Security Advisories That Affect BIOS:</b>
    </p>
    <p>Intel Security Advisory Intel-SA-00700</p>
    <p>CVE-2022-21216</p>
    <p>Intel Security Advisory Intel-SA-00717</p>
    <p>CVE-2022-30539, CVE-2022-32231, CVE-2022-26837, CVE-2022-30704, CVE-2021-0187, CVE-2022-26343</p>
    <p>Intel Security Advisory Intel-SA-00718</p>
    <p>CVE-2022-36348</p>
    <p>Intel Security Advisory Intel-SA-00730</p>
    <p>CVE-2022-33972</p>
    <p>Intel Security Advisory Intel-SA-00738</p>
    <p>CVE-2022-33196</p>
    <p>Intel Security Advisory Intel-SA-00767</p>
    <p>CVE-2022-38090</p>
    <br>
    <p style="font-size: 24px;text-align: center;margin-top:60px;">
        <b>Intel Platform Update (IPU) Update 2022.3, November 2022</b>
    </p>
    <br>
    <p>
        <b>More Information:</b>
    </p>
    <p>
        <b>Security Advisories That Affect BIOS:</b>
    </p>
    <p>
        INTEL-SA-00610 - 2022.3 IPU – Intel<sup>&reg;</sup> Chipset Firmware Advisory
    </p>
    <p>
        <b>Summary:</b>  Potential security vulnerabilities in some Intel<sup>&reg;</sup> Chipset Firmware in Intel<sup>&reg;</sup> Converged Security and Manageability Engine (CSME), Intel<sup>&reg;</sup> Active Management Technology (AMT), Intel<sup>&reg;</sup> Standard Manageability and Intel<sup>&reg;</sup> Server Platform Services (SPS) may allow escalation of privilege or denial of service. Intel is releasing firmware updates to mitigate these potential vulnerabilities.
    </p>
    <p>
        <b>Severity:</b> High
    </p>
    <p>
        INTEL-SA-00688 - 2022.3 IPU – BIOS Advisory
    </p>
    <p>
        <b>Summary:</b> Potential security vulnerabilities in the BIOS firmware for some Intel<sup>&reg;</sup> Processors may allow escalation of privilege. Intel is releasing firmware updates to mitigate these potential vulnerabilities.
    </p>
    <p>
        <b>Severity:</b> High
    </p>
    <br>
    <p>
        <b>Additional Resources:</b>
    </p>
    <p>
        <b>Security Advisories That Affect BIOS:</b>
    </p>
    <p>Intel Security Advisory Intel-SA-00610</p>
    <p>CVE-2022-26845, CVE-2022-27497, CVE-2022-29893, CVE-2021-33159, CVE-2022-29466, CVE-2022-29515</p>
    <p>Intel Security Advisory Intel-SA-00688</p>
    <p>CVE-2022-26006,CVE-2022-21198</p>
    <br>
    <!--
    <p style="font-size: 24px;text-align: center;">
        <b>Intel Platform Update (IPU) 2019.2, November 2019</b>
    </p>
    <br>
    <p>
        <b>More Information:</b>
    </p>
    <p>
        <b>Security Advisories That Affect BIOS:</b>
    </p>
    <p>
        Intel-SA-00164 – Intel<sup>&reg;</sup> Trusted Execution
        Technology 2019.2 IPU Advisory
    </p>
    <p>
        <b>Summary:</b> Multiple potential security
        vulnerabilities that may allow escalation of privilege,
        information disclosure, and/or denial of service.
    </p>
    <p>
        <b>Severity:</b> MEDIUM
    </p>
    <p>
        Intel-SA-00219 – Intel<sup>&reg;</sup> SGX 2019.2 with
        Intel<sup>&reg;</sup> Processor Graphics IPU Update
        Advisory
    </p>
    <p>
        <b>Summary:</b> Potential security vulnerability in
        access control of protected memory for Intel
        <sup>&reg;</sup> SGX processors with Intel
        <sup>&reg;</sup> Processor Graphics may allow for
        information disclosure
    </p>
    <p>
        <b>Severity:</b> MEDIUM
    </p>
    <p>
        Intel-SA-00220 – Intel<sup>&reg;</sup> SGX and Intel
        <sup>&reg;</sup> TXT Advisory
    </p>
    <p>
        <b>Summary:</b> Potential security vulnerabilities in
        6th Generation Intel<sup>&reg;</sup> Core Processors
        and greater may allow information disclosure.
    </p>
    <p>
        <b>Severity:</b> HIGH
    </p>
    <p>
        Intel-SA-00240 – Intel CPU Local Privilege Escalation
        Advisory
    </p>
    <p>
        <b>Summary:</b> Potential security vulnerabilities in
        Intel<sup>&reg;</sup> Core Processors and Intel
        <sup>&reg;</sup> Xeon<sup>&reg;</sup> Processors may
        allow escalation of privilege, denial of service, or
        information disclosure.
    </p>
    <p>
        <b>Severity:</b> HIGH
    </p>
    <p>
        Intel-SA-00241 – Intel<sup>&reg;</sup> CSME, Server
        Platform Services, Trusted Execution Engine, Intel
        <sup>&reg;</sup> Active Management Technology and
        Dynamic Application Loader 2019.2 IPU Advisory
    </p>
    <p>
        <b>Summary:</b> Potential security vulnerabilities in
        Intel<sup>&reg;</sup> Converged Security and
        Manageability Engine (Intel<sup>&reg;</sup> CSME),
        Server Platform Services, Intel<sup>&reg;</sup> Trusted
        Execution Engine (Intel<sup>&reg;</sup> TXE), Intel
        <sup>&reg;</sup> Active Management Technology (Intel
        <sup>&reg;</sup> AMT) and Intel<sup>&reg;</sup> Dynamic
        Application Loader (Intel<sup>&reg;</sup> DAL) may allow
        escalation of privilege, denial of service or
        information disclosure.
    </p>
    <p>
        <b>Severity:</b> CRITICAL
    </p>
    <p>
        Intel-SA-00254 – Intel<sup>&reg;</sup> System Management
        Mode 2019.2 IPU Advisory
    </p>
    <p>
        <b>Summary:</b> potential security vulnerability in
        Intel<sup>&reg;</sup> System Management Mode with Intel
        <sup>&reg;</sup> Processor Graphics may allow for
        information disclosure.
    </p>
    <p>
        <b>Severity:</b> MEDIUM
    </p>
    <p>
        Intel-SA-00260 – Intel<sup>&reg;</sup> Processor
        Graphics 2019.2 IPU Advisory
    </p>
    <p>
        <b>Summary:</b> Potential security vulnerability in
        Intel<sup>&reg;</sup> Processor Graphics may allow
        denial of service.
    </p>
    <p>
        <b>Severity:</b> MEDIUM
    </p>
    <p>
        Intel-SA-00270 – TSX Transaction Asynchronous Abort
        Advisory
    </p>
    <p>
        <b>Summary:</b> Potential security vulnerability in
        Intel<sup>&reg;</sup> CPUs may allow information
        disclosure.
    </p>
    <p>
        <b>Severity:</b> MEDIUM
    </p>
    <p>
        Intel-SA-00271 – Voltage Modulation Technical Advisory
    </p>
    <p>
        <b>Summary:</b> Potential security vulnerability in some
        Intel<sup>&reg;</sup> Xeon<sup>&reg;</sup> Scalable
        Processors CPUs may allow denial of service.
    </p>
    <p>
        <b>Severity:</b> MEDIUM
    </p>
    <p>Intel-SA-00280 – BIOS 2019.2 IPU Advisory</p>
    <p>
        <b>Summary:</b> Potential security vulnerabilities in
        Intel firmware may allow escalation of privilege, denial
        of service, information disclosure.
    </p>
    <p>
        <b>Severity:</b> HIGH
    </p>
    <p>
        <b>Security Advisories That Don’t Affect BIOS:</b>
    </p>
    <p>Intel-SA-00210 – Instruction Fetch Security Advisory</p>
    <p>
        Intel-SA-00242 – Intel<sup>&reg;</sup> Graphics Driver
        for Windows* 2019.2 IPU Advisory
    </p>
    <p>
        Intel-SA-00255 – Intel<sup>&reg;</sup> Ethernet 700
        Series Controllers Advisory
    </p>
    <p>
        Intel-SA-00293 – Intel<sup>&reg;</sup> SGX 2019.2 IPU
        Update Advisory
    </p>
    <p>
        <b>Additional Resources</b>
    </p>
    <p>
        <b>Affecting BIOS:</b>
    </p>
    <p>Intel Security Advisory Intel-SA-00164</p>
    <p>CVE-2019-0184</p>
    <p>Intel Security Advisory Intel-SA-00219</p>
    <p>CVE-2019-0117</p>
    <p>Intel Security Advisory Intel-SA-00220</p>
    <p>CVE-2019-0123, CVE-2019-0124</p>
    <p>Intel Security Advisory Intel-SA-00240</p>
    <p>CVE-2019-0151, CVE-2019-0152</p>
    <p>Intel Security Advisory Intel-SA-00241</p>
    <p>
        CVE-2019-11090, CVE-2019-11088, CVE-2019-0165,
        CVE-2019-0166, CVE-2019-0168, CVE-2019-0169,
        CVE-2019-11086, CVE-2019-11087, CVE-2019-11101,
        CVE-2019-11100, CVE-2019-11102, CVE-2019-11103,
        CVE-2019-11104, CVE-2019-11105, CVE-2019-11106,
        CVE-2019-11107, CVE-2019-11108, CVE-2019-11110,
        CVE-2019-11097, CVE-2019-0131, CVE-2019-11109,
        CVE-2019-11131, CVE-2019-11132, CVE-2019-11147
    </p>
    <p>Intel Security Advisory Intel-SA-00254</p>
    <p>CVE-2019-0185</p>
    <p>Intel Security Advisory Intel-SA-00260</p>
    <p>CVE-2019-0154</p>
    <p>Intel Security Advisory Intel-SA-00270</p>
    <p>CVE-2019-11135</p>
    <p>Intel Security Advisory Intel-SA-00271</p>
    <p>CVE-2019-11139</p>
    <p>Intel Security Advisory Intel-SA-00280</p>
    <p>CVE-2019-11136, CVE-2019-11137</p>
    <p>
        <b>Not Affecting BIOS:</b>
    </p>
    <p>Intel Security Advisory Intel-SA-00210</p>
    <p>Intel Security Advisory Intel-SA-00242</p>
    <p>Intel Security Advisory Intel-SA-00255</p>
    <p>Intel Security Advisory Intel-SA-00293</p>
    <br>
    -->
    <p style="text-indent: 0em;">
        <a
            style="color:#00AA71;"
            href="https://nvd.nist.gov/"
            target="_blank"
        >
            https://nvd.nist.gov/
        </ a>
    </p>
    <p style="text-indent: 0em;">
        <a
            style="color:#00AA71;"
            href="https://www.cvedetails.com/"
            target="_blank"
        >
            https://www.cvedetails.com/
        </ a>
    </p>
    <p style="text-indent: 0em;">
        <a
        
            style="color:#00AA71;"
            href="https://www.supermicro.org.cn/zh_cn/support/security_center"
            target="_blank"
        >
            https://www.supermicro.org.cn/zh_cn/support/security_center
        </ a>
    </p>
</div>`,
                }}
            ></div>
        );
    }

    // 服务范围 2019年
    // getServiceScope() {
    //     return (
    //         <div
    //             style={textSty}
    //             dangerouslySetInnerHTML={{
    //                 __html: `<div
    //         class="myTab_block NeiRong"
    //         style="border:0 none;padding-top:0;"
    //     >
    //         <p>
    //             超云PSIRT负责接受、处理和公开披露超云产品和解决方案相关的安全漏洞，同时超云PSIRT是公司对漏洞信息进行披露的唯一出口。超云鼓励漏洞研究人员、行业组织、政府机构和供应商主动将与超云产品相关的安全漏洞报告给超云PSIRT。
    //         </p>
    //         <p>&nbsp;</p>
    //         <p>
    //             PSIRT电子邮箱：
    //             <a
    //                 style="color:blue;"
    //                 href="mailto:PSIRT@chinasupercloud.com"
    //             >
    //                 PSIRT@chinasupercloud.com
    //             </a>
    //         </p>
    //         <p>&nbsp;</p>
    //         <p>安全公告</p>
    //         <p>&nbsp;</p>
    //         <p style="font-size: 24px;text-align: center;">
    //             <b>Intel Platform Update (IPU) 2019.2, November 2019</b>
    //         </p>
    //         <p>&nbsp;</p>
    //         <p>
    //             <b>More Information:</b>
    //         </p>
    //         <p>
    //             <b>Security Advisories That Affect BIOS:</b>
    //         </p>
    //         <p>
    //             Intel-SA-00164 – Intel<sup>&reg;</sup> Trusted Execution
    //             Technology 2019.2 IPU Advisory
    //         </p>
    //         <p>
    //             <b>Summary:</b> Multiple potential security
    //             vulnerabilities that may allow escalation of privilege,
    //             information disclosure, and/or denial of service.
    //         </p>
    //         <p>
    //             <b>Severity:</b> MEDIUM
    //         </p>
    //         <p>
    //             Intel-SA-00219 – Intel<sup>&reg;</sup> SGX 2019.2 with
    //             Intel<sup>&reg;</sup> Processor Graphics IPU Update
    //             Advisory
    //         </p>
    //         <p>
    //             <b>Summary:</b> Potential security vulnerability in
    //             access control of protected memory for Intel
    //             <sup>&reg;</sup> SGX processors with Intel
    //             <sup>&reg;</sup> Processor Graphics may allow for
    //             information disclosure
    //         </p>
    //         <p>
    //             <b>Severity:</b> MEDIUM
    //         </p>
    //         <p>
    //             Intel-SA-00220 – Intel<sup>&reg;</sup> SGX and Intel
    //             <sup>&reg;</sup> TXT Advisory
    //         </p>
    //         <p>
    //             <b>Summary:</b> Potential security vulnerabilities in
    //             6th Generation Intel<sup>&reg;</sup> Core™ Processors
    //             and greater may allow information disclosure.
    //         </p>
    //         <p>
    //             <b>Severity:</b> HIGH
    //         </p>
    //         <p>
    //             Intel-SA-00240 – Intel CPU Local Privilege Escalation
    //             Advisory
    //         </p>
    //         <p>
    //             <b>Summary:</b> Potential security vulnerabilities in
    //             Intel<sup>&reg;</sup> Core™ Processors and Intel
    //             <sup>&reg;</sup> Xeon<sup>&reg;</sup> Processors may
    //             allow escalation of privilege, denial of service, or
    //             information disclosure.
    //         </p>
    //         <p>
    //             <b>Severity:</b> HIGH
    //         </p>
    //         <p>
    //             Intel-SA-00241 – Intel<sup>&reg;</sup> CSME, Server
    //             Platform Services, Trusted Execution Engine, Intel
    //             <sup>&reg;</sup> Active Management Technology and
    //             Dynamic Application Loader 2019.2 IPU Advisory
    //         </p>
    //         <p>
    //             <b>Summary:</b> Potential security vulnerabilities in
    //             Intel<sup>&reg;</sup> Converged Security and
    //             Manageability Engine (Intel<sup>&reg;</sup> CSME),
    //             Server Platform Services, Intel<sup>&reg;</sup> Trusted
    //             Execution Engine (Intel<sup>&reg;</sup> TXE), Intel
    //             <sup>&reg;</sup> Active Management Technology (Intel
    //             <sup>&reg;</sup> AMT) and Intel<sup>&reg;</sup> Dynamic
    //             Application Loader (Intel<sup>&reg;</sup> DAL) may allow
    //             escalation of privilege, denial of service or
    //             information disclosure.
    //         </p>
    //         <p>
    //             <b>Severity:</b> CRITICAL
    //         </p>
    //         <p>
    //             Intel-SA-00254 – Intel<sup>&reg;</sup> System Management
    //             Mode 2019.2 IPU Advisory
    //         </p>
    //         <p>
    //             <b>Summary:</b> potential security vulnerability in
    //             Intel<sup>&reg;</sup> System Management Mode with Intel
    //             <sup>&reg;</sup> Processor Graphics may allow for
    //             information disclosure.
    //         </p>
    //         <p>
    //             <b>Severity:</b> MEDIUM
    //         </p>
    //         <p>
    //             Intel-SA-00260 – Intel<sup>&reg;</sup> Processor
    //             Graphics 2019.2 IPU Advisory
    //         </p>
    //         <p>
    //             <b>Summary:</b> Potential security vulnerability in
    //             Intel<sup>&reg;</sup> Processor Graphics may allow
    //             denial of service.
    //         </p>
    //         <p>
    //             <b>Severity:</b> MEDIUM
    //         </p>
    //         <p>
    //             Intel-SA-00270 – TSX Transaction Asynchronous Abort
    //             Advisory
    //         </p>
    //         <p>
    //             <b>Summary:</b> Potential security vulnerability in
    //             Intel<sup>&reg;</sup> CPUs may allow information
    //             disclosure.
    //         </p>
    //         <p>
    //             <b>Severity:</b> MEDIUM
    //         </p>
    //         <p>
    //             Intel-SA-00271 – Voltage Modulation Technical Advisory
    //         </p>
    //         <p>
    //             <b>Summary:</b> Potential security vulnerability in some
    //             Intel<sup>&reg;</sup> Xeon<sup>&reg;</sup> Scalable
    //             Processors CPUs may allow denial of service.
    //         </p>
    //         <p>
    //             <b>Severity:</b> MEDIUM
    //         </p>
    //         <p>Intel-SA-00280 – BIOS 2019.2 IPU Advisory</p>
    //         <p>
    //             <b>Summary:</b> Potential security vulnerabilities in
    //             Intel firmware may allow escalation of privilege, denial
    //             of service, information disclosure.
    //         </p>
    //         <p>
    //             <b>Severity:</b> HIGH
    //         </p>
    //         <p>
    //             <b>Security Advisories That Don’t Affect BIOS:</b>
    //         </p>
    //         <p>Intel-SA-00210 – Instruction Fetch Security Advisory</p>
    //         <p>
    //             Intel-SA-00242 – Intel<sup>&reg;</sup> Graphics Driver
    //             for Windows* 2019.2 IPU Advisory
    //         </p>
    //         <p>
    //             Intel-SA-00255 – Intel<sup>&reg;</sup> Ethernet 700
    //             Series Controllers Advisory
    //         </p>
    //         <p>
    //             Intel-SA-00293 – Intel<sup>&reg;</sup> SGX 2019.2 IPU
    //             Update Advisory
    //         </p>
    //         <p>
    //             <b>Additional Resources</b>
    //         </p>
    //         <p>
    //             <b>Affecting BIOS:</b>
    //         </p>
    //         <p>Intel Security Advisory Intel-SA-00164</p>
    //         <p>CVE-2019-0184</p>
    //         <p>Intel Security Advisory Intel-SA-00219</p>
    //         <p>CVE-2019-0117</p>
    //         <p>Intel Security Advisory Intel-SA-00220</p>
    //         <p>CVE-2019-0123, CVE-2019-0124</p>
    //         <p>Intel Security Advisory Intel-SA-00240</p>
    //         <p>CVE-2019-0151, CVE-2019-0152</p>
    //         <p>Intel Security Advisory Intel-SA-00241</p>
    //         <p>
    //             CVE-2019-11090, CVE-2019-11088, CVE-2019-0165,
    //             CVE-2019-0166, CVE-2019-0168, CVE-2019-0169,
    //             CVE-2019-11086, CVE-2019-11087, CVE-2019-11101,
    //             CVE-2019-11100, CVE-2019-11102, CVE-2019-11103,
    //             CVE-2019-11104, CVE-2019-11105, CVE-2019-11106,
    //             CVE-2019-11107, CVE-2019-11108, CVE-2019-11110,
    //             CVE-2019-11097, CVE-2019-0131, CVE-2019-11109,
    //             CVE-2019-11131, CVE-2019-11132, CVE-2019-11147
    //         </p>
    //         <p>Intel Security Advisory Intel-SA-00254</p>
    //         <p>CVE-2019-0185</p>
    //         <p>Intel Security Advisory Intel-SA-00260</p>
    //         <p>CVE-2019-0154</p>
    //         <p>Intel Security Advisory Intel-SA-00270</p>
    //         <p>CVE-2019-11135</p>
    //         <p>Intel Security Advisory Intel-SA-00271</p>
    //         <p>CVE-2019-11139</p>
    //         <p>Intel Security Advisory Intel-SA-00280</p>
    //         <p>CVE-2019-11136, CVE-2019-11137</p>
    //         <p>
    //             <b>Not Affecting BIOS:</b>
    //         </p>
    //         <p>Intel Security Advisory Intel-SA-00210</p>
    //         <p>Intel Security Advisory Intel-SA-00242</p>
    //         <p>Intel Security Advisory Intel-SA-00255</p>
    //         <p>Intel Security Advisory Intel-SA-00293</p>
    //         <p>&nbsp;</p>
    //         <p style="text-indent: 0em;">
    //             <a
    //                 style="color:#00AA71;"
    //                 href="https://nvd.nist.gov/"
    //                 target="_blank"
    //             >
    //                 https://nvd.nist.gov/
    //             </a>
    //         </p>
    //         <p style="text-indent: 0em;">
    //             <a
    //                 style="color:#00AA71;"
    //                 href="https://www.cvedetails.com/"
    //                 target="_blank"
    //             >
    //                 https://www.cvedetails.com/
    //             </a>
    //         </p>
    //         <p style="text-indent: 0em;">
    //             <a
                
    //                 style="color:#00AA71;"
    //                 href="https://www.supermicro.org.cn/zh_cn/support/security_center"
    //                 target="_blank"
    //             >
    //                 https://www.supermicro.org.cn/zh_cn/support/security_center
    //             </a>
    //         </p>
    //     </div>`,
    //             }}
    //         ></div>
    //     );
    // }

    // 服务种类
    getServiceTypes() {
        return (
            <div style={textSty}>
                1、 硬件安装
                <br />
                2、 硬件维修 <br />
                3、 硬件配置扩容 <br />
                4、 操作系统的安装与调试 <br />
                5、 磁盘阵列的重建、修改、创建等服务 <br /> 6、 Call Center服务
                <br />
                7、 项目服务：包括驻场、技术服务日、布线、除尘、巡检等
                本服务标准针对以下几种情况提出：
                <br />
            </div>
        );
    }

    // 特别说明
    getOtherTips() {
        return (
            <div style={textSty}>
                1、
                经超云有偿服务更换或增加的产品部件自有偿服务完成之日起享有12个月的保修期。
                <br />
                2、
                增值服务收费标准请拨打超云服务中心热线服务支持电话：4006330360。
            </div>
        );
    }

    getTitleDes() {
        return (
            <div style={{ ...textSty, textIndent: isMobile ? "20px" : "40px" }}>
                超云数字技术集团有限公司（下简称“超云”）通过统一的管理、统一的培训、统一的服务标准、统一的规范和统一的考核，使得客户可以享受到周到、完美、技术专业、形象亲和的超云售后服务。
            </div>
        );
    }

    getServicesStander() {
        return <div style={textSty}>服务标准</div>;
    }

    onClickTabItem(activeTab, id) {
        this.setState({ activeTab: activeTab });
        // document.geti
        // console.log(TAG, "BottomView next:", myRef);
        let anchorElement = document.getElementById(id);
        // console.log(
        //     "anchorElement",
        //     anchorElement,
        //     anchorElement.getBoundingClientRect()
        // );
        const yHeight = anchorElement.getBoundingClientRect().y;

        if (activeTab == 0) {
            window.scrollTo(0, 0);
        }

        // console.log("yHeight", yHeight);
        window.scrollTo({
            left: 0, // x坐标
            top: yHeight - 100, // y 坐标
            behavior: "smooth", // 可选值：smooth、instant、auto
        });
        // window.scrollTo(0, yHeight, {behavior: ‘smooth’});
    }

    onClickSearchBtn() {
        window.open("/product/search");
    }

    adaptHeight(Content50DataSource) {
        // return "auto";
        const result = this.state.isMobile
            ? Content50DataSource.block.children.length * 340 + "px"
            : Math.ceil(Content50DataSource.block.children.length / 4) * 340 +
              "px";
        return result;
    }

    render() {
        const children = [
            // safty_info
            <div style={{ position: "relative" }}>
                <img
                    src={require("../../assets/techsuupport_bg_6.png")}
                    alt=""
                    style={{ width: "100%" }}
                />
                {/* <div
                    style={{
                        position: "absolute",
                        // top: this.state.isMobile ? "3rem" : "16rem",
                        // left: this.state.isMobile ? "2rem" : "16rem",
                        top: this.state.isMobile ? "3rem" : "40%",
                        left: this.state.isMobile ? "1rem" : "15%",
                        color: "#fff",
                        fontSize: this.state.isMobile ? "1.4rem" : "2.4vw",
                        zIndex: 100,
                        fontWeight: 700,
                    }}
                >
                    技术支持
                </div> */}
            </div>,
            <div
                id="services_scope"
                key="services_scope"
                style={{
                    height: "auto",
                    width: "100%",
                    background: "#fff",
                    paddingBottom: "80px",
                    paddingTop: "80px",
                }}
            >
                <CSCTitle
                    title="信息安全"
                    key="services_scope"
                    isMobile={this.state.isMobile}
                    style={{
                        margin: 0,
                        height: "auto",
                        marginBottom: "30px",
                    }}
                    textSty={{
                        fontSize: this.state.isMobile ? "20px" : "28px",
                        fontWeight: "700",
                    }}
                />
                {this.getServiceScope()},
            </div>,
            // <Banner
            //     id="Banner1_0"
            //     key="Banner1_0"
            //     dataSource={Banner10DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <div
            //     className="p_tab_box_51_wrapper"
            //     key={"p_tab_box_51_wrapper"}
            //     style={{
            //         paddingTop: this.state.isMobile ? "30px" : "60px",
            //         paddingBottom: this.state.isMobile ? "10px" : "60px",
            //     }}
            // >
            //     <div className="p_tab_box_51">
            //         {[
            //             { title: "服务范围", href: "services_scope" },
            //             { title: "服务种类", href: "services_type" },
            //             { title: "服务标准", href: "services_stander" },
            //             { title: "其它说明", href: "other_tips" },
            //         ].map((item, index) => {
            //             console.log("item", item);
            //             return (
            //                 <a
            //                     // onClick = {()=>{}}
            //                     // href={`#${item.href}`}
            //                     key={"cb" + index}
            //                     className={
            //                         this.state.activeTab == index
            //                             ? "p_tab_item_51_active p_tab_item_51"
            //                             : "p_tab_item_51"
            //                     }
            //                     onClick={() => {
            //                         this.onClickTabItem(index, item.href);
            //                     }}
            //                 >
            //                     {item.title}
            //                 </a>
            //             );
            //         })}
            //     </div>
            // </div>,
            // <div
            //     id="services_scope"
            //     key="services_scope"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     {this.getTitleDes()},
            // </div>,
            // <div
            //     id="services_scope"
            //     key="services_scope"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="服务范围"
            //         key="services_scope"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getServiceScope()},
            // </div>,
            // <div
            //     id="services_type"
            //     key="services_type"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="服务种类"
            //         key="services_type"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getServiceTypes()},
            // </div>,
            // <div
            //     id="services_stander"
            //     key="services_stander"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="服务标准"
            //         key="services_stander"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getServicesStander()},
            // </div>,
            // <div
            //     id="other_tips"
            //     key="other_tips"
            //     style={{
            //         height: "auto",
            //         width: "100%",
            //         background: "#fafafa",
            //         paddingBottom: "60px",
            //     }}
            // >
            //     <CSCTitle
            //         title="其它说明"
            //         key="other_tips"
            //         isMobile={this.state.isMobile}
            //         style={{
            //             margin: 0,
            //             height: "auto",
            //         }}
            //         textSty={{
            //             fontSize: this.state.isMobile ? "20px" : "28px",
            //             fontWeight: "700",
            //         }}
            //     />
            //     {this.getOtherTips()}
            // </div>,
        ];
        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {this.state.show && children}
                <CSCBackTop isMobile={this.state.isMobile} key="safety_info" />
                {/* 如果不是 dva 2.0 替换成 {children} end */}
            </div>
        );
    }
}

const style = React.cree;
