import React from "react";
import { Row, Col } from "antd";
import { TweenOneGroup } from "rc-tween-one";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import { getChildrenToRender } from "./utils";

class Content5 extends React.PureComponent {
    getChildrenToRender = (data) => {
        const { isMobile } = this.props;
        return data.map((item) => {
            // console.log("item", item);
            return (
                <Col
                    key={item.name}
                    {...item}
                    style={{
                        margin: "0 auto",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "20px",
                    }}
                >
                    <a
                        {...item.children.wrapper}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: isMobile ? "320px" : "220px",
                            height: isMobile ? "320px%" : "220px",
                            // background: "red",
                        }}
                    >
                        {/* <span {...item.children.img} style={{ padding: 0 }}> */}
                        <img
                            src={item.children.img.children}
                            style={{ width: "100%" }}
                            alt="img"
                        />
                        {/* </span> */}
                        {/* <p {...item.children.content}>
                            {item.children.content.children}
                        </p> */}
                    </a>
                </Col>
            );
        });
    };

    render() {
        const { ...props } = this.props;
        const { dataSource } = props;
        delete props.dataSource;
        // delete props.isMobile;
        for (let item of dataSource.block.children) {
            // console.log("item___sssss", item);
            item["span"] = props.isMobile ? 1 : 5;
        }
        // dataSource.block.children
        const childrenToRender = this.getChildrenToRender(
            dataSource.block.children
        );

        return (
            <div
                {...props}
                {...dataSource.wrapper}
                // style={{
                //     height: props.isMobile
                //         ? dataSource.block.children.length * 225 + "px"
                //         : Math.ceil(dataSource.block.children.length / 5) *
                //               360 +
                //           "px",
                // }}
                style={{
                    // height: props.isMobile
                    //     ? dataSource.block.children.length * 365 + "px"
                    //     : `${
                    //           Math.ceil(dataSource.block.children.length / 5) *
                    //               300 +
                    //           200
                    //       }` + "px",
                    backgroundColor: "#fff",
                }}
            >
                <div
                    {...dataSource.page}
                    // style={{
                    //     height: props.isMobile
                    //         ? dataSource.block.children.length * 365 + "px"
                    //         : `${
                    //               Math.ceil(
                    //                   dataSource.block.children.length / 5
                    //               ) *
                    //                   300 +
                    //               200
                    //           }` + "px",
                    // }}
                >
                    {/* <div
                        key="title"
                        {...dataSource.titleWrapper}
                        style={{ marginBottom: "80px" }}
                    >
                        {dataSource.titleWrapper.children.map(
                            getChildrenToRender
                        )}
                    </div> */}
                    <div style={{ width: "100%" }}>
                        <img
                            style={{ width: "100%" }}
                            src={require("../assets/customer_logos.png")}
                        />
                    </div>
                    {/* <OverPack
                        className={`content-template ${props.className}`}
                        {...dataSource.OverPack}
                    >
                        <TweenOneGroup
                            component={Row}
                            key="ul"
                            enter={{
                                y: "+=30",
                                opacity: 0,
                                type: "from",
                                ease: "easeInOutQuad",
                            }}
                            leave={{
                                y: "+=30",
                                opacity: 0,
                                ease: "easeInOutQuad",
                            }}
                            {...dataSource.block}
                        >
                            {childrenToRender}
                        </TweenOneGroup>
                    </OverPack> */}
                </div>
            </div>
        );
    }
}

export default Content5;
