import React from 'react'
export const Nav30DataSource = {
    wrapper: {className: 'header3 home-page-wrapper'},
    page: {className: 'home-page'},
    logo: {
        className: 'header3-logo',
        // children: "http://www.chinasupercloud.com/images/logo.png",
        children: require('../assets/logo_top.png'),
    },
    Menu: {
        className: 'header3-menu pdr20',
        children: [
            {
                name: 'item0',
                className: 'header3-item',
                children: {
                    href: '/',
                    children: [{children: '首页', name: 'text'}],
                },
            },
            {
                name: 'item1',
                className: 'header3-item',
                children: {
                    href: '/product/',
                    children: [{children: '产品', name: 'text'}],
                },
            },
            {
                name: 'item2',
                className: 'header3-item',
                children: {
                    href: '/solution/',
                    children: [{children: '解决方案', name: 'text'}],
                },
            },
            {
                name: 'item3',
                className: 'header3-item',
                children: {
                    href: '/techsupport/',
                    children: [{children: '技术支持', name: 'text'}],
                },
            },
            {
                name: 'item4',
                className: 'header3-item',
                children: {
                    href: '/about/',
                    children: [{children: '关于超云', name: 'text'}],
                },
            },
        ],
    },
    mobileMenu: {className: 'header3-mobile-menu'},
}
