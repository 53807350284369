import React from "react";
import TweenOne from "rc-tween-one";
import { Link } from "react-router-dom";
import { Menu, Dropdown, Space, Collapse } from "antd";
import { getChildrenToRender } from "./utils";
import { withRouter } from "react-router-dom";
import {enquireScreen} from 'enquire-js'

const { Panel } = Collapse;

// import { useNavigate } from "react-router-dom";

const { Item, SubMenu } = Menu;
class Header3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneOpen: undefined,
            activeCode: 0,
            hoverTabnName: "",
            isModal: false,
            mobileActiveHref: "",
            isMobile:false,
        };
    }
    phoneClick = () => {
        const phoneOpen = !this.state.phoneOpen;
        this.setState({
            phoneOpen,
        });
    };

    componentDidMount() {
        let url = window.location.href;
        if (url.includes("/product")) {
            this.setState({
                activeCode: 1,
            });
            return;
        }
        if (url.includes("/solution")) {
            this.setState({
                activeCode: 2,
            });
            return;
        }
        if (url.includes("/techsupport")) {
            this.setState({
                activeCode: 3,
            });
            return;
        }
        if (url.includes("/about")) {
            this.setState({
                activeCode: 4,
            });
            return;
        }
        this.setState({
            activeCode: 0,
        });
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({isMobile: !!b})
        })
    }

    handleMouseEnter = (e, item) => {
        // console.log("handleMouseEnter", e, item);
        this.setState({ hoverTabnName: item.name });
    };

    getMenuView(name) {
        if (name == "item0") {
            return <div></div>;
        }

        const staticData = {
            item1: [
                {
                    title: "云基础架构产品",
                    children: [
                        { name: "智算服务器", path: "/product?p=1" },
                        { name: "信创服务器", path: "/product?p=2" },
                        { name: "液冷服务器", path: "/product?p=3" },
                        { name: "微模块数据中心", path: "/product?p=4" },
                        { name: "信创工作站", path: "/product?p=5" },
                    ],
                },
                {
                    title: "超异云产品",
                    children: [
                        { name: "集中式存储", path: "/product?p=6" },
                        { name: "分布式存储", path: "/product?p=7" },
                        { name: "云平台/虚拟化", path: "/product?p=8" },
                        { name: "超融合", path: "/product?p=9" },
                        { name: "云桌面", path: "/product?p=10" },
                        // { name: "PAAS平台", path: "/product?p=11" },
                        // { name: "大数据", path: "/product?p=12" },
                        // { name: "安全", path: "/product?p=13" },
                    ],
                },
                // {
                //     title: "云服务产品",
                //     children: [
                //         { name: "云集成", path: "/product/server" },
                //         {
                //             name: "云租赁",
                //             path: "/product/server",
                //         },
                //         {
                //             name: "裸金属",
                //             path: "/product/server",
                //         },
                //     ],
                // },
            ],
            // const staticData_1 = [
            //     { id: 1, name: "党务政务" },
            //     { id: 2, name: "航天军工" },
            //     { id: 3, name: "教科研" },
            //     { id: 4, name: "医疗" },
            //     { id: 5, name: "公安" },
            // ];

            // const staticData_2 = [
            //     { id: 6, name: "互联网" },
            //     { id: 7, name: "金融" },
            //     { id: 8, name: "电信" },
            //     { id: 9, name: "能源" },
            //     { id: 10, name: "交通" },
            //     { id: 11, name: "ISV" },
            // ];
            // const staticData_3 = [
            //     { id: 12, name: "云计算" },
            //     { id: 13, name: "超融合" },
            //     { id: 14, name: "高性能计算" },
            //     { id: 15, name: "人工智能" },
            //     { id: 16, name: "数据中心" },
            // ];
            item2: [
                {
                    title: "政府与公共事业",
                    children: [
                        { name: "党务政务", path: "/solution?s=1" },
                        // { name: "航天军工", path: "/solution?s=2" },
                        { name: "教科研", path: "/solution?s=3" },
                        { name: "医疗", path: "/solution?s=4" },
                        { name: "公安", path: "/solution?s=5" },
                    ],
                },
                {
                    title: "企业解决方案",
                    children: [
                        { name: "互联网", path: "/solution?s=6" },
                        {
                            name: "金融",
                            path: "/solution?s=7",
                        },
                        {
                            name: "电信",
                            path: "/solution?s=8",
                        },
                        {
                            name: "能源",
                            path: "/solution?s=9",
                        },
                        {
                            name: "交通",
                            path: "/solution?s=10",
                        },
                        // {
                        //     name: "ISV",
                        //     path: "/solution?s=11",
                        // },
                    ],
                },
                {
                    title: "云计算与数据中心",
                    children: [
                        { name: "云计算", path: "/solution?s=12" },
                        {
                            name: "超融合",
                            path: "/solution?s=13",
                        },
                        {
                            name: "高性能计算",
                            path: "/solution?s=14",
                        },
                        {
                            name: "人工智能",
                            path: "/solution?s=15",
                        },
                        {
                            name: "数据中心",
                            path: "/solution?s=16",
                        },
                    ],
                },
            ],

            item3: [
                {
                    title: "售后服务体系",
                    children: [
                        {
                            name: "保修政策",
                            path: "/techsupport/warranty/policy",
                        },
                        {
                            name: "增值服务",
                            path: "/techsupport/value/add/service",
                        },
                        {
                            name: "服务网点",
                            path: "/techsupport/service/network",
                        },
                    ],
                },
                {
                    title: "产品支持",
                    children: [
                        { name: "用户手册", path: "/techsupport/user/manual" },
                        {
                            name: "驱动下载",
                            path: "/techsupport/drive/download",
                        },
                         {
                            name: "自助查询",
                            path: "/techsupport/self/query",
                        },
                    ],
                },
                {
                    title: "产品声明",
                    children: [
                        {
                            name: "信息安全声明",
                            path: "/techsupport/infomation/safety",
                        },
                    ],
                },
            ],
            item4: [
                {
                    title: "公司简介",
                    children: [
                        { name: "公司信息", path: "/about/company/intro" },
                        {
                            name: "联系我们",
                            path: "/about/company/intro/#contactus",
                        },
                    ],
                },
                {
                    title: "公司动态",
                    children: [
                        { name: "新闻动态", path: "/about/news/list" },
                        {
                            name: "首页动态",
                            path: "/",
                        },
                    ],
                },
                {
                    title: "加入我们",
                    children: [
                        {
                            name: "社招职位",
                            path: "/about/recruit/society",
                        },
                        {
                            name: "校招职位",
                            path: "/about/recruit/school",
                        },
                         {
                            name: "招聘声明",
                            path: "/about/recruit/explain",
                        },
                    ],
                },
            ],
        };
        const selectData = staticData[name] || [];

        return (
            <Menu style={{ marginTop: "7px" }}>
                {selectData.map((item, idx) => {
                    return (
                        <SubMenu
                            key={`sub${idx}`}
                            title={item.title}
                            style={{
                                padding: "5px 10px",
                                fontSize: "20px",
                            }}
                        >
                            {item.children.map((n) => {
                                return (
                                    <Menu.Item
                                        key={n.path}
                                        style={{
                                            padding: "5px 10px",
                                            fontSize: "14px",
                                        }}
                                        onClick={() => {
                                            // console.log("111Link", this.props);
                                            const num = name.split("item")[1];
                                            // console.log("numnumnumnum", n);

                                            this.setState({
                                                activeCode: num,
                                            });

                                            // this.props.history.push(n.path);
                                            // useNavigate(n.path);
                                            // window.open(n.path);
                                            window.location.href = n.path;
                                        }}
                                    >
                                        <span
                                            style={{
                                                padding: "5px 10px",
                                                display: "inline-block",
                                            }}
                                        >
                                            {n.name}
                                        </span>
                                    </Menu.Item>
                                );
                            })}
                        </SubMenu>
                    );
                })}
            </Menu>
        );
    }

    jumpToHomePage = () => {
        console.log(28282828)
       window.location.href = 'https://www.chinasupercloud.com/'
    };

    getMobileSelect_1() {
        const itemSty = { marginBottom: "10px" };
        return (
            <Collapse accordion>
                <Panel header="云基础架构产品" key="1">
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/product?p=1";
                        }}
                    >
                        智算服务器
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/product?p=2";
                        }}
                    >
                        信创服务器
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/product?p=3";
                        }}
                    >
                        液冷服务器
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/product?p=4";
                        }}
                    >
                        微模块数据中心
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/product?p=5";
                        }}
                    >
                        信创工作站
                    </p>
                </Panel>
                <Panel header="超异云产品" key="2">
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/product?p=6";
                        }}
                    >
                        集中式存储
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/product?p=7";
                        }}
                    >
                        分布式存储
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/product?p=8";
                        }}
                    >
                        云平台/虚拟化
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/product?p=9";
                        }}
                    >
                        超融合
                    </p>
                    {/* <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/product?p=10";
                        }}
                    >
                        云桌面
                    </p>
                     <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/product?p=11";
                        }}
                    >
                        PAAS平台
                    </p>
                     <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/product?p=12";
                        }}
                    >
                        大数据
                    </p>
                     <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/product?p=13";
                        }}
                    >
                        安全
                    </p> */}
                </Panel>
            </Collapse>
        );
    }

    getMobileSelect_2() {
        const itemSty = { marginBottom: "10px" };

        return (
            <Collapse accordion>
                <Panel header="政府与公共事业" key="1">
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/solution?s=1";
                        }}
                    >
                        党务政务
                    </p>
                    {/* <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/solution?s=2";
                        }}
                    >
                        航天军工
                    </p> */}
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/solution?s=3";
                        }}
                    >
                        教科研
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/solution?s=4";
                        }}
                    >
                        医疗
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/solution?s=5";
                        }}
                    >
                        公安
                    </p>
                </Panel>
                <Panel header="企业解决方案" key="2">
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/solution?s=6";
                        }}
                    >
                        互联网
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/solution?s=7";
                        }}
                    >
                        金融
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/solution?s=8";
                        }}
                    >
                        电信
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/solution?s=9";
                        }}
                    >
                        能源
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/solution?s=10";
                        }}
                    >
                        交通
                    </p>
                </Panel>
                <Panel header="云计算与数据中" key="3">
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/solution?s=12";
                        }}
                    >
                        云计算
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/solution?s=13";
                        }}
                    >
                        超融合
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/solution?s=14";
                        }}
                    >
                        高性能计算
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/solution?s=15";
                        }}
                    >
                        人工智能
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/solution?s=16";
                        }}
                    >
                        数据中心
                    </p>
                </Panel>
            </Collapse>
        );
    }

    getMobileSelect_3() {
        const itemSty = { marginBottom: "10px" };

        return (
            <Collapse accordion>
                <Panel header="售后服务体系" key="1">
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href =
                                "/techsupport/warranty/policy";
                        }}
                    >
                        保修政策
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href =
                                "/techsupport/value/add/service";
                        }}
                    >
                        增值服务
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href =
                                "/techsupport/service/network";
                        }}
                    >
                        服务网点
                    </p>
                </Panel>
                <Panel header="产品支持" key="2">
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/techsupport/user/manual";
                        }}
                    >
                        用户手册
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href =
                                "/techsupport/drive/download";
                        }}
                    >
                        驱动下载
                    </p>
                </Panel>
                <Panel header="产品声明" key="3">
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href =
                                "/techsupport/infomation/safety";
                        }}
                    >
                        信息安全声明
                    </p>
                </Panel>
            </Collapse>
        );
    }

    // 公司简介;
    // 公司动态;
    // 加入我们;
    // 公司信息;
    // 联系我们;
    getMobileSelect_4() {
        const itemSty = { marginBottom: "10px" };

        return (
            <Collapse accordion>
                <Panel header="公司简介" key="1">
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/about/company/intro";
                        }}
                    >
                        公司信息
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href =
                                "/about/company/intro/#contactus";
                        }}
                    >
                        联系我们
                    </p>
                </Panel>
                <Panel header="公司动态" key="2">
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/about/news/list";
                        }}
                    >
                        新闻动态
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/";
                        }}
                    >
                        首页动态
                    </p>
                </Panel>
                <Panel header="加入我们" key="3">
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/about/recruit/society";
                        }}
                    >
                        社招职位
                    </p>
                    <p
                        style={itemSty}
                        onClick={() => {
                            window.location.href = "/about/recruit/school";
                        }}
                    >
                        校招职位
                    </p>
                </Panel>
            </Collapse>
        );
    }

    render() {
        const { dataSource, isMobile, ...props } = this.props;
        const { phoneOpen } = this.state;
        const navData = dataSource.Menu.children;

        const navChildren = navData.map((item, i) => {
            const { children: a, subItem, ...itemProps } = item;
            // console.log("itemitemitem", item);
            let hoverMenu = this.getMenuView(item.name);
            if (subItem) {
                return (
                    <SubMenu
                        key={item.name}
                        {...itemProps}
                        title={
                            <div
                                {...a}
                                className={`header3-item-block ${a.className}`.trim()}
                            >
                                {a.children.map(getChildrenToRender)}
                            </div>
                        }
                        popupClassName="header3-item-child"
                    >
                        {subItem.map(($item, ii) => {
                            const { children: childItem } = $item;
                            const child = childItem.href ? (
                                <a {...childItem}>
                                    {childItem.children.map(
                                        getChildrenToRender
                                    )}
                                </a>
                            ) : (
                                <div {...childItem}>
                                    {childItem.children.map(
                                        getChildrenToRender
                                    )}
                                </div>
                            );
                            return (
                                <Item
                                    key={$item.name || ii.toString()}
                                    {...$item}
                                >
                                    {child}
                                </Item>
                            );
                        })}
                    </SubMenu>
                );
            }
            return (
                <Item
                    key={item.name}
                    {...itemProps}
                    id={item.name}
                    // style={
                    //     item.name == "item" + this.state.activeCode
                    //         ? { borderBottom: "3px solid #00AA71" }
                    //         : null
                    // }
                    onMouseEnter={(e) => {
                        this.handleMouseEnter(e, item);
                    }}
                >
                    {isMobile ? (
                        <a
                            {...a}
                            className={`header3-item-block ${a.className}`.trim()}
                            style={
                                item.name == "item" + this.state.activeCode
                                    ? {
                                          color: "#00AA71",
                                          fontSize: "1rem",
                                          fontWeight: 500,
                                      }
                                    : {
                                          color: "#333",
                                          fontWeight: 500,
                                          fontSize: "1rem",
                                      }
                            }
                            onClick={(e) => {
                                if (item.name == "item0") {
                                    console.log("182828");
                                } else {
                                    e.preventDefault();
                                    // console.log("92929292929", item);
                                    this.setState({
                                        isModal: true,
                                        phoneOpen: false,
                                        mobileActiveHref: item.name,
                                    });
                                }
                            }}
                        >
                            {a.children.map(getChildrenToRender)}
                        </a>
                    ) : item.name != "item0" ? (
                        <Dropdown overlay={hoverMenu}>
                            <a
                                {...a}
                                className={`header3-item-block ${a.className}`.trim()}
                                style={
                                    item.name == "item" + this.state.activeCode
                                        ? {
                                              color: "#00AA71",
                                              fontSize: "1rem",
                                              fontWeight: 500,
                                          }
                                        : {
                                              color: "#333",
                                              fontWeight: 500,
                                              fontSize: "1rem",
                                          }
                                }
                                onClick={(e) => e.preventDefault()}
                            >
                                {a.children.map(getChildrenToRender)}
                                {i < 4 ? (
                                    <div
                                        style={{
                                            width: "1px",
                                            height: "0.7rem",
                                            background: "#B3B3B3",
                                            marginRight: "0.9rem",
                                            marginLeft: "1.6rem",
                                            marginTop: "0.2rem",
                                        }}
                                    ></div>
                                ) : null}
                            </a>
                        </Dropdown>
                    ) : (
                        <div>
                            <a
                                {...a}
                                className={`header3-item-block ${a.className}`.trim()}
                                style={
                                    item.name == "item" + this.state.activeCode
                                        ? {
                                              color: "#00AA71",
                                              fontSize: "1rem",
                                              fontWeight: 500,
                                          }
                                        : {
                                              color: "#333",
                                              fontWeight: 500,
                                              fontSize: "1rem",
                                          }
                                }
                            >
                                {a.children.map(getChildrenToRender)}
                                <div
                                    style={{
                                        width: "1px",
                                        height: "0.7rem",
                                        background: "#B3B3B3",
                                        marginRight: "0.9rem",
                                        marginLeft: "1.6rem",
                                        marginTop: "0.2rem",
                                    }}
                                ></div>
                            </a>
                        </div>
                    )}
                </Item>
            );
        });
        const moment = phoneOpen === undefined ? 300 : null;
        return (
            <div style={{}}>
                <div >
                <TweenOne
                    component="header"
                    animation={{ opacity: 0, type: "from" }}
                    {...dataSource.wrapper}
                    {...props}
                >
                    <div
                        {...dataSource.page}
                        className={`${dataSource.page.className}${
                            phoneOpen ? " open" : ""
                        }`}
                        style={isMobile ? {marginRight:0} :{paddingRight:20, background:"#fff"}}
                    >
                        <TweenOne
                            animation={{
                                x: -30,
                                type: "from",
                                ease: "easeOutQuad",
                            }}
                            {...dataSource.logo}
                        >
                            <img
                                style={{cursor: 'pointer'}}   onClick={() => {
                                this.jumpToHomePage();}}
                                width={this.state.isMobile ? "60%" : "100%"}
                                src={dataSource.logo.children}
                                alt="img"
                            />
                        </TweenOne>
                        {isMobile && (
                            <div
                                {...dataSource.mobileMenu}
                                onClick={() => {
                                    this.phoneClick();
                                }}
                            >
                                <em />
                                <em />
                                <em />
                            </div>
                        )}
                        <TweenOne
                            {...dataSource.Menu}
                            animation={
                                isMobile
                                    ? {
                                          x: 0,
                                          height: 0,
                                          duration: 300,
                                          onComplete: (e) => {
                                              if (this.state.phoneOpen) {
                                                  e.target.style.height =
                                                      "auto";
                                              }
                                          },
                                          ease: "easeInOutQuad",
                                      }
                                    : null
                            }
                            moment={moment}
                            reverse={!!phoneOpen}
                        >
                            <Menu
                                mode={isMobile ? "inline" : "horizontal"}
                                defaultSelectedKeys={["sub0"]}
                                theme="light"
                            >
                                {navChildren}
                            </Menu>
                        </TweenOne>
                    </div>
                </TweenOne>   
                {/* {!isMobile ?  <div  style={{position:'fixed' ,top:19, right:30, zIndex:1000}}>
                    <img src={require("../assets/head_search_icon.png")} alt="" style={{ width: 20, cursor:"pointer"  }} onClick={()=>{
                           window.open("/product/search");
                    }} />
                </div>:null}          */}
                </div>
                {!isMobile ?  <div  class="headerImage" style={{position:'fixed' ,top:20, right:10, zIndex:1000}}>
                    <div style={{display:'flex', alignItems:'center'}}>
                        <div id="google_translate_element" style={{ marginRight: 10, }}></div>
                        <img src={require("../assets/head_search_icon.png")} alt="" style={{ width: 20, height:20, cursor:"pointer" , 
                        
                        marginRight: 20,
                         zIndex:1000,  }} onClick={()=>{
                            window.open("/product/search");
                        }} />
                    </div>
                </div>:null}
                {this.state.isModal ? (
                    <div
                        style={{
                            width: "100vw",
                            height: "100vh",
                            position: "fixed",
                            top: 0,
                            left: 0,
                            background: "rgba(0,0,0, 0.4)",
                            zIndex: 1000,
                        }}
                        onClick={(e) => {
                            this.setState({ isModal: false });
                        }}
                    >
                        <div
                            style={{ zIndex: 1002 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                // this.setState({ phoneOpen: true });
                            }}
                        >
                            {this.state.mobileActiveHref == "item1" &&
                                this.getMobileSelect_1()}
                            {this.state.mobileActiveHref == "item2" &&
                                this.getMobileSelect_2()}
                            {this.state.mobileActiveHref == "item3" &&
                                this.getMobileSelect_3()}
                            {this.state.mobileActiveHref == "item4" &&
                                this.getMobileSelect_4()}
                        </div>
                        ,
                    </div>
                ) : null}
            </div>
        );
    }
}
export default withRouter(Header3);
