import React from "react";
// import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import { Row, Col, Empty } from "antd";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import { getChildrenToRender } from "./utils";
import config from "../libs/config";

class Content extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            solutionCategoryOptions: [
                {
                    value: "1",
                    label: "党务政务",
                },
                // {
                //     value: "2",
                //     label: "航天军工",
                // },
                {
                    value: "3",
                    label: "教科研",
                },
                {
                    value: "4",
                    label: "医疗",
                },
                {
                    value: "5",
                    label: "公安",
                },
                {
                    value: "6",
                    label: "互联网",
                },
                {
                    value: "7",
                    label: "金融",
                },
                {
                    value: "8",
                    label: "电信",
                },
                {
                    value: "9",
                    label: "能源",
                },
                {
                    value: "10",
                    label: "交通",
                },
                // {
                //     value: "11",
                //     label: "ISV",
                // },
                {
                    value: "12",
                    label: "云计算",
                },
                {
                    value: "13",
                    label: "超融合",
                },
                {
                    value: "14",
                    label: "高性能计算",
                },
                {
                    value: "15",
                    label: "人工智能",
                },
                {
                    value: "16",
                    label: "数据中心",
                },
            ],
            isHoverDetail:"",
            isHoverDetail_outer:""
        };
    }
    onClickDetail(id) {
        // onClickDetail
        window.open(`/solution/detail?solutionid=${id}`);
    }
    getTitle = () => {
        let str = "";
        for (let item of this.state.solutionCategoryOptions) {
            if (item.value == this.props.sCode) {
                str = item.label;
            }
        }
        return str;
    };

    handleMouseEnter(data, id){
        if(data === 1){
            this.setState({isHoverDetail: id})
        }
        if(data === 2){
            this.setState({isHoverDetail: ""})
        }
    }

    handleMouseEnter_outer(data, id){
        console.log('data',data,id)
        if(data === 1){
            this.setState({isHoverDetail_outer: id})
        }
        if(data === 2){
            this.setState({isHoverDetail_outer: ""})
        }
    }

    render() {
        const {
            solutionList = [],
            sCode,
            dataSource,
            isMobile,
            ...props
        } = this.props;
        const {
            wrapper,
            titleWrapper,
            page,
            OverPack: overPackData,
            childWrapper,
        } = dataSource;
        return (
            <div {...props} {...wrapper} >
                <div {...page}>
                    {/* <div {...titleWrapper}>
                        <span style={{ fontSize: "30px", fontWeight: "600" }}>
                            {this.getTitle()}
                        </span>
                        
                    </div> */}
                    <OverPack {...overPackData}>
                        <div
                            forcedReplay={false}
                            type="bottom"
                            key="block"
                            leaveReverse
                            component={Row}
                            componentProps={childWrapper}
                        >
                            {solutionList && solutionList.length ? (
                                solutionList.map((block, i) => {
                                    return (
                                        <Col
                                            key={i.toString()}
                                            name={"block2"}
                                            className={"content0-block"}
                                            md={8}
                                            xs={24}
                                        >
                                            <div style={{ width:"100%", height:"100%",  marginBottom: "3rem", borderTopLeftRadius:"20px",
                                                        borderTopRightRadius:"20px", borderBottomLeftRadius:"20px",
                                                            borderBottomRightRadius:"20px",}} >
                                                <div
                                                    style={{
                                                        // marginBottom: "10rem",
                                                        position: "relative",
                                                        background:"#fff",
                                                        borderTopLeftRadius:"20px",
                                                        borderTopRightRadius:"20px",
                                                        borderBottomLeftRadius:"20px",
                                                        borderBottomRightRadius:"20px",
                                                    }}
                                                    className={'outer_div'}
                                                    onMouseEnter={() => {
                                                        this.handleMouseEnter_outer(1, block.id);
                                                    }}
                                                    onMouseLeave={() => {
                                                        this.handleMouseEnter_outer(2);
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            config.pic_url +
                                                            block.solution_bg_img
                                                        }
                                                        alt=""
                                                        style={{
                                                            height: "210px !important",
                                                            borderTopLeftRadius:"20px",
                                                            borderTopRightRadius:"20px",
                                                        }}
                                                    />
                                                    <div
                                                        style={{
                                                            background: "#fff",
                                                            width: "100%",
                                                            height: "9.2rem",
                                                            borderBottomLeftRadius:"20px",
                                                            borderBottomRightRadius:"20px",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                paddingLeft: "1rem",
                                                                fontWeight: "600",
                                                                fontSize: "1rem",
                                                                marginTop: "1rem",
                                                            }}
                                                        >
                                                            {block.solution_name && block.solution_name.length> 20 ? block.solution_name.slice(
                                                                            0,
                                                                            18
                                                                        ) + "..." :
                                                                block.solution_name}
                                                        </div>
                                                        <div
                                                            style={{
                                                                paddingLeft: "1rem",
                                                                paddingRight:
                                                                    "1rem",
                                                                fontSize: "14px",
                                                                marginTop: "0.7rem",
                                                                textIndent: "1rem",
                                                                color: "#999999",
                                                                height:"2.5rem",
                                                            }}
                                                            className={"wrap_txt"}
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    block.project_bg &&
                                                                    block
                                                                        .project_bg
                                                                        .length > 45
                                                                        ? block.project_bg.slice(
                                                                            0,
                                                                            42
                                                                        ) + "..."
                                                                        : block.project_bg,
                                                            }}
                                                        ></div>
                                                        <span
                                                            onClick={() => {
                                                                this.onClickDetail(
                                                                    block.id
                                                                );
                                                            }}
                                                            onMouseEnter={() => {
                                                                this.handleMouseEnter(1, block.id);
                                                            }}
                                                            onMouseLeave={() => {
                                                                this.handleMouseEnter(2);
                                                            }}
                                                            style={{
                                                                marginLeft: "1rem",
                                                                fontSize: "15px",
                                                                marginTop: "1.1rem",
                                                                border: this.state.isHoverDetail !==  block.id ? "2px solid #00AA71": "",
                                                                color:  this.state.isHoverDetail !==  block.id ? "#00AA71": "#fff",
                                                                background: this.state.isHoverDetail !==  block.id ? "#fff":"#00AA71",
                                                                display:
                                                                    "inline-block",
                                                                padding: "4px 9px",
                                                                fontWeight:600,
                                                                // width: "2rem",
                                                                alignItems: "center",
                                                                cursor: "pointer",
                                                                justifyContent: "center",
                                                                borderRadius:"0.9rem",
                                                                height:"1.8rem",
                                                                width:"6rem",
                                                                display:"flex",
                                                                alignItems:"center",
                                                                justifyContent:"center"                                                        
                                                            }}
                                                        >
                                                            了解详情
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })
                            ) : (
                                <div
                                    style={{
                                        marginTop: "100px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description={""}
                                    />
                                    ,
                                </div>
                            )}
                        </div>
                    </OverPack>
                </div>
            </div>
        );
    }
}

export default Content;
