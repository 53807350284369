import React from "react";
import {
    RightOutlined,
    LeftOutlined,
    PhoneFilled,
    PhoneTwoTone,
    PhoneOutlined,
} from "@ant-design/icons";
import "./widget.less";
import { render } from "less";

// class Content5 extends React.PureComponent {
export default class CSCFloatBox extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showPhoneBox: false,
        };
    }

    render() {
        const { isMobile } = this.props;
        return (
            <div>
                {!isMobile ? (
                    <div
                        style={{
                            position: "fixed",
                            bottom: "70px",
                            right: this.state.showPhoneBox ? "20px" : "-100px",
                            opacity: this.state.showPhoneBox ? "1" : "0",
                            color: "#fff",
                            background: "#46a670",
                            width: "190px",
                            height: "40px",
                            lineHeight: "40px",
                            borderRadius: "20px",
                            fontSize: "16px",
                            fontWeight: "600",
                            textAlign: "start",
                            display: "flex",
                            alignItem: "flex-start",
                            paddingLeft: "20px",
                            // justifyContent: "center",
                            transition: "all 0.3s",
                            zIndex: 999,
                        }}
                        onMouseEnter={() => {
                            this.setState({ showPhoneBox: true });
                        }}
                        onMouseLeave={() => {
                            this.setState({ showPhoneBox: false });
                        }}
                    >
                        4006-330-360
                    </div>
                ) : null}
                <a
                    onMouseEnter={() => {
                        this.setState({ showPhoneBox: true });
                    }}
                    onMouseLeave={() => {
                        this.setState({ showPhoneBox: false });
                    }}
                    href={isMobile ? "tel:4006-330-360" : "javascript:void(0);"}
                    style={{
                        position: "fixed",
                        // bottom: "100px",
                        bottom: isMobile ? "200px" : "70px",
                        right: isMobile ? "10px" : "20px",
                        zIndex: 1000,
                        width: "40px",
                        height: "40px",
                        background: "red",
                        borderRadius: "50%",
                        background: "#46a670",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <img
                        src={require("../assets/phone_icon.png")}
                        style={{ width: "2.1rem" }}
                    />
                    {/* <PhoneOutlined
                        style={{ fontSize: "20px", color: "#fff" }}
                    /> */}
                    {/* <PhoneTwoTone style={{ fontSize: "20px", color: "#fff" }} /> */}
                    {/* <PhoneFilled style={{ fontSize: "16px", color: "#fff" }} /> */}
                </a>
            </div>
        );
    }
}
