/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import CSCTitle from "../../widget/CSCTitle";
import CSCBackTop from "../../widget/CSCBackTop";

import { BackTop } from "antd";

import "./less/antMotionStyle.less";
import "./less/product.less";

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const location = window.location;

const textSty = {
    fontSize: isMobile ? "13px" : "18px",
    // textIndent: isMobile ? "20px" : "40px",
    maxWidth: "980px",
    padding: isMobile ? "15px" : "20px",
    margin: "0 auto",
};

export default class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
            activeTab: 0,
        };
    }

    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
        /* 如果不是 dva 2.0 请删除 end */
    }

    // 服务范围
    getServiceScope() {
        return (
            <div style={textSty}>
                本服务标准针对以下几种情况提出：
                <br />
                1、
                产品超出保修期，客户未购买延保服务的产品，需要超云提供现场服务的情况；
                <br />
                2、
                产品在保修期内，但服务内容超出《超云产品标准保修政策》所规定的服务范围的情况。
            </div>
        );
    }

    // 服务种类
    getServiceTypes() {
        return (
            <div style={textSty}>
                1、 硬件安装
                <br />
                2、 硬件维修 <br />
                3、 硬件配置扩容 <br />
                4、 操作系统的安装与调试 <br />
                5、 磁盘阵列的重建、修改、创建等服务 <br /> 6、 Call Center服务
                <br />
                7、 项目服务：包括驻场、技术服务日、布线、除尘、巡检等
                本服务标准针对以下几种情况提出：
                <br />
            </div>
        );
    }

    // 特别说明
    getOtherTips() {
        return (
            <div style={textSty}>
                1、
                经超云有偿服务更换或增加的产品部件自有偿服务完成之日起享有12个月的保修期。
                <br />
                2、
                增值服务收费标准请拨打超云服务中心热线服务支持电话：4006330360。
            </div>
        );
    }

    getTitleDes() {
        return (
            <div style={{ ...textSty, textIndent: isMobile ? "20px" : "40px" }}>
                超云数字技术集团有限公司（下简称“超云”）通过统一的管理、统一的培训、统一的服务标准、统一的规范和统一的考核，使得客户可以享受到周到、完美、技术专业、形象亲和的超云售后服务。
            </div>
        );
    }

    getServicesStander() {
        return <div style={textSty}>服务标准</div>;
    }

    onClickTabItem(activeTab, id) {
        this.setState({ activeTab: activeTab });
        // document.geti
        // console.log(TAG, "BottomView next:", myRef);
        let anchorElement = document.getElementById(id);
        // console.log(
        //     "anchorElement",
        //     anchorElement,
        //     anchorElement.getBoundingClientRect()
        // );
        const yHeight = anchorElement.getBoundingClientRect().y;

        if (activeTab == 0) {
            window.scrollTo(0, 0);
        }

        // console.log("yHeight", yHeight);
        window.scrollTo({
            left: 0, // x坐标
            top: yHeight - 100, // y 坐标
            behavior: "smooth", // 可选值：smooth、instant、auto
        });
        // window.scrollTo(0, yHeight, {behavior: ‘smooth’});
    }

    onClickSearchBtn() {
        window.open("/product/search");
    }

    adaptHeight(Content50DataSource) {
        // return "auto";
        const result = this.state.isMobile
            ? Content50DataSource.block.children.length * 340 + "px"
            : Math.ceil(Content50DataSource.block.children.length / 4) * 340 +
              "px";
        return result;
    }

    render() {
        const children = [
            // <Banner
            //     id="Banner1_0"
            //     key="Banner1_0"
            //     dataSource={Banner10DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <img
            //     src="https://img2.baidu.com/it/u=3639955691,11916907&fm=253&fmt=auto&app=138&f=JPEG?w=658&h=171"
            //     alt=""
            //     style={{ width: "100%" }}
            // />,
            <div style={{ position: "relative",  background:"rgba(248, 247, 247)" }}>
                <img
                    src={require("../../assets/techsuupport_bg.png")}
                    alt=""
                    style={{ width: "100%" , }}
                />

                {/* <div
                    style={{
                        position: "absolute",
                        // top: this.state.isMobile ? "3rem" : "16rem",
                        // left: this.state.isMobile ? "2rem" : "16rem",
                        top: this.state.isMobile ? "3rem" : "40%",
                        left: this.state.isMobile ? "1rem" : "15%",
                        color: "#fff",
                        fontSize: this.state.isMobile ? "1.4rem" : "2.4vw",
                        zIndex: 100,
                        fontWeight: 700,
                    }}
                >
                    技术支持
                </div> */}
            </div>,
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "0 auto",
                    background:"rgba(248, 247, 247)" 
                }}
            >
                <CSCTitle
                    title="超云产品标准保修政策"
                    key="warranty_period"
                    isMobile={this.state.isMobile}
                    style={{
                        margin: 0,
                        height: "auto",
                        marginTop: "60px",
                        marginBottom: "40px",
                    }}
                    textSty={{
                        fontSize: this.state.isMobile ? "20px" : "28px",
                        fontWeight: "700",
                    }}
                />
            </div>,
            <div
                style={{
                    maxWidth: "1000px",
                    // display: "flex",
                    // alignItems: "center",
                    // justifyContent: "center",
                    // margin: "0 auto",
                    // marginTop: "4reme",
                    // marginBottom: "4reme",
                    // paddingLeft: "5px",
                    // paddingRight: "5px",
                    // paddingBottom: "7rem",
                    padding: "20px",
                    // background: "#f6f6f6",
                    position: "relative",
                    fontSize: "16px",
                    margin: "0 auto",
                    paddingBottom: "100px",
                    background:"rgba(248, 247, 247)" 
                    // background:"red"
                }}
                dangerouslySetInnerHTML={{
                    __html: `<p style="font-size:1.17em; margin:1.83em 0;font-weight:bold;"> 1.&nbsp;&nbsp;保修期限 </p>
<p style="text-indent: 2em; margin-bottom: 10px;line-height:1.5">
    超云数字技术集团有限公司（以下简称“超云”）对其出售的产品提供三年有限保修服务。各部件免费保修期限如下表所示：
</p>
<table class="MsoNormalTable" style="width:100%; text-align: center;">
<tbody>
<tr style="background:#F2F2F2">
<td width="120" valign="center" rowspan="2">
    <p style="text-align:center;">
        <span>产品类型</span><span></span>
    </p>
</td>
<td width="196" valign="center" rowspan="2">
    <p style="text-align:center;">
        <span>保修部件</span><span></span>
    </p>
</td>
<td width="323" valign="center" colspan="3"  style="background:#F2F2F2;">
    <p style="text-align:center;">
        <span>服务内容</span><span></span>
    </p>
</td>
</tr>
<tr >
<td width="113" valign="center" style="background:#E6E6E6;">
    <p style="text-align:center;">
        <span>现场服务</span><span></span>
    </p>
</td>
<td width="104" valign="center"  style="background:#E0E0E0;">
    <p style="text-align:center;">
        <span>人工</span><span></span>
    </p>
</td>
<td width="105" valign="center"  style="background:#D9D9D9;">
    <p style="text-align:center;">
        <span>部件、材料</span><span></span>
    </p>
</td>
</tr>
<tr style="background:#fff">
<td width="120" valign="center">
    <p style="text-align:center;">
        <span>服务器</span><span></span>
    </p>
</td>
<td width="196" valign="center">
    <p>
        <span>主板、CPU、硬盘(企业级非固态硬盘)、内存、电源、网卡、RAID控制器、其他外插板卡、风扇</span>
    </p>
</td>
<td width="113" valign="center">
    <p style="text-align:center;">
        <span style="color:#00AA71">3年免费</span><span></span>
    </p>
</td>
<td width="104" valign="center">
    <p style="text-align:center;">
        <span style="color:#00AA71">3年免费</span><span></span>
    </p>
</td>
<td width="105" valign="center">
    <p style="text-align:center;">
        <span style="color:#00AA71">3年免费</span><span></span>
    </p>
</td>
</tr>
<tr style="background:#F2F2F2">
<td width="120" valign="center">
    <p style="text-align:center;">
        <span>存储</span><span></span>
    </p>
</td>
<td width="196" valign="center">
    <p>
        <span>硬盘（企业级）、RAID控制器（包括控制器内存）、背板、电源/风扇、LCD控制面板、硬盘托架、光纤卡/SCSI卡/SAS卡</span><span></span>
    </p>
</td>
<td width="113" valign="center">
    <p style="text-align:center;">
        <span style="color:#00AA71">3年免费</span><span></span>
    </p>
</td>
<td width="104" valign="center">
    <p style="text-align:center;">
        <span style="color:#00AA71">3年免费</span><span></span>
    </p>
</td>
<td width="105" valign="center">
    <p style="text-align:center;">
        <span style="color:#00AA71">3年免费</span><span></span>
    </p>
</td>
</tr>
<tr style="background:#fff">
<td width="120" valign="center">
    <p style="text-align:center;">
        <span>固态硬盘</span><span></span>
    </p>
</td>
<td width="196" valign="center">
    <p>
        <span>（企业级SSD，包括SAS/SATA/PCI-E等接口类型）</span><span></span>
    </p>
</td>
<td width="113" valign="center">
    <p style="text-align:center;">
        <span style="color:#00AA71">3年免费</span><span></span>
    </p>
</td>
<td width="104" valign="center">
    <p style="text-align:center;">
        <span style="color:#00AA71">3年免费</span><span></span>
    </p>
</td>
<td width="105" valign="center">
    <p style="text-align:center;">
        <span style="color:#00AA71">3年有限免费<br/>高负载例外</span><span></span>
    </p>
</td>
</tr>
<tr style="background:#F2F2F2">
<td width="120" valign="center">
    <p style="text-align:center;">
        <span>其他部件</span><span></span>
    </p>
</td>
<td width="196" valign="center">
    <p>
        <span>锂电池、SFP（光纤收发器模块）、SCSI&nbsp;/&nbsp;FC&nbsp;/&nbsp;SAS&nbsp;/&nbsp;RS232信号传输线缆、光驱、软驱、LCD显示器</span><span></span>
    </p>
</td>
<td width="113" valign="center">
    <p style="text-align:center;">
        <span style="color:#00AA71">1年免费</span><span></span>
    </p>
</td>
<td width="104" valign="center">
    <p style="text-align:center;">
        <span style="color:#00AA71">1年免费</span><span></span>
    </p>
</td>
<td width="105" valign="center">
    <p style="text-align:center;">
        <span style="color:#00AA71">1年免费</span><span></span>
    </p>
</td>
</tr>
<tr style="background:#fff">
<td width="120" valign="center">
    <p style="text-align:center;">
        <span>机柜配套产品</span><span></span>
    </p>
</td>
<td width="196" valign="center">
    <p>
        <span>机柜、KVM、PDU</span><span></span>
    </p>
</td>
<td width="113" valign="center">
    <p style="text-align:center;">
        <span style="color:#00AA71">1年免费</span><span></span>
    </p>
</td>
<td width="104" valign="center">
    <p style="text-align:center;">
        <span style="color:#00AA71">1年免费</span><span></span>
    </p>
</td>
<td width="105" valign="center">
    <p style="text-align:center;">
        <span style="color:#00AA71">1年免费</span><span></span>
    </p>
</td>
</tr>
<tr style="background:#F2F2F2">
<td width="120" valign="center">
    <p style="text-align:center;">
        <span>服务器配套产品</span><span></span>
    </p>
</td>
<td width="196" valign="center">
    <p>
        <span>随机光盘、软盘、机箱外塑料部件、键盘、鼠标</span><span></span>
    </p>
</td>
<td width="113" valign="center">
    <p style="text-align:center;">
        <span style="color:#00AA71">3个月免费</span><span></span>
    </p>
</td>
<td width="104" valign="center">
    <p style="text-align:center;">
        <span style="color:#00AA71">3个月免费</span><span></span>
    </p>
</td>
<td width="105" valign="center">
    <p style="text-align:center;">
        <span style="color:#00AA71">3个月免费</span><span></span>
    </p>
</td>
</tr>
<tr style="background:#fff">
<td width="120" valign="center">
    <p style="text-align:center;">
        <span>单独销售或客户单独购买的超云配件类产品</span>
    </p>
</td>
<td width="196" valign="center">
    <p>
        <span>服务器平台、服务器主板、CPU、硬盘（含机械硬盘和固态硬盘）、内存、阵列卡、网卡、光纤卡、显卡等</span>
    </p>
</td>
<td width="113" valign="center">
    <p style="text-align:center;">
        <span style="color:#00AA71">无</span><span></span>
    </p>
</td>
<td width="104" valign="center">
    <p style="text-align:center;">
        <span style="color:#00AA71">无</span><span></span>
    </p>
</td>
<td width="105" valign="center">
    <p style="text-align:center;">
        <span style="color:#00AA71">1年免费</span><span></span>
    </p>
</td>
</tr>
<tr style="background:#F2F2F2">
<td width="120" valign="center" rowspan="2">
    <p style="text-align:center;">
        <span >其他</span><span></span>
    </p>
</td>
<td width="196" valign="center">
    <p>
        <span >非超云生产产品</span><span></span>
    </p>
</td>
<td width="323" valign="center" colspan="3">
    <p style="text-align:center;">
        <span style="color:#00AA71">以原厂保修服务说明为准</span><span></span>
    </p>
</td>
</tr>
<tr style="background:#F2F2F2">
<td width="196" valign="center">
    <p>
        <span>其他设备及问题</span><span></span>
    </p>
</td>
<td width="323" valign="center" colspan="3">
    <p style="text-align:center;">
        <span style="color:#00AA71">请向超云咨询</span><span></span>
    </p>
</td>
</tr>
</tbody>
</table>
<p style="line-height:1.5">说明：</p>
<p class="NewStyle15" style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    1）产品保修的起始日期为产品到货日期。如用户不能提供有效的到货日期证明时，以超云记录的产品出厂日期加一个月作为产品的保修起始日期。
</p>
<p class="NewStyle15" style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    2）单独销售或客户单独购买的超云配件类产品，自购买之日起一年内免费保修，保修方式为发货更换，不含免费的上门服务。
</p>
<p class="NewStyle15" style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    3）客户购买的用于原机器扩容使用的零部件，其保修期随同原整机产品的保修期计算, 如该整机产品保修期剩余不足一年，以零部件和整机两者剩余保修期较长者计算。
</p>
<p class="NewStyle15" style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    4）固态硬盘保修期届满，或固态硬盘SMART属性如“介质磨损”值、“使用百分比估计”值等衡量指标达到固态硬盘厂商规定的保修终止指标时，保修期终止，以先到者为准。
</p>
<p class="NewStyle15" style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    5）如整机保修期结束后整机内含有扩容且未过保的零部件发生故障，保修方式为发货更换，不含免费的上门服务，超云支持远程指导排查故障。
</p>
<p class="NewStyle15" style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    6）经超云维修后的产品部件，其保修期随同原整机产品的保修期计算。如修复之日距原整机产品的保修期结束之日不足三个月，则该部件自修复之日起保修三个月。
</p>
<p class="NewStyle15" style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    7）维修所使用部件在关键功能上等同于或高于原部件。
</p>
<p class="NewStyle15" style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    8）维修替换下的部件归超云所有，用户必须保持故障部件的原始使用状态。如果由于用户自身原因 （如科研、保密等）要求保留、拆解或改装瑕疵部件时，该瑕疵部件即失去保修资格。
</p>
<p class="NewStyle15" style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    9）对于合同中有要求硬盘免回收服务的，超云有权将替换下来的故障硬盘壳拆卸下来收回或将硬盘现场销毁。
</p>
<p class="NewStyle15" style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    10）客户购买的用于服务器的PC级硬盘零部件，自购买之日起超云不对其进行保修。
</p>
<p class="NewStyle15" style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    11）超云软件产品向客户提供第一年的免费远程技术支持、免费升级服务。从第二年起提供付费的技术支持服务。
</p>
<p style="font-size:1.17em; margin:1.83em 0;font-weight:bold;">2.&nbsp;&nbsp;保修服务流程</p>
<p style="line-height:1.5">2.1&nbsp;&nbsp;超云服务热线</p>
<p style="text-indent:21pt;">
    超云面向全国客户提供7x24小时的服务热线支持，客户可随时拨打超云服务热线4006-330-360 寻求支持和帮助。
</p>
<p style="line-height:1.5">2.2&nbsp;&nbsp;保修确认手续</p>
<p style="text-indent:21pt; line-height:1.5">用户报修时需要提供如下信息：</p>
<p class="NewStyle15" style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    1）产品型号；
</p>
<p class="NewStyle15" style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    2）整机序列号（S/N）；
</p>
<p class="NewStyle15" style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    3）系统的软硬件配置、详细的故障描述和系统错误信息；
</p>
<p class="NewStyle15" style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    4）用户单位名称、联系人、联系方式及详细地址。
</p>
<p style="line-height:1.5">2.3&nbsp;&nbsp;保修服务流程</p>
<p style="text-align:center;margin-top:10px;margin-bottom:10px;">
    <img  style="max-width:100%; width:1400px; " src="${require("../../assets/lc.png")}" />
</p>
<p style="font-size:1.17em; margin:1.83em 0;font-weight:bold;">3.&nbsp;&nbsp;保修响应时间</p>
<p style="text-indent:21pt;">
    超云向全国范围内的用户提供每周7天每天24小时的服务响应，客户可随时拨打超云的免费服务热线4006-330-360报修。
</p>
<p style="text-indent:21pt; line-height:1.5">
    客户在报修时需提供如下信息：
</p>
<p class="NewStyle15" style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    1）产品型号；
</p>
<p class="NewStyle15" style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    2）整机序列号（S/N）；
</p>
<p class="NewStyle15" style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    3）系统的软硬件配置、详细的故障描述和系统错误信息；
</p>
<p class="NewStyle15" style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    4）用户单位名称、联系人、联系方式及详细地址。
</p>
<p style="text-indent:21pt; line-height:1.5">
    接到报修后，超云的技术服务人员将于2小时内进行电话响应，及时联系客户解决问题。如果确认需要现场服务，超云的技术服务人员将会根据客户所处的位置，与客户电话预约登门时间，并将在第二个工作日抵达现场（或在途）。
</p>
<p style="font-size:1.17em; margin:1.83em 0;font-weight:bold;">4.&nbsp;&nbsp;保修内容和方式</p>
<p style="text-indent:21pt; line-height:1.5">
    超云将根据具体情况选择以下所列的一种或多种合适的方式提供保修服务。
</p>
<p style="line-height:1.5">4.1&nbsp;&nbsp;远程问题诊断和技术支持</p>
<p style="text-indent:21pt; line-height:1.5">
    客户拨打超云售后服务热线提出服务请求后，超云将与客户合作共同解决问题。在提供任何必要的现场服务之前，超云将首先使用电话或远程支持服务工具（可行的情况下）对超云提供支持的产品进行远程问题诊断，或通过其它方式为远程解决问题提供帮助。
</p>
<p style="line-height:1.5">4.2&nbsp;&nbsp;发货更换</p>
<p style="text-indent:21pt; line-height:1.5">
    对于保修期内能简单安装的部件（包括但不限于热拔插硬盘、热拔插电源、显示器、键盘、鼠标），或者客户单独购买的服务器零部件，超云可通过发货更换的方式进行维修，并提供相应的电话安装指导。超云负责将备件寄往客户处的单程运费。客户负责将维修时替换下来的部件寄回至超云并负担单程运费。
</p>
<p style="line-height:1.5">4.3&nbsp;&nbsp;现场硬件更换服务</p>
<p style="text-indent:21pt; line-height:1.5">
    如果硬件问题不能通过远程方式或发货更换方式解决，超云将派遣现场服务工程师到客户现场进行硬件的维修，客户有责任提供合理的维修操作条件，包括但不限于超云现场服务工程师进入客户机房的权限、维修时间的申请、适合的电源等。
</p>
<p style="line-height:1.5">4.4&nbsp;&nbsp;备件和材料</p>
<p style="text-indent:21pt; line-height:1.5">
    超云将提供维修所需的备件和材料，确保超云提供支持的硬件设备恢复正常运行。维修更换下来的产品部件归超云所有。
</p>
<p style="line-height:1.5">4.5&nbsp;&nbsp;不间断工作</p>
<p style="text-indent:21pt; line-height:1.5">
    超云的现场服务工程师到达客户现场后，立即开始进行不间断服务，直至设备能够重新运行。如需额外的备件或资源，服务工作可暂停，直至这些备件或资源备齐。
</p>
<p style="font-size:1.17em; margin:1.83em 0;font-weight:bold;">5.&nbsp;&nbsp;保修服务范围</p>
<p style="line-height:1.5">
    5.1&nbsp;&nbsp;本服务政策服务区域仅限于中国大陆地区，港澳台除外；
</p>
<p style="line-height:1.5">
    5.2&nbsp;&nbsp;产品到货时的初次安装超云提供有限电话支持，如需现场服务需要购买超云公司服务产品；
</p>
<p style="line-height:1.5">
    5.3&nbsp;&nbsp;本服务政策仅限于超云在保修期内正常使用时出现的硬件故障；
</p>
<p style="line-height:1.5">
    5.4&nbsp;&nbsp;超云不承担由于产品故障给用户造成的损失（如设备停机、数据丢失等）；
</p>
<p style="line-height:1.5">
    5.5&nbsp;&nbsp;对于超出服务范围的服务，或保修期外的服务，超云提供有偿服务，具体事宜可向超云服务热线咨询；
</p>
<p style="line-height:1.5">
    5.6&nbsp;&nbsp;下列情况不属于保修服务范围：
</p>
<p style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    1）无超云标识或标识残缺的产品；
</p>
<p style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    2）产品内部的电子元器件对潮湿、酸碱浓度高的环境敏感，湿气、酸碱类物质同污物积聚会形成腐蚀性物质，损害电子元器件，导致产品故障，请用户避免在此类环境中储存和使用产品，由此造成的产品故障不在产品保修范围内；
</p>
<p style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    3）未按说明书要求，非产品所规定的工作环境或错误安装、保管及使用等造成的故障或损坏（例如温度过高、过低，过于潮湿或干燥，海拔过高，非正常的物理压力，电磁干扰，供电不稳，静电干扰，零地电压过大，输入不合适的电压等）；
</p>
<p style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    4）由非超云授权机构人员安装、修理、更改或拆卸而造成的故障或损坏；
</p>
<p style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    5）因使用非超云提供的部件而导致的故障或损坏；
</p>
<p style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    6）因使用盗版或其他非合法授权软件、非标准或未公开发行的软件造成的故障或损坏；
</p>
<p style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    7）因意外因素或人为原因（包括但不限于计算机病毒、操作失误、进液、划伤、搬运、 磕碰、不正确插拔、异物掉入、鼠害、虫害等）导致的故障或损坏；
</p>
<p style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    8）因遗忘口令而导致的故障；
</p>
<p style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    9）因客户误操作而破坏随机配置软件系统导致的故障；
</p>
<p style="margin-left:42pt;text-indent:-21pt; line-height:1.5">
    10）因自然灾害等不可抗力（如雷击、火灾、地震等）原因造成的故障或损坏；
</p>
<p style="font-size:1.17em; margin:1.83em 0;font-weight:bold;">6.&nbsp;&nbsp;特别提示与说明</p>
<p style="line-height:1.5">6.1&nbsp;&nbsp;优先适用</p>
<p class="p" style="text-indent:21pt; line-height:1.5">
    本说明如果出现与国家相关法律法规相违背的情况，以相关法律法规为准。
</p>
<p style="line-height:1.5">6.2&nbsp;&nbsp;超云否认的承诺</p>
<p style="text-indent:21pt; line-height:1.5">
    除非本说明中明确表示，超云不做任何其它明示或暗示的承诺和保证，包括对产品的可销性和对某一特定用途的适用性的暗示保证。除非超云另外做出明确承诺，否则：
</p>
<p class="NewStyle15" style="text-indent:21pt; line-height:1.5">
    1）本保修说明仅适用于超云产品出厂时配置的主机和部件（详见客户订单）。任何机构或人员（如销售商）给客户安装的一切非超云部件，超云不承担保修责任。 
</p>
<p class="NewStyle15" style="text-indent:21pt; line-height:1.5">
    2）任何机构和人员（如销售商）在本保修说明之外就客户购买的产品及其附属软硬件设备向客户做出的任何额外承诺，超云将不承担责任；客户应向做出该承诺的机构或人员索要书面证明，以保证这些额外承诺能够兑现。
</p>
<p class="NewStyle15" style="text-indent:21pt; line-height:1.5">
    3）任何机构和人员（如销售商、客户）对超云出厂时配置的产品（详见客户订单）进行了拆装或改配，该产品即失去保修资格。
</p>
<p style="line-height:1.5">6.3&nbsp;&nbsp;数据备份</p>
<p style="text-indent:21pt; line-height:1.5">
    请您在接受服务前务必及时将您认为有价值的信息程序进行备份或取回，超云及超云认证服务机构仅负责产品的维修与检测，本文件规定的服务范围内恕不提供任何数据恢复与备份。超云及其认证服务机构不对数据、程序或存储介质的损坏或丢失承担责任。  
</p>
<p style="line-height:1.5">6.4&nbsp;&nbsp;替换整机或部件的所有权</p>
<p style="text-indent:21pt; line-height:1.5">
    超云为客户更换整机或故障部件后，被更换的原机器或故障部件将由超云收回并享有所有权。
</p>
<p style="line-height:1.5">6.5&nbsp;&nbsp;本说明的解释权归超云数字技术集团有限公司。</p>`,
                }}
            ></div>,
        ];
        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
                style={{ background:"rgb(248, 247, 247)" , minHeight:"100vh"}}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {this.state.show && children}

                <CSCBackTop
                    isMobile={this.state.isMobile}
                    key="warnnty_policy"
                />
                {/* 如果不是 dva 2.0 替换成 {children} end */}
            </div>
        );
    }
}

const style = React.cree;
