import React from "react";
// import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import { Row, Col } from "antd";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import config from "../libs/config";

class Content extends React.PureComponent {
    getBlockChildren = (data) =>
        data.map((item, i) => {
            const children = item.children;
            return (
                <Col key={i.toString()} {...item}>
                    <div {...children.icon}>
                        <img
                            src={children.icon.children}
                            width="100%"
                            alt="img"
                        />
                    </div>
                    {/* <h3>{this.props.content0title}</h3> */}
                    <h3 {...children.title}>{children.title.children}</h3>
                    <p {...children.content}>{children.content.children}</p>
                </Col>
            );
        });

    render() {
        const { solutionDetailObj = {}, ...props } = this.props;
        // console.log("propsprops", props);
        const { dataSource, contentTxt = "", id } = props;
        delete props.dataSource;
        // delete props.isMobile;
        const listChildren = this.getBlockChildren(dataSource.block.children);
        return (
            <div {...props} {...dataSource.wrapper}>
                <div {...dataSource.page}>
                    <div
                        {...dataSource.titleWrapper}
                        style={{
                            color: "rgba(0,0,0,0.6)",
                            fontSize: "32px",
                            fontWeight: "600",
                        }}
                    >
                        {this.props.content0title}
                        {/* {dataSource.titleWrapper.children.map((item, i) =>
                            React.createElement(
                                item.name.indexOf("title") === 0 ? "h1" : "div",
                                { key: i.toString(), ...item },
                                item.children.match(
                                    /\.(svg|gif|jpg|jpeg|png|JPG|PNG|GIF|JPEG)$/
                                )
                                    ? React.createElement("img", {
                                          src: item.children,
                                          height: "100%",
                                          alt: "img",
                                      })
                                    : item.children
                            )
                        )} */}
                    </div>
                    <OverPack {...dataSource.OverPack}>
                        <TweenOne
                            type="bottom"
                            key="block"
                            leaveReverse
                            {...dataSource.block}
                            component={Row}
                            forcedReplay={false}
                        >
                            {/* {listChildren} */}
                            <div className="bg_section" key={"bg_section_1"}>
                                <div
                                    // key={"aa_machine"}
                                    style={{
                                        maxWidth: "1200px",
                                        textAlign: "start",
                                        margin: "0 auto",
                                        fontSize: props.isMobile
                                            ? "1rem"
                                            : "1.1rem",
                                        lineHeight: "2rem",
                                        marginTop: "0.8rem",
                                        // textIndent: "30px",
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: contentTxt,
                                    }}
                                    // dangerouslySetInnerHTML={{
                                    //     __html: `1、协助政府部门构建了架构开放、兼容性强和面向应用的电子政务云平台；

                                    //           <br />2、提高政府信息化投资的效能，提高了基础硬件和使用效率以及数据的共享能力；

                                    //           <br />3、电子政务云平台实现了对多用户多业务的灵活支撑，满足业务对资源的弹性需求；

                                    //           <br />4、电子政务云平台实现了用户服务申请与业务运营流程的自动化，提高政务服务水平。`,
                                    // }}
                                ></div>
                                {solutionDetailObj.solution_bg_img &&
                                id == "solution" ? (
                                    <div
                                        style={{
                                            background: "#f9f9f9",
                                            // maxWidth: "1000px",
                                            minWidth: "2rem",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginTop: "20px",
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: "70%",
                                                marginTop: "40px",
                                                marginBottom: "40px",
                                                background: "#f9f9f9",
                                            }}
                                            src={
                                                config.pic_url +
                                                solutionDetailObj.solution_plan_img
                                            }
                                            alt=""
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </TweenOne>
                    </OverPack>
                </div>
            </div>
        );
    }
}

export default Content;
