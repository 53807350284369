import React from 'react'

export const Banner10DataSource = {
    wrapper: {className: 'banner1'},
    BannerAnim: {
        children: [
            {
                name: 'elem0',
                BannerElement: {className: 'banner-user-elem'},
                textWrapper: {className: 'banner1-text-wrapper'},
                bg: {className: 'bg bg0'},
                title: {
                    className: 'banner1-title',
                    children:
                        'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
                },
                content: {
                    className: 'banner1-content',
                    children: '一个高效的页面动画解决方案',
                },
                button: {className: 'banner1-button', children: 'Learn More'},
            },
            {
                name: 'elem1',
                BannerElement: {className: 'banner-user-elem'},
                textWrapper: {className: 'banner1-text-wrapper'},
                bg: {className: 'bg bg1'},
                title: {
                    className: 'banner1-title',
                    children:
                        'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
                },
                content: {
                    className: 'banner1-content',
                    children: '一个高效的页面动画解决方案',
                },
                button: {className: 'banner1-button', children: 'Learn More'},
            },
            {
                name: 'elem2',
                BannerElement: {className: 'banner-user-elem'},
                textWrapper: {className: 'banner1-text-wrapper'},
                bg: {className: 'bg bg1'},
                title: {
                    className: 'banner1-title',
                    children:
                        'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
                },
                content: {
                    className: 'banner1-content',
                    children: '一个高效的页面动画解决方案',
                },
                button: {className: 'banner1-button', children: 'Learn More'},
            },
        ],
    },
}
export const ContentAdd00DataSource = {
    wrapper: {className: 'home-page-wrapper content0-wrapper'},
    page: {className: 'home-page content0'},
    OverPack: {playScale: 0.3, className: ''},
    titleWrapper: {
        className: 'title-wrapper',
        children: [{name: 'title', children: '最新产品'}],
    },
    childWrapper: {
        className: 'content0-block-wrapper',
        children: [
            {
                name: 'block0',
                className: 'content0-block',
                md: 8,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [
                        {
                            name: 'image',
                            className: 'content0-block-icon',
                            children:
                                'https://t12.baidu.com/it/u=565705571,1599975429&fm=173&app=49&f=PNG?w=500&h=333&s=A6A393AA5C3A0D8E9F8FD1D303009097',
                        },
                        {
                            name: 'title',
                            className: 'content0-block-title',
                            children: '融智筑元 互联网上的“数字宇宙”',
                        },
                        {
                            name: 'content',
                            children: '百家号 | 2022-06-24 11:06',
                        },
                    ],
                },
            },
            {
                name: 'block1',
                className: 'content0-block',
                md: 8,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [
                        {
                            name: 'image',
                            className: 'content0-block-icon',
                            children:
                                'https://nimg.ws.126.net/?url=https%3A%2F%2Fcrawl.ws.126.net%2Fnbot2%2Fimg%2F1acf65e0c91033a2ddf281304d6c0223.png&thumbnail=660x2147483647&quality=80&type=jpg',
                        },
                        {
                            name: 'title',
                            className: 'content0-block-title',
                            children:
                                '共赢数据中心未来，摩尔线程与超云达成战略合作',
                        },
                        {
                            name: 'content',
                            children: '百家号 | 2022-06-24 11:06',
                        },
                    ],
                },
            },
            {
                name: 'block2',
                className: 'content0-block',
                md: 8,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [
                        {
                            name: 'image',
                            className: 'content0-block-icon',
                            children:
                                'https://p7.itc.cn/q_70/images01/20210720/5125f6056b0d4619bd991f8696560ed3.jpeg',
                        },
                        {
                            name: 'title',
                            className: 'content0-block-title',
                            children:
                                '蚂蚁集群亮相Web3.0中国峰会 | CEO杜鹏解读赛道商机 ',
                        },
                        {
                            name: 'content',
                            children: '搜狐新闻 | 2021-07-20 17:56',
                        },
                    ],
                },
            },
        ],
    },
}
export const Content00DataSource = {
    wrapper: {className: 'home-page-wrapper content0-wrapper'},
    page: {className: 'home-page content0'},
    OverPack: {playScale: 0.3, className: ''},
    titleWrapper: {
        className: 'title-wrapper',
        children: [{name: 'title', children: '新闻动态'}],
    },
    childWrapper: {
        className: 'content0-block-wrapper',
        children: [],
    },
}
export const Content10DataSource = {
    wrapper: {className: 'home-page-wrapper content1-wrapper'},
    OverPack: {className: 'home-page content1', playScale: 0.3},
    imgWrapper: {className: 'content1-img', md: 10, xs: 24},
    img: {
        children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
    },
    textWrapper: {className: 'content1-text', md: 14, xs: 24},
    title: {className: 'content1-title', children: '企业资源管理'},
    content: {
        className: 'content1-content',
        children:
            '云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。',
    },
}
export const Content30DataSource = {
    wrapper: {className: 'home-page-wrapper content3-wrapper'},
    page: {className: 'home-page content3'},
    OverPack: {playScale: 0.3},
    titleWrapper: {
        className: 'title-wrapper',
        children: [
            {
                name: 'title',
                children: '蚂蚁金融云提供专业的服务',
                className: 'title-h1',
            },
            {
                name: 'content',
                className: 'title-content',
                children: '基于阿里云强大的基础资源',
            },
        ],
    },
    block: {
        className: 'content3-block-wrapper',
        children: [
            {
                name: 'block0',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children:
                            'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
                    },
                    textWrapper: {className: 'content3-text'},
                    title: {
                        className: 'content3-title',
                        children: '企业资源管理',
                    },
                    content: {
                        className: 'content3-content',
                        children:
                            '云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。',
                    },
                },
            },
            {
                name: 'block1',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children:
                            'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
                    },
                    textWrapper: {className: 'content3-text'},
                    title: {className: 'content3-title', children: '云安全'},
                    content: {
                        className: 'content3-content',
                        children:
                            '按金融企业安全要求打造的完整云上安全体系，全方位保障金融应用及数据安全。',
                    },
                },
            },
            {
                name: 'block2',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children:
                            'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
                    },
                    textWrapper: {className: 'content3-text'},
                    title: {className: 'content3-title', children: '云监控'},
                    content: {
                        className: 'content3-content',
                        children:
                            '分布式云环境集中监控，统一资源及应用状态视图，智能分析及故障定位。',
                    },
                },
            },
            {
                name: 'block3',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children:
                            'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
                    },
                    textWrapper: {className: 'content3-text'},
                    title: {className: 'content3-title', children: '移动'},
                    content: {
                        className: 'content3-content',
                        children:
                            '一站式移动金融APP开发及全面监控；丰富可用组件，动态发布和故障热修复。',
                    },
                },
            },
            {
                name: 'block4',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children:
                            'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
                    },
                    textWrapper: {className: 'content3-text'},
                    title: {
                        className: 'content3-title',
                        children: '分布式中间件',
                    },
                    content: {
                        className: 'content3-content',
                        children:
                            '金融级联机交易处理中间件，大规模分布式计算机，数万笔/秒级并发能力，严格保证交易数据统一性。',
                    },
                },
            },
            {
                name: 'block5',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children:
                            'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
                    },
                    textWrapper: {className: 'content3-text'},
                    title: {className: 'content3-title', children: '大数据'},
                    content: {
                        className: 'content3-content',
                        children:
                            '一站式、全周期大数据协同工作平台，PB级数据处理、毫秒级数据分析工具。',
                    },
                },
            },
        ],
    },
}
export const Content120DataSource = {
    wrapper: {className: 'home-page-wrapper content12-wrapper'},
    page: {className: 'home-page content12'},
    OverPack: {playScale: 0.3, className: ''},
    titleWrapper: {
        className: 'title-wrapper',
        children: [
            {
                name: 'image',
                children:
                    'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
                className: 'title-image',
            },
            {name: 'title', children: '合作客户', className: 'title-h1'},
        ],
    },
    block: {
        className: 'img-wrapper',
        children: [
            {
                name: 'block0',
                className: 'block',
                md: 8,
                xs: 24,
                children: {
                    wrapper: {className: 'block-content'},
                    img: {
                        children:
                            'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fnimg.ws.126.net%2F%3Furl%3Dhttp%3A%2F%2Fdingyue.ws.126.net%2F2021%2F1117%2F29ec93d4j00r2oceb002yd200lo00j5g008e007e.jpg%26thumbnail%3D650x2147483647%26quality%3D80%26type%3Djpg&refer=http%3A%2F%2Fnimg.ws.126.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1658996075&t=19c7a6cd10e42f9e08ecf1809f1bea3c',
                    },
                },
            },
            {
                name: 'block1',
                className: 'block',
                md: 8,
                xs: 24,
                children: {
                    wrapper: {className: 'block-content'},
                    img: {
                        children:
                            'https://gw.alipayobjects.com/zos/rmsportal/hkLGkrlCEkGZeMQlnEkD.svg',
                    },
                },
            },
            {
                name: 'block2',
                className: 'block',
                md: 8,
                xs: 24,
                children: {
                    wrapper: {className: 'block-content'},
                    img: {
                        children:
                            'https://gw.alipayobjects.com/zos/rmsportal/bqyPRSZmhvrsfJrBvASi.svg',
                    },
                },
            },
            {
                name: 'block3',
                className: 'block',
                md: 8,
                xs: 24,
                children: {
                    wrapper: {className: 'block-content'},
                    img: {
                        children:
                            'https://gw.alipayobjects.com/zos/rmsportal/UcsyszzOabdCYDkoPPnM.svg',
                    },
                },
            },
            {
                name: 'block4',
                className: 'block',
                md: 8,
                xs: 24,
                children: {
                    wrapper: {className: 'block-content'},
                    img: {
                        children:
                            'https://gw.alipayobjects.com/zos/rmsportal/kRBeaICGexAmVjqBEqgw.svg',
                    },
                },
            },
            {
                name: 'block5',
                className: 'block',
                md: 8,
                xs: 24,
                children: {
                    wrapper: {className: 'block-content'},
                    img: {
                        children:
                            'https://gw.alipayobjects.com/zos/rmsportal/ftBIiyJcCHpHEioRvPsV.svg',
                    },
                },
            },
        ],
    },
}
export const Feature10DataSource = {
    wrapper: {className: 'home-page-wrapper content1-wrapper'},
    OverPack: {className: 'home-page content1', playScale: 0.3},
    imgWrapper: {className: 'content1-img', md: 10, xs: 24},
    img: {
        children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
    },
    textWrapper: {className: 'content1-text', md: 14, xs: 24},
    title: {className: 'content1-title', children: '企业资源管理'},
    content: {
        className: 'content1-content',
        children:
            '云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。',
    },
}
export const Feature20DataSource = {
    wrapper: {className: 'home-page-wrapper content2-wrapper'},
    OverPack: {className: 'home-page content2', playScale: 0.3},
    imgWrapper: {className: 'content2-img', md: 10, xs: 24},
    img: {
        children: 'https://zos.alipayobjects.com/rmsportal/tvQTfCupGUFKSfQ.png',
    },
    textWrapper: {className: 'content2-text', md: 14, xs: 24},
    title: {className: 'content2-title', children: '分布式中间件'},
    content: {
        className: 'content2-content',
        children:
            '金融级联机交易处理中间件，大规模分布式计算机，数万笔/秒级并发能力，严格保证交易数据统一性。金融级联机交易处理中间件，大规模分布式计算机，数万笔/秒级并发能力，严格保证交易数据统一性。',
    },
}
export const Feature60DataSource = {
    wrapper: {className: 'home-page-wrapper feature6-wrapper'},
    OverPack: {className: 'home-page feature6', playScale: 0.3},
    Carousel: {
        className: 'feature6-content',
        dots: false,
        wrapper: {className: 'feature6-content-wrapper'},
        titleWrapper: {
            className: 'feature6-title-wrapper',
            barWrapper: {
                className: 'feature6-title-bar-wrapper',
                children: {className: 'feature6-title-bar'},
            },
            title: {className: 'feature6-title'},
        },
        children: [
            {
                title: {
                    className: 'feature6-title-text',
                    children: '服务指标',
                },
                className: 'feature6-item',
                name: 'block0',
                children: [
                    {
                        md: 8,
                        xs: 24,
                        className: 'feature6-number-wrapper',
                        name: 'child0',
                        number: {
                            className: 'feature6-number',
                            unit: {
                                className: 'feature6-unit',
                                children: '万',
                            },
                            toText: true,
                            children: '116',
                        },
                        children: {
                            className: 'feature6-text',
                            children: '模型数据',
                        },
                    },
                    {
                        md: 8,
                        xs: 24,
                        className: 'feature6-number-wrapper',
                        name: 'child1',
                        number: {
                            className: 'feature6-number',
                            unit: {
                                className: 'feature6-unit',
                                children: '亿',
                            },
                            toText: true,
                            children: '1.17',
                        },
                        children: {
                            className: 'feature6-text',
                            children: '模型迭代数量',
                        },
                    },
                    {
                        md: 8,
                        xs: 24,
                        className: 'feature6-number-wrapper',
                        name: 'child2',
                        number: {
                            className: 'feature6-number',
                            unit: {
                                className: 'feature6-unit',
                                children: '亿',
                            },
                            toText: true,
                            children: '2.10',
                        },
                        children: {
                            className: 'feature6-text',
                            children: '训练样本数量',
                        },
                    },
                ],
            },
            {
                title: {
                    className: 'feature6-title-text',
                    children: '服务指标',
                },
                className: 'feature6-item',
                name: 'block1',
                children: [
                    {
                        md: 8,
                        xs: 24,
                        name: 'child0',
                        className: 'feature6-number-wrapper',
                        number: {
                            className: 'feature6-number',
                            unit: {
                                className: 'feature6-unit',
                                children: '万',
                            },
                            toText: true,
                            children: '116',
                        },
                        children: {
                            className: 'feature6-text',
                            children: '模型数据',
                        },
                    },
                    {
                        md: 8,
                        xs: 24,
                        name: 'child1',
                        className: 'feature6-number-wrapper',
                        number: {
                            className: 'feature6-number',
                            unit: {
                                className: 'feature6-unit',
                                children: '亿',
                            },
                            toText: true,
                            children: '1.17',
                        },
                        children: {
                            className: 'feature6-text',
                            children: '模型迭代数量',
                        },
                    },
                    {
                        md: 8,
                        xs: 24,
                        name: 'child2',
                        className: 'feature6-number-wrapper',
                        number: {
                            className: 'feature6-number',
                            unit: {
                                className: 'feature6-unit',
                                children: '亿',
                            },
                            toText: true,
                            children: '2.10',
                        },
                        children: {
                            className: 'feature6-text',
                            children: '训练样本数量',
                        },
                    },
                ],
            },
        ],
    },
}
export const Feature40DataSource = {
    wrapper: {className: 'home-page-wrapper content6-wrapper'},
    OverPack: {className: 'home-page content6'},
    textWrapper: {className: 'content6-text', xs: 24, md: 10},
    titleWrapper: {
        className: 'title-wrapper',
        children: [
            {
                name: 'title',
                children: '蚂蚁金融云提供专业的服务',
                className: 'title-h1',
            },
            {
                name: 'content',
                className: 'title-content',
                children: '基于阿里云计算强大的基础资源',
            },
        ],
    },
    img: {
        children: 'https://zos.alipayobjects.com/rmsportal/VHGOVdYyBwuyqCx.png',
        className: 'content6-img',
        xs: 24,
        md: 14,
    },
    block: {
        children: [
            {
                name: 'block0',
                img: {
                    children:
                        'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
                    className: 'content6-icon',
                },
                title: {className: 'content6-title', children: '技术'},
                content: {
                    className: 'content6-content',
                    children:
                        '丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。',
                },
            },
            {
                name: 'block1',
                img: {
                    className: 'content6-icon',
                    children:
                        'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
                },
                title: {className: 'content6-title', children: '融合'},
                content: {
                    className: 'content6-content',
                    children:
                        '解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。',
                },
            },
            {
                name: 'block2',
                img: {
                    className: 'content6-icon',
                    children:
                        'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
                },
                title: {className: 'content6-title', children: '开发'},
                content: {
                    className: 'content6-content',
                    children:
                        '符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。',
                },
            },
        ],
    },
}
export const Content50DataSource = {
    wrapper: {className: 'home-page-wrapper content5-wrapper'},
    page: {className: 'home-page content5'},
    OverPack: {playScale: 0.3, className: ''},
    titleWrapper: {
        className: 'title-wrapper',
        children: [
            {name: 'title', children: '合作客户', className: 'title-h1'},
            {
                name: 'content',
                className: 'title-content',
                children: '',
            },
        ],
    },
    block: {
        className: 'content5-img-wrapper',
        gutter: 16,
        children: [
            // {
            //     name: "block0",
            //     className: "block",
            //     span: 5,
            //     children: {
            //         wrapper: { className: "content5-block-content" },
            //         img: {
            //             children:
            //                 "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fnimg.ws.126.net%2F%3Furl%3Dhttp%3A%2F%2Fdingyue.ws.126.net%2F2021%2F1117%2F29ec93d4j00r2oceb002yd200lo00j5g008e007e.jpg%26thumbnail%3D650x2147483647%26quality%3D80%26type%3Djpg&refer=http%3A%2F%2Fnimg.ws.126.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1658996075&t=19c7a6cd10e42f9e08ecf1809f1bea3c",
            //         },
            //         content: { children: "中山大学" },
            //     },
            // },
        ],
    },
}
export const Feature50DataSource = {
    wrapper: {className: 'home-page-wrapper content7-wrapper'},
    page: {className: 'home-page content7'},
    OverPack: {},
    titleWrapper: {
        className: 'title-wrapper',
        children: [
            {
                name: 'title',
                children: '蚂蚁金融云提供专业的服务',
                className: 'title-h1',
            },
            {name: 'content', children: '基于阿里云计算强大的基础资源'},
        ],
    },
    tabsWrapper: {className: 'content7-tabs-wrapper'},
    block: {
        children: [
            {
                name: 'block0',
                tag: {
                    className: 'content7-tag',
                    text: {children: 'PHONE', className: 'content7-tag-name'},
                    icon: {children: 'mobile'},
                },
                content: {
                    className: 'content7-content',
                    text: {
                        className: 'content7-text',
                        md: 14,
                        xs: 24,
                        children: (
                            <span>
                                <h3>技术</h3>
                                <p>
                                    丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。
                                </p>
                                <br />
                                <h3>融合</h3>
                                <p>
                                    解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。
                                </p>
                                <br />
                                <h3>开放</h3>
                                符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。
                            </span>
                        ),
                    },
                    img: {
                        className: 'content7-img',
                        children:
                            'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
                        md: 10,
                        xs: 24,
                    },
                },
            },
            {
                name: 'block1',
                tag: {
                    className: 'content7-tag',
                    icon: {children: 'tablet'},
                    text: {
                        className: 'content7-tag-name',
                        children: 'TABLET',
                    },
                },
                content: {
                    className: 'content7-content',
                    text: {
                        className: 'content7-text',
                        md: 14,
                        xs: 24,
                        children: (
                            <span>
                                <h3>技术</h3>
                                <p>
                                    丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。
                                </p>
                                <br />
                                <h3>融合</h3>
                                <p>
                                    解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。
                                </p>
                                <br />
                                <h3>开放</h3>
                                符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。
                            </span>
                        ),
                    },
                    img: {
                        className: 'content7-img',
                        md: 10,
                        xs: 24,
                        children:
                            'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
                    },
                },
            },
            {
                name: 'block2',
                tag: {
                    className: 'content7-tag',
                    text: {
                        children: 'DESKTOP',
                        className: 'content7-tag-name',
                    },
                    icon: {children: 'laptop'},
                },
                content: {
                    className: 'content7-content',
                    text: {
                        className: 'content7-text',
                        md: 14,
                        xs: 24,
                        children: (
                            <span>
                                <h3>技术</h3>
                                <p>
                                    丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。
                                </p>
                                <br />
                                <h3>融合</h3>
                                <p>
                                    解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。
                                </p>
                                <br />
                                <h3>开放</h3>
                                符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。
                            </span>
                        ),
                    },
                    img: {
                        className: 'content7-img',
                        md: 10,
                        xs: 24,
                        children:
                            'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
                    },
                },
            },
        ],
    },
}
export const Feature80DataSource = {
    wrapper: {className: 'home-page-wrapper feature8-wrapper'},
    page: {className: 'home-page feature8'},
    OverPack: {playScale: 0.3},
    titleWrapper: {
        className: 'feature8-title-wrapper',
        children: [
            {
                name: 'title',
                className: 'feature8-title-h1',
                children: '使用流程',
            },
            {
                name: 'content',
                className: 'feature8-title-content',
                children: '流程简单清晰，快速响应需求',
            },
        ],
    },
    childWrapper: {
        className: 'feature8-button-wrapper',
        children: [
            {
                name: 'button',
                className: 'feature8-button',
                children: {href: '#', children: '立即体验'},
            },
        ],
    },
    Carousel: {
        dots: false,
        className: 'feature8-carousel',
        wrapper: {className: 'feature8-block-wrapper'},
        children: {
            className: 'feature8-block',
            titleWrapper: {
                className: 'feature8-carousel-title-wrapper',
                title: {className: 'feature8-carousel-title'},
            },
            children: [
                {
                    name: 'block0',
                    className: 'feature8-block-row',
                    gutter: 120,
                    title: {
                        className: 'feature8-carousel-title-block',
                        children: '平台自主训练流程',
                    },
                    children: [
                        {
                            className: 'feature8-block-col',
                            md: 6,
                            xs: 24,
                            name: 'child0',
                            arrow: {
                                className: 'feature8-block-arrow',
                                children:
                                    'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
                            },
                            children: {
                                className: 'feature8-block-child',
                                children: [
                                    {
                                        name: 'image',
                                        className: 'feature8-block-image',
                                        children:
                                            'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                                    },
                                    {
                                        name: 'title',
                                        className: 'feature8-block-title',
                                        children: '需求沟通',
                                    },
                                    {
                                        name: 'content',
                                        className: 'feature8-block-content',
                                        children:
                                            '沟通业务需求，对接人：诚凡、芸彩',
                                    },
                                ],
                            },
                        },
                        {
                            className: 'feature8-block-col',
                            md: 6,
                            xs: 24,
                            name: 'child1',
                            arrow: {
                                className: 'feature8-block-arrow',
                                children:
                                    'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
                            },
                            children: {
                                className: 'feature8-block-child',
                                children: [
                                    {
                                        name: 'image',
                                        className: 'feature8-block-image',
                                        children:
                                            'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                                    },
                                    {
                                        name: 'title',
                                        className: 'feature8-block-title',
                                        children: '需求沟通',
                                    },
                                    {
                                        name: 'content',
                                        className: 'feature8-block-content',
                                        children:
                                            '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩',
                                    },
                                ],
                            },
                        },
                        {
                            className: 'feature8-block-col',
                            md: 6,
                            xs: 24,
                            name: 'child2',
                            arrow: {
                                className: 'feature8-block-arrow',
                                children:
                                    'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
                            },
                            children: {
                                className: 'feature8-block-child',
                                children: [
                                    {
                                        name: 'image',
                                        className: 'feature8-block-image',
                                        children:
                                            'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                                    },
                                    {
                                        name: 'title',
                                        className: 'feature8-block-title',
                                        children: '需求沟通',
                                    },
                                    {
                                        name: 'content',
                                        className: 'feature8-block-content',
                                        children:
                                            '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩',
                                    },
                                ],
                            },
                        },
                        {
                            className: 'feature8-block-col',
                            md: 6,
                            xs: 24,
                            name: 'child3',
                            arrow: {
                                className: 'feature8-block-arrow',
                                children:
                                    'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
                            },
                            children: {
                                className: 'feature8-block-child',
                                children: [
                                    {
                                        name: 'image',
                                        className: 'feature8-block-image',
                                        children:
                                            'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                                    },
                                    {
                                        name: 'title',
                                        className: 'feature8-block-title',
                                        children: '需求沟通',
                                    },
                                    {
                                        name: 'content',
                                        className: 'feature8-block-content',
                                        children:
                                            '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩',
                                    },
                                ],
                            },
                        },
                    ],
                },
                {
                    name: 'block1',
                    className: 'feature8-block-row',
                    gutter: 120,
                    title: {
                        children: '平台自主训练流程',
                        className: 'feature8-carousel-title-block',
                    },
                    children: [
                        {
                            className: 'feature8-block-col',
                            md: 6,
                            xs: 24,
                            name: 'child0',
                            arrow: {
                                className: 'feature8-block-arrow',
                                children:
                                    'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
                            },
                            children: {
                                className: 'feature8-block-child',
                                children: [
                                    {
                                        name: 'image',
                                        className: 'feature8-block-image',
                                        children:
                                            'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                                    },
                                    {
                                        name: 'title',
                                        className: 'feature8-block-title',
                                        children: '需求沟通',
                                    },
                                    {
                                        name: 'content',
                                        className: 'feature8-block-content',
                                        children:
                                            '沟通业务需求，对接人：诚凡、芸彩',
                                    },
                                ],
                            },
                        },
                        {
                            className: 'feature8-block-col',
                            md: 6,
                            xs: 24,
                            name: 'child1',
                            arrow: {
                                className: 'feature8-block-arrow',
                                children:
                                    'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
                            },
                            children: {
                                className: 'feature8-block-child',
                                children: [
                                    {
                                        name: 'image',
                                        className: 'feature8-block-image',
                                        children:
                                            'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                                    },
                                    {
                                        name: 'title',
                                        className: 'feature8-block-title',
                                        children: '需求沟通',
                                    },
                                    {
                                        name: 'content',
                                        className: 'feature8-block-content',
                                        children:
                                            '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩',
                                    },
                                ],
                            },
                        },
                        {
                            className: 'feature8-block-col',
                            md: 6,
                            xs: 24,
                            name: 'child2',
                            arrow: {
                                className: 'feature8-block-arrow',
                                children:
                                    'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
                            },
                            children: {
                                className: 'feature8-block-child',
                                children: [
                                    {
                                        name: 'image',
                                        className: 'feature8-block-image',
                                        children:
                                            'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                                    },
                                    {
                                        name: 'title',
                                        className: 'feature8-block-title',
                                        children: '需求沟通',
                                    },
                                    {
                                        name: 'content',
                                        className: 'feature8-block-content',
                                        children:
                                            '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩',
                                    },
                                ],
                            },
                        },
                        {
                            className: 'feature8-block-col',
                            md: 6,
                            xs: 24,
                            name: 'child3',
                            arrow: {
                                className: 'feature8-block-arrow',
                                children:
                                    'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
                            },
                            children: {
                                className: 'feature8-block-child',
                                children: [
                                    {
                                        name: 'image',
                                        className: 'feature8-block-image',
                                        children:
                                            'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                                    },
                                    {
                                        name: 'title',
                                        className: 'feature8-block-title',
                                        children: '需求沟通',
                                    },
                                    {
                                        name: 'content',
                                        className: 'feature8-block-content',
                                        children:
                                            '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
        },
    },
}
export const Pricing00DataSource = {
    wrapper: {className: 'home-page-wrapper pricing0-wrapper'},
    OverPack: {playScale: 0.3, className: 'home-page pricing0'},
    imgWrapper: {className: 'pricing0-img-wrapper', md: 12, xs: 24},
    img: {
        className: 'pricing0-img',
        name: 'image',
        children:
            'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*OnyWT4Nsxy0AAAAAAAAAAABjARQnAQ',
    },
    childWrapper: {
        className: 'pricing0-text-wrapper',
        md: 12,
        xs: 24,
        children: [
            {
                name: 'title',
                children: 'OceanBase 服务器',
                className: 'pricing0-title',
            },
            {
                name: 'content',
                children:
                    '云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。按金融企业安全要求打造的完整云上安全体系，全方位保障金融应用及数据安全。<br/>500-5Gbps，10 GB-50TB（含），1TB流量包，国内按峰值。',
                className: 'pricing0-content',
            },
            {
                name: 'pricing',
                children: '¥2,200',
                className: 'pricing0-pricing',
            },
            {
                name: 'button',
                children: {
                    icon: 'shopping-cart',
                    href: '#',
                    type: 'primary',
                    children: '立即购买',
                },
            },
        ],
    },
}
export const Teams20DataSource = {
    wrapper: {className: 'home-page-wrapper teams2-wrapper'},
    page: {className: 'home-page teams2'},
    OverPack: {playScale: 0.3, className: ''},
    titleWrapper: {
        className: 'title-wrapper',
        children: [{name: 'title', children: '团队成员'}],
    },
    block: {
        className: 'block-wrapper',
        gutter: 72,
        children: [
            {
                name: 'block0',
                className: 'block',
                md: 8,
                xs: 24,
                image: {
                    name: 'image',
                    className: 'teams2-image',
                    children:
                        'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ',
                },
                titleWrapper: {
                    className: 'teams2-textWrapper',
                    children: [
                        {
                            name: 'title',
                            className: 'teams2-title',
                            children: '叶秀英',
                        },
                        {
                            name: 'content',
                            className: 'teams2-job',
                            children: '公司+职位 信息暂缺',
                        },
                        {
                            name: 'content1',
                            className: 'teams2-content',
                            children:
                                'AntV 是蚂蚁金服全新一代数据可视化解决方案。',
                        },
                    ],
                },
            },
            {
                name: 'block1',
                className: 'block',
                md: 8,
                xs: 24,
                image: {
                    name: 'image',
                    className: 'teams2-image',
                    children:
                        'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*njqxS5Ky7CQAAAAAAAAAAABjARQnAQ',
                },
                titleWrapper: {
                    className: 'teams2-textWrapper',
                    children: [
                        {
                            name: 'title',
                            className: 'teams2-title',
                            children: '韩勇',
                        },
                        {
                            name: 'content',
                            className: 'teams2-job',
                            children: '公司+职位 信息暂缺',
                        },
                        {
                            name: 'content1',
                            className: 'teams2-content',
                            children:
                                '语雀是一款优雅高效的在线文档编辑与协同工具。',
                        },
                    ],
                },
            },
            {
                name: 'block2',
                className: 'block',
                md: 8,
                xs: 24,
                image: {
                    name: 'image',
                    className: 'teams2-image',
                    children:
                        'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ',
                },
                titleWrapper: {
                    className: 'teams2-textWrapper',
                    children: [
                        {
                            name: 'title',
                            className: 'teams2-title',
                            children: '叶秀英',
                        },
                        {
                            name: 'content',
                            className: 'teams2-job',
                            children: '公司+职位 信息暂缺',
                        },
                        {
                            name: 'content1',
                            className: 'teams2-content',
                            children:
                                'AntV 是蚂蚁金服全新一代数据可视化解决方案。',
                        },
                    ],
                },
            },
            {
                name: 'block3',
                className: 'block',
                md: 8,
                xs: 24,
                image: {
                    name: 'image',
                    className: 'teams2-image',
                    children:
                        'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ',
                },
                titleWrapper: {
                    className: 'teams2-textWrapper',
                    children: [
                        {
                            name: 'title',
                            className: 'teams2-title',
                            children: '叶秀英',
                        },
                        {
                            name: 'content',
                            className: 'teams2-job',
                            children: '公司+职位 信息暂缺',
                        },
                        {
                            name: 'content1',
                            className: 'teams2-content',
                            children:
                                'AntV 是蚂蚁金服全新一代数据可视化解决方案。',
                        },
                    ],
                },
            },
            {
                name: 'block4',
                className: 'block',
                md: 8,
                xs: 24,
                image: {
                    name: 'image',
                    className: 'teams2-image',
                    children:
                        'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*njqxS5Ky7CQAAAAAAAAAAABjARQnAQ',
                },
                titleWrapper: {
                    className: 'teams2-textWrapper',
                    children: [
                        {
                            name: 'title',
                            className: 'teams2-title',
                            children: '韩勇',
                        },
                        {
                            name: 'content',
                            className: 'teams2-job',
                            children: '公司+职位 信息暂缺',
                        },
                        {
                            name: 'content1',
                            className: 'teams2-content',
                            children:
                                '语雀是一款优雅高效的在线文档编辑与协同工具。',
                        },
                    ],
                },
            },
            {
                name: 'block5',
                className: 'block',
                md: 8,
                xs: 24,
                image: {
                    name: 'image',
                    className: 'teams2-image',
                    children:
                        'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ',
                },
                titleWrapper: {
                    className: 'teams2-textWrapper',
                    children: [
                        {
                            name: 'title',
                            className: 'teams2-title',
                            children: '叶秀英',
                        },
                        {
                            name: 'content',
                            className: 'teams2-job',
                            children: '公司+职位 信息暂缺',
                        },
                        {
                            name: 'content1',
                            className: 'teams2-content',
                            children:
                                'AntV 是蚂蚁金服全新一代数据可视化解决方案。',
                        },
                    ],
                },
            },
            {
                name: 'block6',
                className: 'block',
                md: 8,
                xs: 24,
                image: {
                    name: 'image',
                    className: 'teams2-image',
                    children:
                        'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ',
                },
                titleWrapper: {
                    className: 'teams2-textWrapper',
                    children: [
                        {
                            name: 'title',
                            className: 'teams2-title',
                            children: '叶秀英',
                        },
                        {
                            name: 'content',
                            className: 'teams2-job',
                            children: '公司+职位 信息暂缺',
                        },
                        {
                            name: 'content1',
                            className: 'teams2-content',
                            children:
                                'AntV 是蚂蚁金服全新一代数据可视化解决方案。',
                        },
                    ],
                },
            },
            {
                name: 'block7',
                className: 'block',
                md: 8,
                xs: 24,
                image: {
                    name: 'image',
                    className: 'teams2-image',
                    children:
                        'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*njqxS5Ky7CQAAAAAAAAAAABjARQnAQ',
                },
                titleWrapper: {
                    className: 'teams2-textWrapper',
                    children: [
                        {
                            name: 'title',
                            className: 'teams2-title',
                            children: '韩勇',
                        },
                        {
                            name: 'content',
                            className: 'teams2-job',
                            children: '公司+职位 信息暂缺',
                        },
                        {
                            name: 'content1',
                            className: 'teams2-content',
                            children:
                                '语雀是一款优雅高效的在线文档编辑与协同工具。',
                        },
                    ],
                },
            },
            {
                name: 'block8',
                className: 'block',
                md: 8,
                xs: 24,
                image: {
                    name: 'image',
                    className: 'teams2-image',
                    children:
                        'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*--rVR4hclJYAAAAAAAAAAABjARQnAQ',
                },
                titleWrapper: {
                    className: 'teams2-textWrapper',
                    children: [
                        {
                            name: 'title',
                            className: 'teams2-title',
                            children: '叶秀英',
                        },
                        {
                            name: 'content',
                            className: 'teams2-job',
                            children: '公司+职位 信息暂缺',
                        },
                        {
                            name: 'content1',
                            className: 'teams2-content',
                            children:
                                'AntV 是蚂蚁金服全新一代数据可视化解决方案。',
                        },
                    ],
                },
            },
        ],
    },
}
export const Teams40DataSource = {
    wrapper: {className: 'home-page-wrapper content8-wrapper'},
    page: {className: 'home-page content8'},
    OverPack: {playScale: 0.3},
    titleWrapper: {
        className: 'title-wrapper',
        children: [
            {
                name: 'image',
                children:
                    'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
                className: 'title-image',
            },
            {name: 'title', children: '特邀嘉宾', className: 'title-h1'},
        ],
    },
    block: {
        className: 'content-wrapper',
        children: [
            {
                name: 'block0',
                md: 6,
                xs: 24,
                className: 'content8-block-wrapper',
                children: {
                    className: 'content8-block',
                    img: {
                        className: 'content8-img',
                        children:
                            'https://gw.alipayobjects.com/zos/rmsportal/JahzbVrdHdJlkJjkNsBJ.png',
                    },
                    title: {className: 'content8-title', children: 'Jack'},
                    content: {
                        className: 'content8-content',
                        children: '公司+职位 信息暂缺',
                    },
                },
            },
            {
                name: 'block1',
                md: 6,
                xs: 24,
                className: 'content8-block-wrapper',
                children: {
                    className: 'content8-block',
                    img: {
                        className: 'content8-img',
                        children:
                            'https://gw.alipayobjects.com/zos/rmsportal/JahzbVrdHdJlkJjkNsBJ.png',
                    },
                    title: {className: 'content8-title', children: 'Jack'},
                    content: {
                        className: 'content8-content',
                        children: '公司+职位 信息暂缺',
                    },
                },
            },
            {
                name: 'block2',
                md: 6,
                xs: 24,
                className: 'content8-block-wrapper',
                children: {
                    className: 'content8-block',
                    img: {
                        className: 'content8-img',
                        children:
                            'https://gw.alipayobjects.com/zos/rmsportal/JahzbVrdHdJlkJjkNsBJ.png',
                    },
                    title: {className: 'content8-title', children: 'Jack'},
                    content: {
                        className: 'content8-content',
                        children: '公司+职位 信息暂缺',
                    },
                },
            },
            {
                name: 'block3',
                md: 6,
                xs: 24,
                className: 'content8-block-wrapper',
                children: {
                    className: 'content8-block',
                    img: {
                        className: 'content8-img',
                        children:
                            'https://gw.alipayobjects.com/zos/rmsportal/JahzbVrdHdJlkJjkNsBJ.png',
                    },
                    title: {className: 'content8-title', children: 'Jack'},
                    content: {
                        className: 'content8-content',
                        children: '公司+职位 信息暂缺',
                    },
                },
            },
            {
                name: 'block4',
                md: 6,
                xs: 24,
                className: 'content8-block-wrapper',
                children: {
                    className: 'content8-block',
                    img: {
                        className: 'content8-img',
                        children:
                            'https://gw.alipayobjects.com/zos/rmsportal/JahzbVrdHdJlkJjkNsBJ.png',
                    },
                    title: {className: 'content8-title', children: 'Jack'},
                    content: {
                        className: 'content8-content',
                        children: '公司+职位 信息暂缺',
                    },
                },
            },
            {
                name: 'block5',
                md: 6,
                xs: 24,
                className: 'content8-block-wrapper',
                children: {
                    className: 'content8-block',
                    img: {
                        className: 'content8-img',
                        children:
                            'https://gw.alipayobjects.com/zos/rmsportal/JahzbVrdHdJlkJjkNsBJ.png',
                    },
                    title: {className: 'content8-title', children: 'Jack'},
                    content: {
                        className: 'content8-content',
                        children: '公司+职位 信息暂缺',
                    },
                },
            },
            {
                name: 'block6',
                md: 6,
                xs: 24,
                className: 'content8-block-wrapper',
                children: {
                    className: 'content8-block',
                    img: {
                        className: 'content8-img',
                        children:
                            'https://gw.alipayobjects.com/zos/rmsportal/JahzbVrdHdJlkJjkNsBJ.png',
                    },
                    title: {className: 'content8-title', children: 'Jack'},
                    content: {
                        className: 'content8-content',
                        children: '公司+职位 信息暂缺',
                    },
                },
            },
            {
                name: 'block7',
                md: 6,
                xs: 24,
                className: 'content8-block-wrapper',
                children: {
                    className: 'content8-block',
                    img: {
                        className: 'content8-img',
                        children:
                            'https://gw.alipayobjects.com/zos/rmsportal/JahzbVrdHdJlkJjkNsBJ.png',
                    },
                    title: {className: 'content8-title', children: 'Jack'},
                    content: {
                        className: 'content8-content',
                        children: '公司+职位 信息暂缺',
                    },
                },
            },
        ],
    },
}
export const Contact00DataSource = {
    wrapper: {className: 'home-page-wrapper content10-wrapper'},
    Content: {
        className: 'icon-wrapper',
        children: {
            icon: {
                className: 'icon',
                children:
                    'https://gw.alipayobjects.com/zos/rmsportal/zIUVomgdcKEKcnnQdOzw.svg',
                name: '主要图标',
            },
            iconShadow: {
                className: 'icon-shadow',
                children:
                    'https://gw.alipayobjects.com/zos/rmsportal/WIePwurYppfVvDNASZRN.svg',
                name: '图标影阴',
            },
            url: {
                children: 'https://gaode.com/place/B0FFH3KPBX',
                name: '跳转地址',
            },
            title: {children: '大会地址', name: '弹框标题'},
            content: {
                children: '蚂蚁 Z 空间  浙江省杭州市西湖区西溪路556号',
                name: '弹框内容',
            },
        },
    },
}
