/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import CSCTitle from "../../widget/CSCTitle";
import { CloudDownloadOutlined, InfoCircleFilled } from "@ant-design/icons";

import {
    Row,
    Col,
    AutoComplete,
    Cascader,
    DatePicker,
    Input,
    InputNumber,
    Select,
    Button,
    Form,
    Tooltip,
    Radio,
    BackTop,
} from "antd";

// import Content0 from "./Content0";
// import Content7 from "./Content7";
// import Banner from "../Banner/index";
// import CSCButton from "../widget/CSCButton";
import CSCBackTop from "../../widget/CSCBackTop";

import Content5 from "./Content5";

import {
    // Content00DataSource,
    // Content70DataSource,
    // Banner10DataSource,
    Content50DataSource,
} from "./data.source.js";
import owRequest from "../../libs/request";
import config from "../../libs/config";

import "./less/antMotionStyle.less";
import "./less/product_search.less";

const { Option } = Select;
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const location = window.location;

export default class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
            activeTab: 0,
            product_series: "series_a11",
            product_model: "",
            driven_type: [],
            driven_os: [],
            all_list: [],
            product_list: [],
            driven_type_list: [],
            driven_os_list: [],
            display_list: [],
            drivenTypes: [
                {
                    value: "1",
                    label: "3008 SAS",
                },
                {
                    value: "2",
                    label: "3108 RAID",
                },
                {
                    value: "3",
                    label: "HBA网卡",
                },
                {
                    value: "4",
                    label: "网卡",
                },
                // {
                //     value: "5",
                //     label: "HBA",
                // },
                {
                    value: "6",
                    label: "板载SATA",
                },
                {
                    value: "7",
                    label: "芯片组",
                },
                {
                    value: "8",
                    label: "BMC刷新文件",
                },
                {
                    value: "9",
                    label: "固件刷新文件",
                },
                // {
                //     value: "10",
                //     label: "板载SATA",
                // },
                {
                    value: "11",
                    label: "BIOS刷新文件",
                },
               {
                    value: '12',
                    label: '39xx RAID',
                },
                {
                    value: '13',
                    label: 'AMD芯片组',
                },
                {
                    value: '14',
                    label: 'GPU',
                },
                {
                    value: '15',
                    label: 'Microsoft .Net Framework',
                },
                {
                    value: '16',
                    label: 'PCH RAID',
                },
                {
                    value: '17',
                    label: 'SATA（板载SATA修改）',
                },
                {
                    value: '18',
                    label: 'VGA',
                },
                {
                    value: '19',
                    label: 'VROC-NVMe RAID',
                },
                {
                    value: '20',
                    label: 'BIOS/BMC',
                },
                {
                    value: '21',
                    label: 'mib库文件',
                },
            ],
        };
    }

    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
        this.getDrivenList();
        /* 如果不是 dva 2.0 请删除 end */
    }

    // getProductItem() {
    //     return (
    //         <div className="product_item">
    //             <div className="product_img">
    //                 <img
    //                     src="https://www.chinasupercloud.com/service/Public/kindeditor/attached/image/20211029/20211029140557_98288.jpg"
    //                     alt=""
    //                 />
    //             </div>
    //             <div className="product_title">233333</div>
    //             <div className="product_desc">444</div>
    //         </div>
    //     );
    // }

    // onClickTabItem(activeTab) {
    //     this.setState({ activeTab: activeTab });
    // }

    // onClickSearchBtn() {
    //     window.open("/product/detail");
    // }

    onFormLayoutChange(e) {
        console.log("onFormLayoutChange", e);
    }

    onClickSearch() {
        console.log("onClickSearch");
    }
    onChange_1(e) {
        this.setState(
            {
                product_series: e.target.value,
                product_model: "",
                driven_os: "",
                driven_type: "",
                display_list: [],
            },
            () => {
                this.getDrivenList();
            }
        );
    }
    onChange_2(e) {
        // const osData = this.uniqueArr(this.state.product_list, "driven_os");
        let uniOs = [];
        let osData = [];
        let drivenOSData = [];
        let driveTypeData = [];
        let ret = [];
        console.log('onChange_2', e)
        for (let item of this.state.all_list) {
            if (item.product_model == e.target.value) {
                osData.push(item);
            }
        }
        for (let item of osData) {
            // console.log("123123", item);
            if (uniOs.indexOf(item.driven_os) == -1) {
                uniOs.push(item.driven_os);
                drivenOSData.push(item);
            }
        }
        for (let item of osData) {
            // console.log("123123", item);
            if (uniOs.indexOf(item.driven_type) == -1) {
                uniOs.push(item.driven_type);
                driveTypeData.push(item);
            }
        }

        for (let item of this.state.all_list) {
            if (item.product_model == e.target.value) {
                ret.push(item);
            }
        }
        console.log("driveTypeData", driveTypeData);
        if(driveTypeData &&   driveTypeData.length){
            driveTypeData.sort((a,b)=>{
                console.log(a.driven_type, b.driven_type)
                let val1 = Number(a.driven_type);
                let val2 = Number(b.driven_type);
                return val1 - val2
            })
        }

        this.setState({
            product_model: e.target.value,
            driven_os_list: drivenOSData,
            driven_type_list: driveTypeData,
            // display_list: ret,
            driven_os: "",
            driven_type: "",
            display_list: [],
        });
    }
    onChange_3(e) {
        let ret = [];
        // console.log("driven_type_list___11", this.state.driven_type_list);
        for (let item of this.state.all_list) {
            if (item.driven_os == e.target.value) {
                ret.push(item);
            }
        }
        this.setState({
            driven_os: e.target.value,
            driven_type: "",
            display_list: [],
            // display_list: ret,
        });
    }
    onChange_4(e) {
        let ret = [];
        // console.log("e.target.value", e);

        for (let item of this.state.all_list || []) {
            // console.log(
            //     "item3837373737",
            //     item,
            //     this.state.product_model,
            //     this.state.driven_os,
            //     e.target.value
            // );
            if (
                item.product_model == this.state.product_model &&
                item.driven_os == this.state.driven_os &&
                item.driven_type == e.target.value
            ) {
                ret.push(item);
            }
        }

        this.setState({
            driven_type: e.target.value,
            display_list: ret,
        });
    }

    uniqueArr(arr, key) {
        let ret = [];
        let uniArr = [];
        for (let item of arr) {
            if (uniArr.indexOf(item[`${key}`]) === -1) {
                ret.push(item);
                uniArr.push(item[`${key}`]);
            }
        }
        return ret;
    }

    getDrivenList() {
        owRequest
            .get(
                `/office-website/driven-list?product_series=${this.state.product_series}`
            )
            .then((res) => {
                if (res && res.code == 2000) {
                    let product_modelArr = [];
                    let drivenTypeArr = [];
                    let drivenOS = [];
                    let ret = [];
                    let ret_0 = [];
                    let ret_1 = [];
                    let ret_2 = [];
                    let uniDrivenIds = [];
                    let uniData = [];

                    // 去重 driven id
                    for (let item of res.data) {
                        // if (uniDrivenIds.indexOf(item.driven_id) === -1) {
                            uniDrivenIds.push(item.driven_id);
                            uniData.push(item);
                        // }
                    }

                    console.log('uniDatauniDatauniData',uniData)

                    for (let item of uniData) {
                        // console.log("123123r4", res.data);
                        if (
                            product_modelArr.indexOf(item.product_model) === -1
                        ) {
                            ret_0.push(item);
                            product_modelArr.push(item.product_model);
                        }
                        if (drivenOS.indexOf(item.driven_os) === -1) {
                            ret_1.push(item);
                            drivenOS.push(item.driven_os);
                        }
                        if (drivenTypeArr.indexOf(item.driven_type) === -1) {
                            ret_2.push(item);
                            drivenTypeArr.push(item.driven_type);
                        }
                    }

                    if(ret_2 && ret_2.length){
                        ret_2.sort((a,b)=>{return Number(a.driven_type) - Number(b.driven_type)})
                    }

                    // console.log("rettt__1", ret_2);
                    this.setState({
                        all_list: uniData || [],
                        // display_list: res.data || [],
                        product_list: ret_0 || [],
                        driven_os_list: ret_1 || [],
                        driven_type_list: ret_2 || [],
                    });
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    }

    getDrivenTypeName(data) {
        // console.log("data", data);
        let str = "";
        for (let item of this.state.drivenTypes) {
            if (item.value === data.driven_type) {
                str = item.label;
            }
        }
        return str;
    }

    onClickDownload(url) {
        if (url) {
            // console.log(config.pic_url + url);
            window.open(config.pic_url + url);
        }
    }

    render() {
        const children = [
            // <Banner
            //     id="Banner1_0"
            //     key="Banner1_0"
            //     dataSource={Banner10DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            <div style={{ position: "relative" }}>
                <img
                    src={require("../../assets/techsuupport_bg_5.png")}
                    alt=""
                    style={{ width: "100%" }}
                />

                {/* <div
                    style={{
                        position: "absolute",
                        // top: this.state.isMobile ? "3rem" : "16rem",
                        // left: this.state.isMobile ? "2rem" : "16rem",
                        top: this.state.isMobile ? "3rem" : "40%",
                        left: this.state.isMobile ? "1rem" : "15%",
                        color: "#fff",
                        fontSize: this.state.isMobile ? "1.4rem" : "2.4vw",
                        zIndex: 100,
                        fontWeight: 700,
                    }}
                >
                    技术支持
                </div> */}
            </div>,

            <div style={{ paddingTop: "40px", display:"flex", alignItems:"center", justifyContent:"center" , flexDirection:"column"}}>
                <CSCTitle
                    title="检索条件"
                    // key="services_scope"
                    isMobile={this.state.isMobile}
                    style={{
                        margin: 0,
                        height: "auto",
                        // borderBottom: "5px solid red",
                    }}
                    textSty={{
                        fontSize: this.state.isMobile ? "20px" : "28px",
                        fontWeight: "700",
                    }}
                />
                <div style={{width:"7rem", height:"5px", background:"#2FB467",marginTop:"10px"}}>
                </div>
            </div>,
        
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#fff",
                }}
            >
                <div
                    key={"cscsearch_2"}
                    style={{
                        background: "#fff",
                        padding: "30px",
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        paddingTop: "3rem",
                    }}
                >
                    <div style={checkItem}>
                        <div style={checkLabelSty}>
                            <span>选择产品系列</span>
                        </div>
                        <div style={checkContentSty}>
                            <Radio.Group
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                    this.onChange_1(e);
                                }}
                                value={this.state.product_series}
                            >
                                <Radio value="series_a11">A11系列</Radio>
                                <Radio value="series_a12">A12系列</Radio>
                                <Radio value="series_a13">A13系列</Radio>
                                <Radio value="series_a14">A14系列</Radio>
                                <Radio value="series_g11">G11系列</Radio>
                                <Radio value="series_g12">G12系列</Radio>
                                <Radio value="series_g13">G13系列</Radio>
                                {/* <Radio value="series_t11">T11系列</Radio> */}
                                {/* <Radio value="series_g10">G10系列</Radio> */}
                                {/* <Radio value="series_all">全国国产系列</Radio> */}
                                <Radio value="series_info_v">信创系列</Radio>

                               

                            </Radio.Group>
                        </div>
                    </div>
                    <div style={checkItem}>
                        <div style={checkLabelSty}>
                            <span>选择产品型号</span>
                        </div>
                        <div style={checkContentSty}>
                            {this.state.product_list &&
                            this.state.product_list.length ? (
                                <Radio.Group
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                        this.onChange_2(e);
                                    }}
                                    value={this.state.product_model}
                                >
                                    {this.state.product_list.map((item) => {
                                        return (
                                            <Radio value={item.product_model}>
                                                {item.product_name}
                                            </Radio>
                                        );
                                    })}

                                    {/* <Radio value="A">G5210 G11</Radio>
                                <Radio value="B">G5410 G11</Radio>
                                <Radio value="C">G6240 G11</Radio> */}
                                </Radio.Group>
                            ) : null}
                        </div>
                    </div>
                    <div style={checkItem}>
                        <div style={checkLabelSty}>
                            <span>选择操作系统</span>
                        </div>
                        <div style={checkContentSty}>
                            {this.state.driven_os_list &&
                            this.state.driven_os_list.length ? (
                                <Radio.Group
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                        this.onChange_3(e);
                                    }}
                                    value={this.state.driven_os}
                                >
                                    {this.state.driven_os_list.map((item) => {
                                        if (item.driven_os == "os_linux") {
                                            return (
                                                <Radio value="os_linux">
                                                    Linux
                                                </Radio>
                                            );
                                        }
                                        if (item.driven_os == "os_windows") {
                                            return (
                                                <Radio value="os_windows">
                                                    Windows
                                                </Radio>
                                            );
                                        }
                                    })}
                                </Radio.Group>
                            ) : null}
                        </div>
                    </div>
                    <div style={checkItem}>
                        <div style={checkLabelSty}>
                            <span>选择驱动类型</span>
                        </div>
                        <div style={checkContentSty}>
                            {this.state.driven_type_list &&
                            this.state.driven_type_list.length ? (
                                <Radio.Group
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                        this.onChange_4(e);
                                    }}
                                    value={this.state.driven_type}
                                >
                                    {this.state.driven_type_list.map((item) => {
                                        return (
                                            <Radio value={item.driven_type}>
                                                {this.getDrivenTypeName(item)}
                                            </Radio>
                                        );
                                    })}
                                </Radio.Group>
                            ) : null}
                        </div>
                    </div>
                    {/* <Row>
                        <Radio.Group
                            style={{ width: "100%" }}
                            onChange={(e) => {
                                this.onChange(e);
                            }}
                        >
                            <span>123128734787843: </span>
                            <Radio value="A">A</Radio>
                            <Radio value="A">A</Radio>
                            <Radio value="A">A</Radio>
                            <Radio value="A">A</Radio>
                            <Radio value="A">A</Radio>
                        </Radio.Group>
                    </Row> */}
                    {/* <Form
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 14 }}
                    layout="iniline"
                    // labelAlign="left"
                    // initialValues={{ disabled: componentDisabled }}
                    onValuesChange={(e) => {
                        this.onFormLayoutChange(e);
                    }}
                    // disabled={componentDisabled}
                >
                    <Form.Item name="reportCycle" label="123123">
                        <Radio.Group
                            options={this.state.cycleOptions}
                            onChange={this.onChangeCycle}
                        />
                    </Form.Item>
                    <Form.Item name="reportCycle" label="123123">
                        <Radio.Group
                            options={this.state.cycleOptions1}
                            onChange={this.onChangeCycle}
                        />
                    </Form.Item>

                    <div>
                        <Form.Item
                            label="Form disabled"
                            name="disabled"
                            valuePropName="checked"
                        >
                            <Radio>disabled</Radio>
                        </Form.Item>
                    </div>
                    <Form.Item label="Form disabled:" name="disabled">
                        <Radio value="A">不限</Radio>
                        <Radio value="A">1</Radio>
                        <Radio value="A">2</Radio>
                        <Radio value="A">3</Radio>
                        <Radio value="A">8</Radio>
                    </Form.Item>
                    <Form.Item
                        label="Form disabled:"
                        name="disabled"
                        valuePropName="checked"
                    >
                        <Radio value="A">不限</Radio>
                        <Radio value="A">1</Radio>
                        <Radio value="A">2</Radio>
                        <Radio value="A">3</Radio>
                        <Radio value="A">4</Radio>
                        <Radio value="A">4个以上</Radio>
                    </Form.Item>
                </Form> */}
                </div>
            </div>,
            <div className="line_bar"></div>,
            <div style={{ paddingTop: "40px", marginBottom: "30px",  display:"flex", alignItems:"center", justifyContent:"center" , flexDirection:"column" }}>
                <CSCTitle
                    title="查询结果"
                    // key="services_scope"
                    isMobile={this.state.isMobile}
                    style={{
                        margin: 0,
                        height: "auto",
                    }}
                    textSty={{
                        fontSize: this.state.isMobile ? "20px" : "28px",
                        fontWeight: "700",
                    }}
                />
                <div style={{width:"7rem", height:"5px", background:"#2FB467",marginTop:"10px"}}>
                </div>
            </div>,
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex",
                        background: "#fff",
                        flexDirection: "column",
                        maxWidth: "780px",
                        width: "780px",
                        paddingBottom: "60px",
                        // background: "red",
                    }}
                >
                    {/* <div
                        style={{
                            marginTop: "50px",
                            fontSize: "20px",
                            fontWeight: "600",
                        }}
                    >
                        文件名称
                    </div> */}
                    {this.state.display_list.map((item) => {
                        return (
                            <div
                                key={item}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginTop: "20px",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                            >
                                <div style={{ fontSize: "20px" }}>
                                    {item.driven_name || ""}
                                </div>
                                <a
                                    style={{
                                        fontSize: "18px",
                                        cursor: "pointer",
                                    }}
                                    // href={item.driven_url || "#"}
                                    onClick={() => {
                                        this.onClickDownload(item.driven_url);
                                    }}
                                >
                                    <CloudDownloadOutlined />
                                    下载
                                </a>
                            </div>
                        );
                    })}
                </div>
                ,
            </div>,

            // <Content5
            //     id="general_machine"
            //     key="general_machine"
            //     dataSource={Content50DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <Content5
            //     id="ai_machine"
            //     key="ai_machine"
            //     dataSource={Content50DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <Content5
            //     id="epyc_machine"
            //     key="epyc_machine"
            //     dataSource={Content50DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <Content5
            //     id="aa_machine"
            //     key="aa_machine"
            //     dataSource={Content50DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <div className="c_a_1_1_title_wrapper">
            //     <h1 className="c_a_1_1_title">通用服务器</h1>
            // </div>,
            // <div className="product_box">
            //     {[1, 1, 1, 1, 1, 1, 1, 1].map((item) => {
            //         return this.getProductItem(item);
            //     })}
            // </div>,
            // <Content0
            //     id="Content0_0"
            //     key="Content0_0"
            //     dataSource={Content00DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <Content7
            //   id="Content7_0"
            //   key="Content7_0"
            //   dataSource={Content70DataSource}
            //   isMobile={this.state.isMobile}
            // />,
        ];
        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {this.state.show && children}

                <CSCBackTop isMobile={this.state.isMobile} key="driven_down" />
                {/* 如果不是 dva 2.0 替换成 {children} end */}
            </div>
        );
    }
}

const checkItem = {
    // width: "60rem",
    textAlign: "center",
    display: "flex",
    // flexDirection:"column",
    alignItems: "flex-start",
    justifyContent: "center",
    margin: "0",
    fontSize: "16px",
    padding: "10px",
    fontWeight: "600",
};

const checkLabelSty = {
    width: "10rem",
    textAlign: "right",
    marginRight: "20px",
    padding: "7px",
};
const checkContentSty = {
    //   width: "140px",
    flex: 1,
    textAlign: "left",
    marginRight: "20px",
    display: "flex",
    maxWidth: "700px",
    whiteSpace: "wrap",
    height: "auto",
    overflow: "hidden",
    float: "left",
    display: "block",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    // justifyContent: "space-between",
};
